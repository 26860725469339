import styled from 'styled-components';
import * as defaultTheme from '../variables';
import { fadeInUp, fadeIn, fadeInUpBlock } from '../animations';

import bgPrizes from '../../images/bg_Prizes.jpg';

const LeaderboardStyled = styled('div')`
  color: ${p => p.theme.white};
  background-color: ${p => p.theme.black};
  background-image: url(${bgPrizes});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation-name: ${fadeInUp};
  animation-duration: 800ms;
  animation-delay: 140ms;
  animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

  .Leaderboard__inner {
    position: relative;
    width: 100%;
    max-width: ${p => p.theme.maxWidth}px;
    padding: 75px 20px;
    margin: 0 auto;

    @media (${p => p.theme.lgMin}) {
      padding: 75px 0;
    }
  }

  .Leaderboard__wrapper {
    position: relative;
    animation: ${fadeIn} 0.7s cubic-bezier(0.135, 0.785, 0.15, 0.86) both;

    @media (${p => p.theme.mdMin}) {
      display: flex;
      justify-content: space-between;
    }
  }

  .Leaderboard__start {
    padding-right: 8px;
    font-size: 12px;
    line-height: 53px;
    font-weight: 900;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (${p => p.theme.smMin}) {
      font-size: 20px;
    }
  }

  .Leaderboard__leaders {
    position: relative;
    flex-grow: 1;
    overflow-y: auto;

    @media (${p => p.theme.smMax}) {
      height: 271px;
    }

    @media (${p => p.theme.mdMin}) {
      height: 807px;
      margin-right: 40px;
    }
  }

  .Leaderboard__leader {
    display: flex;
    width: 100%;
    height: 51px;
    margin-bottom: 3px;
    background-color: rgba(0, 0, 0, 0.5);
    transform-origin: center center;

    &.top3 {
      background-color: rgba(74, 144, 226, 0.5);
    }

    &.top10 {
      background-color: rgba(32, 79, 133, 0.5);
    }
  }

  .Leaderboard__pos {
    flex-shrink: 0;
    width: 36px;
    font-size: 20px;
    line-height: 51px;
    text-align: center;
    letter-spacing: 1.42px;

    @media (${p => p.theme.smMin}) {
      width: 51px;
      font-size: 22px;
    }
  }

  .Leaderboard__avatar {
    flex-shrink: 0;
    width: 51px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .Leaderboard__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    padding-left: 12px;

    @media (${p => p.theme.smMin}) {
      flex-direction: row;
      padding-left: 32px;
    }
  }

  .Leaderboard__player {
    flex: 1;
    min-width: 0;

    @media (${p => p.theme.xsMax}) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .Leaderboard__name {
    padding: 0 16px 0 0;
    font-size: 16px;
    line-height: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (${p => p.theme.smMin}) {
      font-size: 18px;
      line-height: 30px;
    }
  }

  .Leaderboard__icons {
    display: flex;
    flex-shrink: 0;
  }

  .Leaderboard__icon {
    height: 16px;
    margin-right: 10px;

    @media (${p => p.theme.smMin}) {
      height: 15px;
    }

    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }

  .Leaderboard__position {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .Leaderboard__score {
    font-size: 18px;
    line-height: 24px;
    transition: all 500ms cubic-bezier(1, 0, 0.49, 1.29);

    @media (${p => p.theme.smMin}) {
      padding-right: 30px;
      font-size: 24px;
      line-height: 51px;
    }
  }

  .Leaderboard__sidebar {
    width: 100%;

    @media (${p => p.theme.smMax}) {
      margin-top: 65px;
    }

    @media (${p => p.theme.mdMin}) {
      max-width: 460px;
    }
  }

  .Leaderboard__subtitle {
    margin: 24px 0;
    font-size: 28px;
    font-weight: 900;
    line-height: 1;
    letter-spacing: 1.3px;
    text-transform: uppercase;
  }

  .Leaderboard__scoring {
    @media (min-width: 480px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .Leaderboard__weekIcon {
    display: flex;
    align-items: center;
    margin: 24px 0;

    img {
      flex-shrink: 0;
      margin-right: 13px;
    }
  }

  .Leaderboard__scoringItem {
    width: 153px;
    margin-bottom: 16px;
    line-height: 1.4;

    &:not(:last-child) {
      padding-right: 16px;
    }

    strong {
      font-size: 20px;
      white-space: nowrap;
    }

    span {
      display: block;
      font-size: 13px;
    }
  }

  .Leaderboard__wins {
    margin: 0 0 30px 15px;
    padding: 0;
    list-style: square;
  }

  .Leaderboard__weekIcon,
  .Leaderboard__scoringItem,
  .Leaderboard__winsItem,
  .Leaderboard__prizingItem {
    opacity: 0;
    transform: translate3d(0, 25px, 0);
    animation-name: ${fadeInUpBlock};
    animation-fill-mode: both;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }

  .Leaderboard__prizing {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }

  .Leaderboard__prizingItem {
    padding: 8px;

    @media (${p => p.theme.xsMax}) {
      width: 33.3333%;
    }

    @media (${p => p.theme.smMin}) {
      width: 25%;
    }

    @media (${p => p.theme.mdMin}) {
      width: 33.3333%;
    }

    button {
      display: block;
      width: 100%;
      height: 100%;
      padding: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .Leaderboard__weekButtons {
    display: flex;
    flex-wrap: wrap;
    margin: 32px -8px;
  }

  .Leaderboard__weekButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin: 8px;
    padding: 0 32px;
    color: #fff;
    background-image: none;
    font-size: inherit;
    font-weight: 700;
    vertical-align: middle;
    letter-spacing: 2px;
    white-space: nowrap;
    border: 1px solid white;
    cursor: pointer;
    background-color: transparent;
    text-transform: uppercase;

    &.active {
      color: #000;
      background-color: #fff;
    }
  }

  .Leaderboard__badge {
    display: inline-block;
    margin-left: 8px;
    padding: 2px 5px;
    color: white;
    background-color: #1bd002;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
  }
`;

LeaderboardStyled.defaultProps = {
  theme: defaultTheme,
};

export default LeaderboardStyled;
