import alliesTitan from '../../images/tournament/allies-titan.svg';
import alliesHunter from '../../images/tournament/allies-hunter.svg';
import alliesWarlock from '../../images/tournament/allies-warlock.svg';

import platformXbox from '../../images/tournament/platform-xbox.png';
import platformPsn from '../../images/tournament/platform-psn.png';
import platformPc from '../../images/tournament/platform-pc.png';

import iconCrucible from '../../images/icon_crucible.svg';
import iconStrikes from '../../images/icon_strikes.svg';
import iconGambit from '../../images/icon_gambit.svg';

import weeks123_1 from '../../images/prizing/weeks123_1.jpg';
import weeks123_2 from '../../images/prizing/weeks123_2.jpg';
import weeks123_3 from '../../images/prizing/weeks123_3.jpg';
import weeks123_4 from '../../images/prizing/weeks123_4.jpg';
import originalSugarFree from '../../images/prizing/D2-site-cases-original-sugar-free.jpg';
import week4Spot1 from '../../images/prizing/week_week4_spot1.jpg';
import week4Spot2 from '../../images/prizing/week_week4_spot2.jpg';
import week4Spot3 from '../../images/prizing/week_week4_spot3.jpg';
import week4Spot4 from '../../images/prizing/week_week4_spot4.jpg';
import week4Spot5 from '../../images/prizing/week_week4_spot5.jpg';
import week4Spot6 from '../../images/prizing/week_week4_spot6.jpg';
import week5Spot1 from '../../images/prizing/week_week5_spot1.jpg';
import week5Spot2 from '../../images/prizing/week_week5_spot2.jpg';
import week5Spot3 from '../../images/prizing/week_week5_spot3.jpg';
import week5Spot4 from '../../images/prizing/week_week5_spot4.jpg';
import week5Spot5 from '../../images/prizing/week_week5_spot5.jpg';
import week5Spot6 from '../../images/prizing/week_week5_spot6.jpg';
import week6Spot1 from '../../images/prizing/week_week6_spot1.jpg';
import week6Spot2 from '../../images/prizing/week_week6_spot2.jpg';
import week6Spot3 from '../../images/prizing/week_week6_spot3.jpg';
import week6Spot4 from '../../images/prizing/week_week6_spot4.jpg';
import week6Spot5 from '../../images/prizing/week_week6_spot5.jpg';
import week6Spot6 from '../../images/prizing/week_week6_spot6.jpg';

export const classTypes = [
  { id: 0, img: alliesTitan },
  { id: 1, img: alliesHunter },
  { id: 2, img: alliesWarlock },
];

export const platforms = [
  { id: 1, img: platformXbox },
  { id: 2, img: platformPsn },
  { id: 4, img: platformPc },
];

export const sidebarWeeksData = [
  {
    id: 1,
    title: 'Open Round',
    restrictedPlay: false,
    period: { start: '2018-10-06', end: '2018-10-13' },
    icon: iconCrucible,
    desc:
      'Play any Crucible mode during Week 1 of the Open Round and earn points for every Completed Match, Guardian Kill or Precision Kill.',
    mode: 'CRUCIBLE',
    scoring: [
      { points: 100, label: 'Every Match Completion' },
      { points: 3, label: 'Every Precision Kill' },
      { points: 1, label: 'Every Guardian Kill' },
    ],
    prizing: [
      {
        label: '1st Place: Vertagear Gaming Chair',
      },
      {
        label: 'Top 5: Astro Headset + Scuf Controller',
      },
      {
        label:
          'Top 10: $50 Bungie.net store gift card + 2 cases of Rockstar Energy',
      },
    ],
    gallery: [
      { src: weeks123_1 },
      { src: weeks123_2 },
      { src: weeks123_3 },
      { src: weeks123_4 },
      { src: originalSugarFree },
    ],
  },
  {
    id: 2,
    title: 'Open Round',
    restrictedPlay: false,
    period: { start: '2018-10-14', end: '2018-10-20' },
    icon: iconStrikes,
    desc:
      'Play Strikes during Week 2 of the Open Round and earn points for every Completed Match, Enemy Kill or Precision Kill.',
    mode: 'STRIKES',
    scoring: [
      { points: 125, label: 'Every Match Completion' },
      { points: 3, label: 'Every Precision Kill' },
      { points: 1, label: 'Every Enemy Kill' },
    ],
    prizing: [
      {
        label: '1st Place: Vertagear Gaming Chair',
      },
      {
        label: 'Top 5: Astro Headset + Scuf Controller',
      },
      {
        label:
          'Top 10: $50 Bungie.net store gift card + 2 cases of Rockstar Energy',
      },
    ],
    gallery: [
      { src: weeks123_1 },
      { src: weeks123_2 },
      { src: weeks123_3 },
      { src: weeks123_4 },
      { src: originalSugarFree },
    ],
  },
  {
    id: 3,
    title: 'Open Round',
    restrictedPlay: false,
    period: { start: '2018-10-21', end: '2018-10-27' },
    icon: iconGambit,
    desc:
      'Play Gambit mode during Week 3 of the Open Round and earn points for every Completed Match, Invader Kill, Enemy Kill or Precision Kill.',
    mode: 'GAMBIT',
    scoring: [
      { points: 150, label: 'Every Match Completion' },
      { points: 5, label: 'Every Precision Kill' },
      { points: 3, label: 'Every Invader Kill' },
      { points: 1, label: 'Every Enemy Kill' },
    ],
    prizing: [
      {
        label: '1st Place: Vertagear Gaming Chair',
      },
      {
        label: 'Top 5: Astro Headset + Scuf Controller',
      },
      {
        label:
          'Top 10: $50 Bungie.net store gift card + 2 cases of Rockstar Energy',
      },
    ],
    gallery: [
      { src: weeks123_1 },
      { src: weeks123_2 },
      { src: weeks123_3 },
      { src: weeks123_4 },
      { src: originalSugarFree },
    ],
  },
  {
    id: 4,
    title: 'LEGENDARY ROUND',
    restrictedPlay: true,
    period: { start: '2018-10-28', end: '2018-11-03' },
    icon: iconCrucible,
    desc:
      'Play any Crucible mode during Week 4 of the Legendary Round and earn points for every Completed Match, Guardian Kill or Precision Kill.',
    mode: 'CRUCIBLE',
    scoring: [
      { points: 100, label: 'Every Match Completion' },
      { points: 3, label: 'Every Precision Kill' },
      { points: 1, label: 'Every Guardian Kill' },
    ],
    prizing: [
      {
        label:
          '1st Place: iBuyPower custom PC Rig + monitor + keyboard + mouse',
      },
      {
        label:
          'Top 5: Vertagear Gaming Chair + Astro Headset + Scuf Controller',
      },
      {
        label:
          'Top 10: Alexa Limited Edition Destiny 2 Ghost + 2 cases of Rockstar Energy',
      },
      {
        label: 'Top 200: Hat + Hoodie',
      },
      {
        label: 'Top 200: Earns spot in Qualifier Round',
      },
    ],
    gallery: [
      { src: week4Spot1 },
      { src: week4Spot2 },
      { src: week4Spot3 },
      { src: week4Spot4 },
      { src: week4Spot5 },
      { src: week4Spot6 },
      { src: originalSugarFree },
    ],
  },
  {
    id: 5,
    title: 'LEGENDARY ROUND',
    restrictedPlay: true,
    period: { start: '2018-11-04', end: '2018-11-10' },
    icon: iconStrikes,
    desc:
      'Play Strikes during Week 5 of the Legendary Round and earn points for every Completed Match, Enemy Kill or Precision Kill.',
    mode: 'STRIKES',
    scoring: [
      { points: 125, label: 'Every Match Completion' },
      { points: 3, label: 'Every Precision Kill' },
      { points: 1, label: 'Every Enemy Kill' },
    ],
    prizing: [
      {
        label:
          '1st Place: iBuyPower custom PC Rig + monitor + keyboard + mouse',
      },
      {
        label:
          'Top 5: Vertagear Gaming Chair + Astro Headset + Scuf Controller',
      },
      {
        label:
          'Top 10: Alexa Limited Edition Destiny 2 Ghost + 2 cases of Rockstar Energy',
      },
      {
        label: 'Top 200: Hat + Hoodie',
      },
      {
        label: 'Top 200: Earns spot in Qualifier Round',
      },
    ],
    gallery: [
      { src: week5Spot1 },
      { src: week5Spot2 },
      { src: week5Spot3 },
      { src: week5Spot4 },
      { src: week5Spot5 },
      { src: week5Spot6 },
      { src: originalSugarFree },
    ],
  },
  {
    id: 6,
    title: 'LEGENDARY ROUND',
    restrictedPlay: true,
    period: { start: '2018-11-11', end: '2018-11-17' },
    icon: iconGambit,
    desc:
      'Play Gambit mode during Week 6 of the Legendary Round and earn points for every Completed Match, Invader, Enemy Kill or Precision Kill.',
    mode: 'GAMBIT',
    scoring: [
      { points: 150, label: 'Every Match Completion' },
      { points: 5, label: 'Every Precision Kill' },
      { points: 3, label: 'Every Invader Kill' },
      { points: 1, label: 'Every Enemy Kill' },
    ],
    prizing: [
      {
        label:
          '1st Place: iBuyPower custom PC Rig + monitor + keyboard + mouse',
      },
      {
        label:
          'Top 5: Vertagear Gaming Chair + Astro Headset + Scuf Controller',
      },
      {
        label:
          'Top 10: Alexa Limited Edition Destiny 2 Ghost + 2 cases of Rockstar Energy',
      },
      {
        label: 'Top 200: Hat + Hoodie',
      },
      {
        label: 'Top 200: Earns spot in Qualifier Round',
      },
    ],
    gallery: [
      { src: week6Spot1 },
      { src: week6Spot2 },
      { src: week6Spot3 },
      { src: week6Spot4 },
      { src: week6Spot5 },
      { src: week6Spot6 },
      { src: originalSugarFree },
    ],
  },
  {
    id: 7,
    title: 'QUALIFIER ROUND',
    restrictedPlay: true,
    period: { start: '2018-11-25', end: '2018-12-01' },
    icon: iconCrucible,
    desc:
      'Top 600 players from the Legendary Round will earn a spot in the online Qualifier Round. Top 4 win a fly-away trip to compete in the live finals event at Bungie Studios.',
    mode: 'CRUCIBLE',
    scoring: [
      { points: 100, label: 'Every Match Completion' },
      { points: 3, label: 'Every Precision Kill' },
      { points: 1, label: 'Every Guardian Kill' },
    ],
    prizing: [
      {
        label:
          'Top 4: Each player wins fly-away trip to compete in the live finals event at Bungie Studios located in Washington, USA. Fly-away prize package includes +1 guest per winner.',
      },
    ],
    gallery: [],
  },
];
