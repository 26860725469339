const week5 = [
  {
    displayName: 'Elegantgun',
    totalScore: 776863,
    membershipType: 4,
    classType: 1,
    rank: 1,
    profilePicturePath: '/img/profile/avatars/cc18.jpg',
  },
  {
    displayName: 'holforty',
    totalScore: 617852,
    membershipType: 2,
    classType: 1,
    rank: 2,
    profilePicturePath: '/img/profile/avatars/cc46.jpg',
  },
  {
    displayName: 'L01z0k3r',
    totalScore: 604303,
    membershipType: 4,
    classType: 0,
    rank: 3,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_29.jpg',
  },
  {
    displayName: 'Vendetta',
    totalScore: 578507,
    membershipType: 4,
    classType: 2,
    rank: 4,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=19&1=2&2=2&3=19&fi=37&bi=25&fl=1&m=1',
  },
  {
    displayName: 'Vinilla',
    totalScore: 543639,
    membershipType: 4,
    classType: 0,
    rank: 5,
    profilePicturePath: '/img/profile/avatars/cc08.jpg',
  },
  {
    displayName: 'TerrorizeThe805',
    totalScore: 541700,
    membershipType: 2,
    classType: 1,
    rank: 6,
    profilePicturePath: '/img/profile/avatars/bungieday_17.jpg',
  },
  {
    displayName: 'ninjyy',
    totalScore: 363877,
    membershipType: 4,
    classType: 1,
    rank: 7,
    profilePicturePath: '/img/profile/avatars/cc01.jpg',
  },
  {
    displayName: 'Typism',
    totalScore: 319564,
    membershipType: 1,
    classType: 0,
    rank: 8,
    profilePicturePath: '/img/profile/avatars/s_NobleTeam.jpg',
  },
  {
    displayName: 'Recon Zed One',
    totalScore: 318066,
    membershipType: 1,
    classType: 2,
    rank: 9,
    profilePicturePath: '/img/profile/avatars/a_reach_1.jpg',
  },
  {
    displayName: 'amanda_snipes',
    totalScore: 311496,
    membershipType: 4,
    classType: 2,
    rank: 10,
    profilePicturePath: '/img/profile/avatars/cc000010.jpg',
  },
  {
    displayName: 'Zoderak',
    totalScore: 309710,
    membershipType: 2,
    classType: 0,
    rank: 11,
    profilePicturePath: '/img/profile/avatars/e2015_11.jpg',
  },
  {
    displayName: 'TheHero',
    totalScore: 229695,
    membershipType: 4,
    classType: 1,
    rank: 12,
    profilePicturePath: '/img/profile/avatars/avatar4.jpg',
  },
  {
    displayName: 'OperationBOAR',
    totalScore: 216408,
    membershipType: 1,
    classType: 1,
    rank: 13,
    profilePicturePath: '/img/profile/avatars/seventhcolumn7.gif',
  },
  {
    displayName: 'Ekuegan',
    totalScore: 183712,
    membershipType: 4,
    classType: 1,
    rank: 14,
    profilePicturePath: '/img/profile/avatars/Destiny06.jpg',
  },
  {
    displayName: 'Grumpy',
    totalScore: 167322,
    membershipType: 4,
    classType: 1,
    rank: 15,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
  },
  {
    displayName: 'JLPERRY16',
    totalScore: 162165,
    membershipType: 2,
    classType: 0,
    rank: 16,
    profilePicturePath: '/img/profile/avatars/Destiny11.jpg',
  },
  {
    displayName: 'Blazespot',
    totalScore: 152168,
    membershipType: 4,
    classType: 0,
    rank: 17,
    profilePicturePath: '/img/profile/avatars/cc00003.jpg',
  },
  {
    displayName: 'tromblay',
    totalScore: 143541,
    membershipType: 4,
    classType: 1,
    rank: 18,
    profilePicturePath: '/img/profile/avatars/Destiny04.jpg',
  },
  {
    displayName: 'Llamas at IHOP',
    totalScore: 143384,
    membershipType: 1,
    classType: 2,
    rank: 19,
    profilePicturePath: '/img/profile/avatars/cc21.jpg',
  },
  {
    displayName: 'RiffRaff Giraffe',
    totalScore: 129188,
    membershipType: 2,
    classType: 0,
    rank: 20,
    profilePicturePath: '/img/profile/avatars/avatar2.jpg',
  },
  {
    displayName: 'Murtag',
    totalScore: 129001,
    membershipType: 4,
    classType: 0,
    rank: 21,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
  },
  {
    displayName: 'GrayZXA',
    totalScore: 124154,
    membershipType: 2,
    classType: 1,
    rank: 22,
    profilePicturePath: '/img/profile/avatars/avatar18.jpg',
  },
  {
    displayName: 'xCookieJx',
    totalScore: 117059,
    membershipType: 2,
    classType: 1,
    rank: 23,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'Ronatello',
    totalScore: 115683,
    membershipType: 2,
    classType: 1,
    rank: 24,
    profilePicturePath: '/img/profile/avatars/bungieday_06.jpg',
  },
  {
    displayName: 'Mr JayBasic',
    totalScore: 111369,
    membershipType: 1,
    classType: 1,
    rank: 25,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'Mrdmp305',
    totalScore: 110842,
    membershipType: 4,
    classType: 1,
    rank: 26,
    profilePicturePath: '/img/profile/avatars/cc00007.jpg',
  },
  {
    displayName: 'Deus Ex BBQ',
    totalScore: 105249,
    membershipType: 1,
    classType: 2,
    rank: 27,
    profilePicturePath: '/img/profile/avatars/avatar12.jpg',
  },
  {
    displayName: 'Nathen-N-8',
    totalScore: 96945,
    membershipType: 2,
    classType: 0,
    rank: 28,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
  },
  {
    displayName: 'WhiteGknight',
    totalScore: 94841,
    membershipType: 2,
    classType: 2,
    rank: 29,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'hyperPANDAcat',
    totalScore: 93463,
    membershipType: 2,
    classType: 0,
    rank: 30,
    profilePicturePath: '/img/profile/avatars/cc12.jpg',
  },
  {
    displayName: 'Dragonfear78',
    totalScore: 87104,
    membershipType: 2,
    classType: 0,
    rank: 31,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'a-j_dynamic_duo',
    totalScore: 85096,
    membershipType: 2,
    classType: 0,
    rank: 32,
    profilePicturePath: '/img/profile/avatars/oonsk.gif',
  },
  {
    displayName: 'Destronite',
    totalScore: 82408,
    membershipType: 1,
    classType: 2,
    rank: 33,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'Emprox',
    totalScore: 80281,
    membershipType: 2,
    classType: 1,
    rank: 34,
    profilePicturePath: '/img/profile/avatars/bungieday_05.jpg',
  },
  {
    displayName: 'Angelbabyluv',
    totalScore: 79079,
    membershipType: 2,
    classType: 2,
    rank: 35,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'PonyConspiracy',
    totalScore: 71926,
    membershipType: 2,
    classType: 0,
    rank: 36,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'TitanSlayer1000',
    totalScore: 69989,
    membershipType: 1,
    classType: 1,
    rank: 37,
    profilePicturePath: '/img/profile/avatars/septagonsuperanimated6.gif',
  },
  {
    displayName: 'Nindrone',
    totalScore: 68011,
    membershipType: 4,
    classType: 0,
    rank: 38,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Dann1Blaz3r',
    totalScore: 65277,
    membershipType: 4,
    classType: 2,
    rank: 39,
    profilePicturePath: '/img/profile/avatars/cc32.jpg',
  },
  {
    displayName: 'QuantomSandwich',
    totalScore: 63027,
    membershipType: 1,
    classType: 1,
    rank: 40,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'OMIKRUD',
    totalScore: 60633,
    membershipType: 1,
    classType: 0,
    rank: 41,
    profilePicturePath: '/img/profile/avatars/rarw.gif',
  },
  {
    displayName: 'Micca1996',
    totalScore: 60560,
    membershipType: 2,
    classType: 2,
    rank: 42,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'jasminita',
    totalScore: 59294,
    membershipType: 2,
    classType: 1,
    rank: 43,
    profilePicturePath: '/img/profile/avatars/cc24.jpg',
  },
  {
    displayName: 'SteelRickochet',
    totalScore: 57619,
    membershipType: 1,
    classType: 1,
    rank: 44,
    profilePicturePath: '/img/profile/avatars/cc20.jpg',
  },
  {
    displayName: 'kcbeans76',
    totalScore: 57249,
    membershipType: 2,
    classType: 0,
    rank: 45,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Sylicant',
    totalScore: 56863,
    membershipType: 1,
    classType: 1,
    rank: 46,
    profilePicturePath: '/img/profile/avatars/cc73.jpg',
  },
  {
    displayName: 'KN0WLYF3',
    totalScore: 55431,
    membershipType: 4,
    classType: 1,
    rank: 47,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'GUNSABLAZ1NG',
    totalScore: 55303,
    membershipType: 1,
    classType: 0,
    rank: 48,
    profilePicturePath: '/img/profile/avatars/bungieday_24.jpg',
  },
  {
    displayName: 'katlyn_kat',
    totalScore: 54629,
    membershipType: 2,
    classType: 2,
    rank: 49,
    profilePicturePath: '/img/profile/avatars/cc06.jpg',
  },
  {
    displayName: 'K04LaBEE87',
    totalScore: 53249,
    membershipType: 2,
    classType: 1,
    rank: 50,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Derpindibble',
    totalScore: 53099,
    membershipType: 4,
    classType: 2,
    rank: 51,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'trudeaulove',
    totalScore: 52420,
    membershipType: 2,
    classType: 2,
    rank: 52,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'brentd25',
    totalScore: 52248,
    membershipType: 2,
    classType: 1,
    rank: 53,
    profilePicturePath: '/img/profile/avatars/s_Vitruvian_Chief.jpg',
  },
  {
    displayName: 'North1984',
    totalScore: 51150,
    membershipType: 1,
    classType: 2,
    rank: 54,
    profilePicturePath: '/img/profile/avatars/huntinghunter.jpg',
  },
  {
    displayName: 'vampirefreak86',
    totalScore: 51068,
    membershipType: 1,
    classType: 0,
    rank: 55,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Thiodag',
    totalScore: 49961,
    membershipType: 4,
    classType: 0,
    rank: 56,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'ladyvirus69',
    totalScore: 49905,
    membershipType: 1,
    classType: 2,
    rank: 57,
    profilePicturePath: '/img/profile/avatars/cc000012.jpg',
  },
  {
    displayName: 'blackopsbeast71',
    totalScore: 48465,
    membershipType: 4,
    classType: 0,
    rank: 58,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_10.jpg',
  },
  {
    displayName: 'sunnman910',
    totalScore: 47730,
    membershipType: 2,
    classType: 1,
    rank: 59,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'Munch-ies420',
    totalScore: 46253,
    membershipType: 2,
    classType: 1,
    rank: 60,
    profilePicturePath: '/img/profile/avatars/cc00007.jpg',
  },
  {
    displayName: 'Shotgun Daddy',
    totalScore: 45431,
    membershipType: 2,
    classType: 0,
    rank: 61,
    profilePicturePath: '/img/profile/avatars/avatar2.jpg',
  },
  {
    displayName: 'Crowskee',
    totalScore: 45398,
    membershipType: 4,
    classType: 0,
    rank: 62,
    profilePicturePath: '/img/profile/avatars/cc05.jpg',
  },
  {
    displayName: 'EagleScope',
    totalScore: 44665,
    membershipType: 2,
    classType: 2,
    rank: 63,
    profilePicturePath: '/img/profile/avatars/sevenz.gif',
  },
  {
    displayName: 'Regulator5678',
    totalScore: 44600,
    membershipType: 1,
    classType: 1,
    rank: 64,
    profilePicturePath: '/img/profile/avatars/cc32.jpg',
  },
  {
    displayName: 'Arpacan',
    totalScore: 44250,
    membershipType: 4,
    classType: 0,
    rank: 65,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'IRON BOURN',
    totalScore: 44207,
    membershipType: 1,
    classType: 0,
    rank: 66,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_25.jpg',
  },
  {
    displayName: 'RogueLight',
    totalScore: 43929,
    membershipType: 4,
    classType: 1,
    rank: 67,
    profilePicturePath: '/img/profile/avatars/cc42.jpg',
  },
  {
    displayName: 'Athenuyel',
    totalScore: 43235,
    membershipType: 2,
    classType: 0,
    rank: 68,
    profilePicturePath: '/img/profile/avatars/avatar29.jpg',
  },
  {
    displayName: 'oXe Luke',
    totalScore: 42632,
    membershipType: 1,
    classType: 0,
    rank: 69,
    profilePicturePath: '/img/profile/avatars/th_BN2Avs33.jpg',
  },
  {
    displayName: 'SHADOWxToF',
    totalScore: 42499,
    membershipType: 2,
    classType: 0,
    rank: 70,
    profilePicturePath: '/img/profile/avatars/cc23.jpg',
  },
  {
    displayName: 'Rocketblaster369',
    totalScore: 42423,
    membershipType: 2,
    classType: 2,
    rank: 71,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'saber_rayko',
    totalScore: 41900,
    membershipType: 2,
    classType: 1,
    rank: 72,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'punsher',
    totalScore: 41309,
    membershipType: 2,
    classType: 0,
    rank: 73,
    profilePicturePath: '/img/profile/avatars/skull1w.gif',
  },
  {
    displayName: 'sAnitySCREAMS',
    totalScore: 41101,
    membershipType: 4,
    classType: 2,
    rank: 74,
    profilePicturePath: '/img/profile/avatars/avatar29.jpg',
  },
  {
    displayName: 'Agent471909',
    totalScore: 40843,
    membershipType: 2,
    classType: 0,
    rank: 75,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'BuTTeRfLi18',
    totalScore: 40798,
    membershipType: 4,
    classType: 2,
    rank: 76,
    profilePicturePath: '/img/profile/avatars/seventhcolumn7.gif',
  },
  {
    displayName: 'CrEaTiOnN',
    totalScore: 40605,
    membershipType: 2,
    classType: 0,
    rank: 77,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'adiojoe627',
    totalScore: 40273,
    membershipType: 4,
    classType: 2,
    rank: 78,
    profilePicturePath: '/img/profile/avatars/cc00005.jpg',
  },
  {
    displayName: 'iTz BlaDesa',
    totalScore: 39583,
    membershipType: 1,
    classType: 0,
    rank: 79,
    profilePicturePath: '/img/profile/avatars/h3chief_strut.gif',
  },
  {
    displayName: 'HappyJoey91559',
    totalScore: 39128,
    membershipType: 1,
    classType: 0,
    rank: 80,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'highestsociety',
    totalScore: 38836,
    membershipType: 2,
    classType: 1,
    rank: 81,
    profilePicturePath: '/img/profile/avatars/cc67.jpg',
  },
  {
    displayName: 'AzleSnow',
    totalScore: 38722,
    membershipType: 4,
    classType: 1,
    rank: 82,
    profilePicturePath: '/img/profile/avatars/Destiny06.jpg',
  },
  {
    displayName: 'helmetinthebush',
    totalScore: 38482,
    membershipType: 1,
    classType: 1,
    rank: 83,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=3&1=29&2=27&3=3&fi=22&bi=48&fl=1&m=3',
  },
  {
    displayName: 'Collateral_',
    totalScore: 38386,
    membershipType: 4,
    classType: 0,
    rank: 84,
    profilePicturePath: '/img/profile/avatars/cc07.jpg',
  },
  {
    displayName: 'classi641',
    totalScore: 38377,
    membershipType: 2,
    classType: 2,
    rank: 85,
    profilePicturePath: '/img/profile/avatars/jolly_roger.jpg',
  },
  {
    displayName: 'Kamahin',
    totalScore: 38162,
    membershipType: 2,
    classType: 2,
    rank: 86,
    profilePicturePath: '/img/profile/avatars/cc05.jpg',
  },
  {
    displayName: 'philghost123',
    totalScore: 38104,
    membershipType: 2,
    classType: 1,
    rank: 87,
    profilePicturePath: '/img/profile/avatars/cc28.jpg',
  },
  {
    displayName: 'PaTriick1996',
    totalScore: 37818,
    membershipType: 2,
    classType: 0,
    rank: 88,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'blueraven2234',
    totalScore: 37647,
    membershipType: 2,
    classType: 1,
    rank: 89,
    profilePicturePath: '/img/profile/avatars/trigunT7R_avatar.gif',
  },
  {
    displayName: 'Keonelehua',
    totalScore: 37406,
    membershipType: 4,
    classType: 2,
    rank: 90,
    profilePicturePath: '/img/profile/avatars/h3chief_reflection.gif',
  },
  {
    displayName: 'IRIIGSIBY3000',
    totalScore: 37367,
    membershipType: 1,
    classType: 1,
    rank: 91,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'ProGoat',
    totalScore: 37243,
    membershipType: 4,
    classType: 0,
    rank: 92,
    profilePicturePath: '/img/profile/avatars/cc54.jpg',
  },
  {
    displayName: 'Borchardt',
    totalScore: 37139,
    membershipType: 4,
    classType: 1,
    rank: 93,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Rock Johnny',
    totalScore: 36939,
    membershipType: 1,
    classType: 2,
    rank: 94,
    profilePicturePath: '/img/profile/avatars/bungieday_08.jpg',
  },
  {
    displayName: 'Xamthos',
    totalScore: 36902,
    membershipType: 2,
    classType: 2,
    rank: 95,
    profilePicturePath: '/img/profile/avatars/e2015_15.jpg',
  },
  {
    displayName: 'xNightii',
    totalScore: 36681,
    membershipType: 2,
    classType: 1,
    rank: 96,
    profilePicturePath: '/img/profile/avatars/Destiny11.jpg',
  },
  {
    displayName: 'DaLifeForce',
    totalScore: 36498,
    membershipType: 2,
    classType: 1,
    rank: 97,
    profilePicturePath: '/img/profile/avatars/cc47.jpg',
  },
  {
    displayName: 'SIeepIy',
    totalScore: 36403,
    membershipType: 1,
    classType: 1,
    rank: 98,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Daddy3019',
    totalScore: 36382,
    membershipType: 1,
    classType: 2,
    rank: 99,
    profilePicturePath: '/img/profile/avatars/th_BnetAvs27.jpg',
  },
  {
    displayName: 'Bruder',
    totalScore: 36233,
    membershipType: 2,
    classType: 0,
    rank: 100,
    profilePicturePath: '/img/profile/avatars/linglings.gif',
  },
  {
    displayName: 'Toastji2400',
    totalScore: 35978,
    membershipType: 4,
    classType: 2,
    rank: 101,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
  },
  {
    displayName: 'TheRealOmega01',
    totalScore: 35814,
    membershipType: 2,
    classType: 0,
    rank: 102,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_34.jpg',
  },
  {
    displayName: 'owlz-HD',
    totalScore: 35763,
    membershipType: 2,
    classType: 1,
    rank: 103,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'faulky2852',
    totalScore: 35624,
    membershipType: 2,
    classType: 0,
    rank: 104,
    profilePicturePath: '/img/profile/avatars/Destiny25.jpg',
  },
  {
    displayName: 'A large Potato',
    totalScore: 35458,
    membershipType: 4,
    classType: 0,
    rank: 105,
    profilePicturePath: '/img/profile/avatars/flamingninja.gif',
  },
  {
    displayName: 'FlatBunny391383',
    totalScore: 35244,
    membershipType: 1,
    classType: 1,
    rank: 106,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'FGX',
    totalScore: 35159,
    membershipType: 4,
    classType: 0,
    rank: 107,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=0&1=2&2=2&3=2&fi=16&bi=40&fl=0&m=1',
  },
  {
    displayName: 'Baxlyy',
    totalScore: 34955,
    membershipType: 4,
    classType: 2,
    rank: 108,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_34.jpg',
  },
  {
    displayName: 'KenyTaverous',
    totalScore: 34854,
    membershipType: 4,
    classType: 2,
    rank: 109,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
  },
  {
    displayName: 'Virgil',
    totalScore: 34698,
    membershipType: 2,
    classType: 0,
    rank: 110,
    profilePicturePath: '/img/profile/avatars/bungieday_10.jpg',
  },
  {
    displayName: 'INFamousSwiftz',
    totalScore: 34658,
    membershipType: 2,
    classType: 1,
    rank: 111,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Castle',
    totalScore: 34340,
    membershipType: 4,
    classType: 2,
    rank: 112,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'DerFreshe',
    totalScore: 34203,
    membershipType: 4,
    classType: 0,
    rank: 113,
    profilePicturePath: '/img/profile/avatars/logo-skull2.gif',
  },
  {
    displayName: 'Stackzzz_18',
    totalScore: 34173,
    membershipType: 2,
    classType: 1,
    rank: 114,
    profilePicturePath: '/img/profile/avatars/Destiny21.jpg',
  },
  {
    displayName: 'Jupiter2800',
    totalScore: 34085,
    membershipType: 2,
    classType: 0,
    rank: 115,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'Cokkymonster',
    totalScore: 34019,
    membershipType: 2,
    classType: 2,
    rank: 116,
    profilePicturePath: '/img/profile/avatars/cc68.jpg',
  },
  {
    displayName: 'ducatic',
    totalScore: 34014,
    membershipType: 4,
    classType: 0,
    rank: 117,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'BAU Fuzzy Wuzzy',
    totalScore: 33879,
    membershipType: 4,
    classType: 1,
    rank: 118,
    profilePicturePath: '/img/profile/avatars/CarnageZone.gif',
  },
  {
    displayName: 'DemonSpawn0618',
    totalScore: 33815,
    membershipType: 2,
    classType: 1,
    rank: 119,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'MegunCasey',
    totalScore: 33774,
    membershipType: 2,
    classType: 1,
    rank: 120,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'TOONLINK126',
    totalScore: 33731,
    membershipType: 2,
    classType: 1,
    rank: 121,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
  },
  {
    displayName: 'inFAMOUS Ninja',
    totalScore: 33707,
    membershipType: 2,
    classType: 1,
    rank: 122,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'I_Am_Dovahkiin17',
    totalScore: 33579,
    membershipType: 2,
    classType: 1,
    rank: 123,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'IronXVI',
    totalScore: 33554,
    membershipType: 2,
    classType: 1,
    rank: 124,
    profilePicturePath: '/img/profile/avatars/odst_skull_8_3.png',
  },
  {
    displayName: 'Thazmo',
    totalScore: 33553,
    membershipType: 4,
    classType: 0,
    rank: 125,
    profilePicturePath: '/img/profile/avatars/th_BnetAvs10.jpg',
  },
  {
    displayName: 'Fibonacci',
    totalScore: 33540,
    membershipType: 4,
    classType: 2,
    rank: 126,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'BLUEvAsH101',
    totalScore: 33452,
    membershipType: 2,
    classType: 2,
    rank: 127,
    profilePicturePath: '/img/profile/avatars/e2015_09.jpg',
  },
  {
    displayName: 'twitch-Doxxnoxx',
    totalScore: 33440,
    membershipType: 2,
    classType: 2,
    rank: 128,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Shadow99k',
    totalScore: 33283,
    membershipType: 2,
    classType: 1,
    rank: 129,
    profilePicturePath: '/img/profile/avatars/bungieday_24.jpg',
  },
  {
    displayName: 'X Vepz X',
    totalScore: 33260,
    membershipType: 1,
    classType: 1,
    rank: 130,
    profilePicturePath: '/img/profile/avatars/cc49.jpg',
  },
  {
    displayName: 'ImmaWizard4',
    totalScore: 33250,
    membershipType: 4,
    classType: 0,
    rank: 131,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'SGen1625',
    totalScore: 33165,
    membershipType: 2,
    classType: 2,
    rank: 132,
    profilePicturePath: '/img/profile/avatars/bungieday_23.jpg',
  },
  {
    displayName: 'Final_Meds',
    totalScore: 33155,
    membershipType: 2,
    classType: 1,
    rank: 133,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_45.jpg',
  },
  {
    displayName: 'MiesVanDeKalb',
    totalScore: 33137,
    membershipType: 2,
    classType: 1,
    rank: 134,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'freezhart',
    totalScore: 33137,
    membershipType: 4,
    classType: 2,
    rank: 135,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'irish1620',
    totalScore: 33130,
    membershipType: 2,
    classType: 2,
    rank: 136,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_41.jpg',
  },
  {
    displayName: 'MrTurtleWing',
    totalScore: 33112,
    membershipType: 4,
    classType: 1,
    rank: 137,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Profanendobscene',
    totalScore: 33001,
    membershipType: 2,
    classType: 1,
    rank: 138,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'Diggie',
    totalScore: 32791,
    membershipType: 2,
    classType: 2,
    rank: 139,
    profilePicturePath: '/img/profile/avatars/bungieday_10.jpg',
  },
  {
    displayName: 'Sub_limes',
    totalScore: 32746,
    membershipType: 4,
    classType: 2,
    rank: 140,
    profilePicturePath: '/img/profile/avatars/cc42.jpg',
  },
  {
    displayName: 'Haldenbubble93',
    totalScore: 32695,
    membershipType: 2,
    classType: 2,
    rank: 141,
    profilePicturePath: '/img/profile/avatars/attention2.gif',
  },
  {
    displayName: 'c_minjung_',
    totalScore: 32579,
    membershipType: 2,
    classType: 1,
    rank: 142,
    profilePicturePath: '/img/profile/avatars/bungieday_16.jpg',
  },
  {
    displayName: 'Evaqs',
    totalScore: 32577,
    membershipType: 4,
    classType: 2,
    rank: 143,
    profilePicturePath: '/img/profile/avatars/sevenz.gif',
  },
  {
    displayName: 'Tydaspy',
    totalScore: 32538,
    membershipType: 2,
    classType: 1,
    rank: 144,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_26.jpg',
  },
  {
    displayName: 'Crimsoncrisis17',
    totalScore: 32505,
    membershipType: 2,
    classType: 1,
    rank: 145,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
  },
  {
    displayName: 'CCubic',
    totalScore: 32493,
    membershipType: 4,
    classType: 0,
    rank: 146,
    profilePicturePath: '/img/profile/avatars/cc000015.jpg',
  },
  {
    displayName: 'bud everett',
    totalScore: 32457,
    membershipType: 2,
    classType: 1,
    rank: 147,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Zweeps',
    totalScore: 32438,
    membershipType: 4,
    classType: 0,
    rank: 148,
    profilePicturePath: '/img/profile/avatars/cc30.jpg',
  },
  {
    displayName: 'sirloganmoore1',
    totalScore: 32375,
    membershipType: 2,
    classType: 1,
    rank: 149,
    profilePicturePath: '/img/profile/avatars/avatar3.jpg',
  },
  {
    displayName: 'jwillyumz',
    totalScore: 32338,
    membershipType: 4,
    classType: 1,
    rank: 150,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Jonny',
    totalScore: 32338,
    membershipType: 4,
    classType: 0,
    rank: 151,
    profilePicturePath: '/img/profile/avatars/Destiny21.jpg',
  },
  {
    displayName: 'RedFlyingTurtles',
    totalScore: 32224,
    membershipType: 2,
    classType: 1,
    rank: 152,
    profilePicturePath: '/img/profile/avatars/avatar27.jpg',
  },
  {
    displayName: 'Qbano86',
    totalScore: 32178,
    membershipType: 2,
    classType: 0,
    rank: 153,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'Chikn',
    totalScore: 32058,
    membershipType: 4,
    classType: 1,
    rank: 154,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Datenshix',
    totalScore: 31855,
    membershipType: 2,
    classType: 2,
    rank: 155,
    profilePicturePath: '/img/profile/avatars/cc07.jpg',
  },
  {
    displayName: 'Sneak_420',
    totalScore: 31845,
    membershipType: 2,
    classType: 1,
    rank: 156,
    profilePicturePath: '/img/profile/avatars/e2015_14.jpg',
  },
  {
    displayName: 'Otohh',
    totalScore: 31841,
    membershipType: 2,
    classType: 0,
    rank: 157,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'CreepyGamer6600',
    totalScore: 31762,
    membershipType: 2,
    classType: 2,
    rank: 158,
    profilePicturePath: '/img/profile/avatars/gravemindzz.gif',
  },
  {
    displayName: 'Skiptro',
    totalScore: 31650,
    membershipType: 1,
    classType: 1,
    rank: 159,
    profilePicturePath: '/img/profile/avatars/cc63.jpg',
  },
  {
    displayName: 'bleach punk',
    totalScore: 31581,
    membershipType: 2,
    classType: 2,
    rank: 160,
    profilePicturePath: '/img/profile/avatars/Destiny16.jpg',
  },
  {
    displayName: 'atclouse',
    totalScore: 31580,
    membershipType: 4,
    classType: 0,
    rank: 161,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
  },
  {
    displayName: 'pitita-82',
    totalScore: 31455,
    membershipType: 2,
    classType: 1,
    rank: 162,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
  },
  {
    displayName: 'texastechjoe',
    totalScore: 31454,
    membershipType: 2,
    classType: 0,
    rank: 163,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_41.jpg',
  },
  {
    displayName: 'Darkspeeder5',
    totalScore: 31424,
    membershipType: 2,
    classType: 1,
    rank: 164,
    profilePicturePath: '/img/profile/avatars/cc000013.jpg',
  },
  {
    displayName: 'Valon',
    totalScore: 31422,
    membershipType: 4,
    classType: 2,
    rank: 165,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'YOURKEWL99',
    totalScore: 31418,
    membershipType: 4,
    classType: 1,
    rank: 166,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Andy McNad',
    totalScore: 31340,
    membershipType: 1,
    classType: 1,
    rank: 167,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=1&1=2&2=2&3=2&fi=5&bi=27&fl=0&m=0',
  },
  {
    displayName: 's m o k e y Ill',
    totalScore: 31260,
    membershipType: 1,
    classType: 1,
    rank: 168,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
  },
  {
    displayName: 'Tbon3Strik3r',
    totalScore: 31153,
    membershipType: 1,
    classType: 2,
    rank: 169,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Snooz3',
    totalScore: 31121,
    membershipType: 1,
    classType: 0,
    rank: 170,
    profilePicturePath: '/img/profile/avatars/avatar27.jpg',
  },
  {
    displayName: 'Airjaros169',
    totalScore: 31032,
    membershipType: 1,
    classType: 1,
    rank: 171,
    profilePicturePath: '/img/profile/avatars/e2015_14.jpg',
  },
  {
    displayName: 'Samc1987',
    totalScore: 31031,
    membershipType: 1,
    classType: 1,
    rank: 172,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'SoCalBear',
    totalScore: 31008,
    membershipType: 2,
    classType: 2,
    rank: 173,
    profilePicturePath: '/img/profile/avatars/s_AVATAR.jpg',
  },
  {
    displayName: 'Shadowkralle',
    totalScore: 30990,
    membershipType: 2,
    classType: 1,
    rank: 174,
    profilePicturePath: '/img/profile/avatars/cc24.jpg',
  },
  {
    displayName: 'drpeppermd1990',
    totalScore: 30923,
    membershipType: 2,
    classType: 2,
    rank: 175,
    profilePicturePath: '/img/profile/avatars/linglings.gif',
  },
  {
    displayName: 'SwatxXxComatose',
    totalScore: 30912,
    membershipType: 1,
    classType: 1,
    rank: 176,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
  },
  {
    displayName: 'MushieSnuggs',
    totalScore: 30906,
    membershipType: 4,
    classType: 2,
    rank: 177,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'jvs1288',
    totalScore: 30800,
    membershipType: 2,
    classType: 2,
    rank: 178,
    profilePicturePath: '/img/profile/avatars/Monitor01_T.gif',
  },
  {
    displayName: 'Techmaturgic',
    totalScore: 30783,
    membershipType: 4,
    classType: 0,
    rank: 179,
    profilePicturePath: '/img/profile/avatars/cc14.jpg',
  },
  {
    displayName: 'TheHyperWeeMan',
    totalScore: 30772,
    membershipType: 1,
    classType: 0,
    rank: 180,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'Munchlax95',
    totalScore: 30707,
    membershipType: 2,
    classType: 1,
    rank: 181,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
  },
  {
    displayName: 'xINSERTPUNHEREx',
    totalScore: 30702,
    membershipType: 1,
    classType: 1,
    rank: 182,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'Rubidium',
    totalScore: 30678,
    membershipType: 4,
    classType: 2,
    rank: 183,
    profilePicturePath: '/img/profile/avatars/avatar28.jpg',
  },
  {
    displayName: 'vizualassassin77',
    totalScore: 30663,
    membershipType: 2,
    classType: 1,
    rank: 184,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'LordBagel',
    totalScore: 30529,
    membershipType: 4,
    classType: 0,
    rank: 185,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'skater_Joe91',
    totalScore: 30518,
    membershipType: 1,
    classType: 1,
    rank: 186,
    profilePicturePath: '/img/profile/avatars/Destiny07.jpg',
  },
  {
    displayName: 'dane4242',
    totalScore: 30509,
    membershipType: 2,
    classType: 2,
    rank: 187,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'Arblex',
    totalScore: 30490,
    membershipType: 4,
    classType: 2,
    rank: 188,
    profilePicturePath: '/img/profile/avatars/cc45.jpg',
  },
  {
    displayName: 'DIG_it_or_DIE',
    totalScore: 30479,
    membershipType: 2,
    classType: 2,
    rank: 189,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'ChaosAngel2112',
    totalScore: 30397,
    membershipType: 1,
    classType: 1,
    rank: 190,
    profilePicturePath: '/img/profile/avatars/e2015_14.jpg',
  },
  {
    displayName: 'TeggySI',
    totalScore: 30378,
    membershipType: 1,
    classType: 0,
    rank: 191,
    profilePicturePath: '/img/profile/avatars/a_reach_4.jpg',
  },
  {
    displayName: 'Vixon',
    totalScore: 30307,
    membershipType: 2,
    classType: 2,
    rank: 192,
    profilePicturePath: '/img/profile/avatars/cc50.jpg',
  },
  {
    displayName: 'Kuriah',
    totalScore: 30257,
    membershipType: 4,
    classType: 2,
    rank: 193,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_40.jpg',
  },
  {
    displayName: 'Xylo_Void',
    totalScore: 30169,
    membershipType: 2,
    classType: 1,
    rank: 194,
    profilePicturePath: '/img/profile/avatars/Destiny16.jpg',
  },
  {
    displayName: 'VinWop',
    totalScore: 30129,
    membershipType: 1,
    classType: 0,
    rank: 195,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'unusualRenegade',
    totalScore: 30022,
    membershipType: 4,
    classType: 2,
    rank: 196,
    profilePicturePath: '/img/profile/avatars/cc18.jpg',
  },
  {
    displayName: 'ScrappieDough',
    totalScore: 29951,
    membershipType: 1,
    classType: 0,
    rank: 197,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_43.jpg',
  },
  {
    displayName: 'Se7en14',
    totalScore: 29943,
    membershipType: 1,
    classType: 1,
    rank: 198,
    profilePicturePath: '/img/profile/avatars/Seven.gif',
  },
  {
    displayName: 'flashcat85',
    totalScore: 29879,
    membershipType: 1,
    classType: 2,
    rank: 199,
    profilePicturePath: '/img/profile/avatars/avatar29.jpg',
  },
  {
    displayName: 'thereaper3143',
    totalScore: 29865,
    membershipType: 4,
    classType: 0,
    rank: 200,
    profilePicturePath: '/img/profile/avatars/Destiny04.jpg',
  },
];

export default week5;
