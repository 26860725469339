import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal/Modal';
import ModalClose from '../ModalClose/ModalClose';
import Carousel from '../Carousel/Carousel';

const ModalImage = ({ onClose, children, gallery, index }) => (
  <Modal fullWidth>
    <ModalClose onClick={onClose} />
    <div style={{ textAlign: 'center' }}>
      <Carousel
        settings={{
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: false,
          adaptiveHeight: true,
          initialSlide: index,
        }}
        slides={gallery}
      />

      {children}
    </div>
  </Modal>
);

ModalImage.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  ).isRequired,
  index: PropTypes.number,
};

ModalImage.defaultProps = {
  children: null,
  index: 0,
};

export default ModalImage;
