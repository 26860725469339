import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { StaggeredMotion, spring, presets } from 'react-motion';

import { week1, week2, week3, week4, week5, week6, week7 } from './weeks';
import { classTypes, platforms } from './competition';

const getClassName = classType => {
  const classObject = classTypes.find(cls => classType === cls.id);
  return classObject.img;
};

const getPlatformName = platformType => {
  const platformObject = platforms.find(plt => platformType === plt.id);
  return platformObject.img;
};

const getWeekData = weekNumber =>
  ({
    '1': week1,
    '2': week2,
    '3': week3,
    '4': week4,
    '5': week5,
    '6': week6,
    '7': week7,
  }[weekNumber] || week1);

const getLeaderboardItems = (data, boxes) => {
  return (
    <StaggeredMotion
      defaultStyles={boxes}
      styles={prevStyles =>
        prevStyles.map((item, i) => {
          return i === 0
            ? { scale: spring(1, presets.noWobble) }
            : prevStyles[i - 1];
        })
      }
    >
      {interpolatingStyles => {
        return (
          <Fragment>
            {interpolatingStyles.map((item, i) => {
              return (
                <div
                  className={cx(
                    'Leaderboard__leader',
                    { top3: i <= 4 },
                    { top10: i >= 5 && i < 10 },
                  )}
                  key={i.toString()}
                  style={{
                    transform: `scale(${item.scale}, ${item.scale})`,
                  }}
                >
                  <div className="Leaderboard__pos">{data[i].rank}</div>
                  <div className="Leaderboard__avatar">
                    <img
                      src={
                        data[i].profilePicturePath.startsWith('http')
                          ? data[i].profilePicturePath
                          : `//bungie.net${data[i].profilePicturePath}`
                      }
                      alt=""
                    />
                  </div>
                  <div className="Leaderboard__body">
                    <div className="Leaderboard__player">
                      <div className="Leaderboard__name">
                        {data[i].displayName}
                      </div>
                      <div className="Leaderboard__icons">
                        <div className="Leaderboard__icon">
                          <img src={getClassName(data[i].classType)} alt="" />
                        </div>
                        {data[i].membershipType !== 0 && (
                          <div className="Leaderboard__icon">
                            <img
                              src={getPlatformName(data[i].membershipType)}
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="Leaderboard__position">
                      <div className="Leaderboard__score">
                        {data[i].totalScore.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Fragment>
        );
      }}
    </StaggeredMotion>
  );
};

const LeaderboardLeaders = ({ weekNumber }) => {
  const weekData = getWeekData(weekNumber);

  const boxes = Array.from({ length: weekData.length }, () => ({
    scale: 0,
  }));

  return (
    <div className="Leaderboard__leaders">
      {getLeaderboardItems(weekData, boxes)}
    </div>
  );
};

LeaderboardLeaders.propTypes = {
  weekNumber: PropTypes.number,
};

LeaderboardLeaders.defaultProps = {
  weekNumber: 1,
};

export default LeaderboardLeaders;
