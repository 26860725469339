import styled from 'styled-components';
import * as defaultTheme from '../variables';
import { fadeInUp } from '../animations';

import bgHeroDashboard from '../../images/bg_HeroDashboard.jpg';

const HeroCompetitionStyled = styled('div')`
  position: relative;
  margin-top: 60px;
  color: ${p => p.theme.white};
  background-image: url(${bgHeroDashboard});
  background-repeat: no-repeat;
  background-position: right top;
  animation-name: ${fadeInUp};
  animation-duration: 800ms;
  animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  z-index: 1;

  @media (${p => p.theme.lgMin}) {
    background-size: cover;
  }

  .HeroCompetition__video {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }

    > video {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .HeroCompetition__inner {
    position: relative;
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;
    padding: 75px 20px;

    @media (${p => p.theme.mdMin}) {
      display: flex;
    }

    @media (${p => p.theme.lgMin}) {
      padding: 75px 0;
    }
  }

  .HeroCompetition__main {
    flex-grow: 1;
    padding-right: 40px;
  }

  .HeroCompetition__logo {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @media (${p => p.theme.smMin}) {
      display: block;
    }
  }

  .HeroCompetition__logoImg {
    flex-shrink: 0;
    width: 105px;

    @media (${p => p.theme.smMin}) {
      width: 147px;
      margin-bottom: 20px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .HeroCompetition__title {
    margin-left: 12px;
    font-size: 26px;
    font-weight: 900;
    line-height: 26px;
    letter-spacing: 0.98px;
    text-transform: uppercase;

    @media (min-width: 420px) {
      font-size: 31px;
      line-height: 34px;
    }

    @media (${p => p.theme.smMin}) {
      max-width: 600px;
      margin: 0;
      font-size: 50px;
      line-height: 50px;
    }
  }

  .HeroCompetition__txt {
    max-width: 531px;
    margin: 16px 0;
    font-size: 14px;
    line-height: 22px;
  }

  .HeroCompetition__engrams {
    width: 100%;
    padding: 24px;
    background-color: rgba(0, 0, 0, 0.75);

    @media (${p => p.theme.smMax}) {
      margin-top: 40px;
    }

    @media (${p => p.theme.mdMin}) {
      max-width: 500px;
      padding: 36px;
    }
  }

  .HeroCompetition__challenge {
    display: inline-block;
    line-height: 1.3;

    h4 {
      margin: 32px 0 2px;
      font-size: 17px;
      text-decoration: underline;
      text-transform: uppercase;
    }

    p {
      margin: 0 0 16px;

      &:last-child {
        margin: 0;
      }
    }

    ul {
      padding-left: 16px;
      list-style-type: square;
    }

    li {
      margin: 2px 0;
    }
  }

  .HeroCompetition__badge {
    display: inline-block;
    margin-top: -0.205em;
    margin-left: 8px;
    padding: 2px 5px;
    color: white;
    background-color: #d0021b;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
  }
`;

HeroCompetitionStyled.defaultProps = {
  theme: defaultTheme,
};

export default HeroCompetitionStyled;
