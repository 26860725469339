import React, { Component } from 'react';

import { sidebarWeeksData } from './competition';

import LeaderboardStyled from './Leaderboard.style';
import LeaderboardLeaders from './LeaderboardLeaders';

import Heading from '../Heading/Heading';
import ModalController from '../ModalController/ModalController';
import ModalImage from '../ModalImage/ModalImage';

class Leaderboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showWeek: 1,
    };
  }

  render() {
    const { showWeek } = this.state;

    const activeSidebar = sidebarWeeksData.find(w => w.id === showWeek);
    const sidebar = (
      <div className="Leaderboard__sidebar">
        <div className="Leaderboard__subtitle" style={{ margin: 0 }}>
          WEEK {activeSidebar.id} | {activeSidebar.title}
        </div>

        <p style={{ textTransform: 'uppercase' }}>
          <strong>STATUS</strong>:{' '}
          {activeSidebar.restrictedPlay === false ? (
            <span
              className="Leaderboard__badge"
              style={{ backgroundColor: '#1bd002' }}
            >
              UNLOCKED
            </span>
          ) : (
            <span
              className="Leaderboard__badge"
              style={{ backgroundColor: '#d0021b' }}
            >
              LOCKED
            </span>
          )}
          <br />
          <strong>PERIOD</strong>:{'  '}
          {activeSidebar.period.start} - {activeSidebar.period.end}
        </p>
        <div className="Leaderboard__weekIcon">
          <img src={activeSidebar.icon} style={{ width: '80px' }} alt="" />
          {activeSidebar.desc}
        </div>
        <div className="Leaderboard__subtitle">
          <span>SCORING</span> | {activeSidebar.mode}
        </div>

        <div className="Leaderboard__scoring">
          {activeSidebar.scoring.map((itm, idx) => (
            <div
              key={idx.toString()}
              className="Leaderboard__scoringItem"
              style={{ animationDelay: `${idx * 30}ms` }}
            >
              <strong>
                {itm.points === 1 ? `+${itm.points} PT` : `+${itm.points} PTS`}
              </strong>
              <span>{itm.label}</span>
            </div>
          ))}
        </div>

        <div className="Leaderboard__subtitle">PRIZING</div>

        <ul className="Leaderboard__wins">
          {activeSidebar.prizing.map((itm, idx) => (
            <li
              key={idx.toString()}
              className="Leaderboard__winsItem"
              style={{ animationDelay: `${idx * 40}ms` }}
            >
              {itm.label}
            </li>
          ))}
        </ul>

        <ModalController>
          {(isOpen, handleToggle, index) => (
            <div className="Leaderboard__prizing">
              {activeSidebar.gallery.map((itm, idx) => (
                <div
                  key={itm.src}
                  className="Leaderboard__prizingItem"
                  style={{ animationDelay: `${idx * 50}ms` }}
                >
                  <button type="button" onClick={() => handleToggle(idx)}>
                    <img src={itm.src} alt="" />
                  </button>
                </div>
              ))}

              {isOpen && (
                <ModalImage
                  onClose={handleToggle}
                  gallery={activeSidebar.gallery}
                  index={index}
                />
              )}
            </div>
          )}
        </ModalController>
      </div>
    );

    return (
      <LeaderboardStyled>
        <div className="Leaderboard__inner">
          <Heading>WEEKLY LEADERBOARD</Heading>

          <div className="Leaderboard__weekButtons">
            {Array.from({ length: 7 }, (weekButton, i) => (
              <button
                key={`weekButtons${i + 1}`}
                className={
                  showWeek === i + 1
                    ? `Leaderboard__weekButton active`
                    : `Leaderboard__weekButton`
                }
                type="button"
                onClick={() => this.setState({ showWeek: i + 1 })}
              >
                WEEK {i + 1}
              </button>
            ))}
          </div>

          <div className="Leaderboard__wrapper">
            <LeaderboardLeaders weekNumber={showWeek} />
            {sidebar}
          </div>
        </div>
      </LeaderboardStyled>
    );
  }
}

export default Leaderboard;
