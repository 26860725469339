const week6 = [
  {
    displayName: 'Borchardt',
    totalScore: 408751,
    membershipType: 4,
    classType: 1,
    rank: 1,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Modern Tryhard',
    totalScore: 376128,
    membershipType: 4,
    classType: 2,
    rank: 2,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'CCubic',
    totalScore: 353576,
    membershipType: 4,
    classType: 0,
    rank: 3,
    profilePicturePath: '/img/profile/avatars/cc000015.jpg',
  },
  {
    displayName: 'CG Brendino',
    totalScore: 344811,
    membershipType: 1,
    classType: 1,
    rank: 4,
    profilePicturePath: '/img/profile/avatars/e2015_14.jpg',
  },
  {
    displayName: 'ZachJ',
    totalScore: 325687,
    membershipType: 2,
    classType: 2,
    rank: 5,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'katlyn_kat',
    totalScore: 314147,
    membershipType: 2,
    classType: 2,
    rank: 6,
    profilePicturePath: '/img/profile/avatars/cc06.jpg',
  },
  {
    displayName: 'Jupiter2800',
    totalScore: 263108,
    membershipType: 2,
    classType: 0,
    rank: 7,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'Ronatello',
    totalScore: 261744,
    membershipType: 2,
    classType: 1,
    rank: 8,
    profilePicturePath: '/img/profile/avatars/bungieday_06.jpg',
  },
  {
    displayName: 'crazycoop40',
    totalScore: 253992,
    membershipType: 2,
    classType: 0,
    rank: 9,
    profilePicturePath: '/img/profile/avatars/bungieday_12.jpg',
  },
  {
    displayName: 'SoCalBear',
    totalScore: 232653,
    membershipType: 2,
    classType: 2,
    rank: 10,
    profilePicturePath: '/img/profile/avatars/s_AVATAR.jpg',
  },
  {
    displayName: 'Petrifyze',
    totalScore: 208213,
    membershipType: 4,
    classType: 0,
    rank: 11,
    profilePicturePath: '/img/profile/avatars/e2015_15.jpg',
  },
  {
    displayName: 'Angelbabyluv',
    totalScore: 173377,
    membershipType: 2,
    classType: 2,
    rank: 12,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'Chess',
    totalScore: 162055,
    membershipType: 1,
    classType: 1,
    rank: 13,
    profilePicturePath: '/img/profile/avatars/shield2.jpg',
  },
  {
    displayName: 'OperationBOAR',
    totalScore: 160354,
    membershipType: 1,
    classType: 1,
    rank: 14,
    profilePicturePath: '/img/profile/avatars/seventhcolumn7.gif',
  },
  {
    displayName: 'jasser54',
    totalScore: 150656,
    membershipType: 2,
    classType: 0,
    rank: 15,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'GrayZXA',
    totalScore: 104214,
    membershipType: 2,
    classType: 1,
    rank: 16,
    profilePicturePath: '/img/profile/avatars/avatar18.jpg',
  },
  {
    displayName: 'lycanthrope-96',
    totalScore: 91774,
    membershipType: 2,
    classType: 1,
    rank: 17,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
  },
  {
    displayName: 'HighHuckleberry',
    totalScore: 90319,
    membershipType: 1,
    classType: 1,
    rank: 18,
    profilePicturePath: '/img/profile/avatars/cc00002.jpg',
  },
  {
    displayName: 'KN0WLYF3',
    totalScore: 88657,
    membershipType: 4,
    classType: 1,
    rank: 19,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'austinjamaal',
    totalScore: 88491,
    membershipType: 4,
    classType: 1,
    rank: 20,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
  },
  {
    displayName: 'CrushReaver',
    totalScore: 88064,
    membershipType: 2,
    classType: 1,
    rank: 21,
    profilePicturePath: '/img/profile/avatars/cc12.jpg',
  },
  {
    displayName: 'NTowers',
    totalScore: 82989,
    membershipType: 2,
    classType: 1,
    rank: 22,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'Avril95',
    totalScore: 78472,
    membershipType: 2,
    classType: 0,
    rank: 23,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
  },
  {
    displayName: 'Rawdog_710',
    totalScore: 77619,
    membershipType: 2,
    classType: 1,
    rank: 24,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'brentd25',
    totalScore: 75355,
    membershipType: 2,
    classType: 1,
    rank: 25,
    profilePicturePath: '/img/profile/avatars/s_Vitruvian_Chief.jpg',
  },
  {
    displayName: 'ZadrianKV',
    totalScore: 73994,
    membershipType: 1,
    classType: 1,
    rank: 26,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'ARIES2301',
    totalScore: 73665,
    membershipType: 2,
    classType: 2,
    rank: 27,
    profilePicturePath: '/img/profile/avatars/avatar12.jpg',
  },
  {
    displayName: 'bizzy817',
    totalScore: 73246,
    membershipType: 2,
    classType: 1,
    rank: 28,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'SirDiesAlot',
    totalScore: 72519,
    membershipType: 4,
    classType: 0,
    rank: 29,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Blazespot',
    totalScore: 70109,
    membershipType: 4,
    classType: 0,
    rank: 30,
    profilePicturePath: '/img/profile/avatars/cc00003.jpg',
  },
  {
    displayName: 'SunSamuraii',
    totalScore: 68864,
    membershipType: 2,
    classType: 1,
    rank: 31,
    profilePicturePath: '/img/profile/avatars/trigunT7R_avatar.gif',
  },
  {
    displayName: 'PDIGGY22',
    totalScore: 64072,
    membershipType: 2,
    classType: 2,
    rank: 32,
    profilePicturePath: '/img/profile/avatars/cc000015.jpg',
  },
  {
    displayName: 'NekoBlackGem',
    totalScore: 63359,
    membershipType: 2,
    classType: 2,
    rank: 33,
    profilePicturePath: '/img/profile/avatars/th_BN2Avs15.jpg',
  },
  {
    displayName: 'troublegirl126',
    totalScore: 62438,
    membershipType: 2,
    classType: 1,
    rank: 34,
    profilePicturePath: '/img/profile/avatars/15.jpg',
  },
  {
    displayName: 'Gnarwhai',
    totalScore: 62431,
    membershipType: 4,
    classType: 2,
    rank: 35,
    profilePicturePath: '/img/profile/avatars/cc000010.jpg',
  },
  {
    displayName: 'PotatoeGuru',
    totalScore: 61699,
    membershipType: 2,
    classType: 2,
    rank: 36,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_19.jpg',
  },
  {
    displayName: 'Kruger',
    totalScore: 61402,
    membershipType: 1,
    classType: 0,
    rank: 37,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'Regulator5678',
    totalScore: 60701,
    membershipType: 1,
    classType: 1,
    rank: 38,
    profilePicturePath: '/img/profile/avatars/cc32.jpg',
  },
  {
    displayName: 'Arblex',
    totalScore: 59352,
    membershipType: 4,
    classType: 2,
    rank: 39,
    profilePicturePath: '/img/profile/avatars/cc45.jpg',
  },
  {
    displayName: 'TheCerusKnight',
    totalScore: 58110,
    membershipType: 4,
    classType: 2,
    rank: 40,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
  },
  {
    displayName: 'APEX TAIGER',
    totalScore: 57035,
    membershipType: 2,
    classType: 1,
    rank: 41,
    profilePicturePath: '/img/profile/avatars/bungieday_19.jpg',
  },
  {
    displayName: 'Alcatraz6',
    totalScore: 55406,
    membershipType: 1,
    classType: 1,
    rank: 42,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Ouup',
    totalScore: 55324,
    membershipType: 1,
    classType: 0,
    rank: 43,
    profilePicturePath: '/img/profile/avatars/bungieday_14.jpg',
  },
  {
    displayName: 'Lar3nik',
    totalScore: 55251,
    membershipType: 2,
    classType: 0,
    rank: 44,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'PlzDontEatMyFace',
    totalScore: 54996,
    membershipType: 2,
    classType: 2,
    rank: 45,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
  },
  {
    displayName: 'Threehorn A72',
    totalScore: 54583,
    membershipType: 1,
    classType: 1,
    rank: 46,
    profilePicturePath: '/img/profile/avatars/chiefdy7.gif',
  },
  {
    displayName: 'Kento',
    totalScore: 53100,
    membershipType: 2,
    classType: 2,
    rank: 47,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'HunnyBadgeDGAF',
    totalScore: 52331,
    membershipType: 1,
    classType: 0,
    rank: 48,
    profilePicturePath: '/img/profile/avatars/s_AwesomeAvatar.jpg',
  },
  {
    displayName: 'MrMacDaddySr',
    totalScore: 51910,
    membershipType: 2,
    classType: 1,
    rank: 49,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'ViiRuS',
    totalScore: 51850,
    membershipType: 4,
    classType: 1,
    rank: 50,
    profilePicturePath: '/img/profile/avatars/awoz_avatar.gif',
  },
  {
    displayName: 'VinDaddy1208',
    totalScore: 50114,
    membershipType: 1,
    classType: 2,
    rank: 51,
    profilePicturePath: '/img/profile/avatars/jolly_roger_gears.jpg',
  },
  {
    displayName: 'blueraven2234',
    totalScore: 50040,
    membershipType: 2,
    classType: 1,
    rank: 52,
    profilePicturePath: '/img/profile/avatars/trigunT7R_avatar.gif',
  },
  {
    displayName: 'RYDER_5169',
    totalScore: 49992,
    membershipType: 2,
    classType: 2,
    rank: 53,
    profilePicturePath: '/img/profile/avatars/cc000015.jpg',
  },
  {
    displayName: 'adiojoe627',
    totalScore: 48467,
    membershipType: 4,
    classType: 2,
    rank: 54,
    profilePicturePath: '/img/profile/avatars/cc00005.jpg',
  },
  {
    displayName: 'Qbano86',
    totalScore: 48297,
    membershipType: 2,
    classType: 0,
    rank: 55,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'Shaw3028',
    totalScore: 47738,
    membershipType: 2,
    classType: 1,
    rank: 56,
    profilePicturePath: '/img/profile/avatars/Destiny26.jpg',
  },
  {
    displayName: 'inFAMOUS Ninja',
    totalScore: 47682,
    membershipType: 2,
    classType: 1,
    rank: 57,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'Ultimichael',
    totalScore: 47551,
    membershipType: 2,
    classType: 0,
    rank: 58,
    profilePicturePath: '/img/profile/avatars/bungieday_08.jpg',
  },
  {
    displayName: 'KaiserKing01',
    totalScore: 47413,
    membershipType: 1,
    classType: 1,
    rank: 59,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'TheKillingLight',
    totalScore: 46877,
    membershipType: 2,
    classType: 1,
    rank: 60,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_39.jpg',
  },
  {
    displayName: 'Sneadd',
    totalScore: 46500,
    membershipType: 4,
    classType: 2,
    rank: 61,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'desi81597',
    totalScore: 45639,
    membershipType: 2,
    classType: 1,
    rank: 62,
    profilePicturePath: '/img/profile/avatars/Destiny21.jpg',
  },
  {
    displayName: 'EagleScope',
    totalScore: 45297,
    membershipType: 2,
    classType: 2,
    rank: 63,
    profilePicturePath: '/img/profile/avatars/sevenz.gif',
  },
  {
    displayName: 'classi641',
    totalScore: 45236,
    membershipType: 2,
    classType: 2,
    rank: 64,
    profilePicturePath: '/img/profile/avatars/jolly_roger.jpg',
  },
  {
    displayName: 'OGGodLee',
    totalScore: 45210,
    membershipType: 2,
    classType: 1,
    rank: 65,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'majinbuu79',
    totalScore: 44845,
    membershipType: 1,
    classType: 1,
    rank: 66,
    profilePicturePath: '/img/profile/avatars/gravemindzz.gif',
  },
  {
    displayName: 'BlinginEagle',
    totalScore: 44839,
    membershipType: 2,
    classType: 1,
    rank: 67,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
  },
  {
    displayName: 'TRON197462',
    totalScore: 44138,
    membershipType: 2,
    classType: 1,
    rank: 68,
    profilePicturePath: '/img/profile/avatars/skull1w.gif',
  },
  {
    displayName: 'hellrazer21',
    totalScore: 43737,
    membershipType: 2,
    classType: 2,
    rank: 69,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Agent471909',
    totalScore: 43733,
    membershipType: 2,
    classType: 0,
    rank: 70,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'CaptureTheSea',
    totalScore: 43733,
    membershipType: 1,
    classType: 1,
    rank: 71,
    profilePicturePath: '/img/profile/avatars/Seven.gif',
  },
  {
    displayName: 'Destronite',
    totalScore: 43431,
    membershipType: 1,
    classType: 2,
    rank: 72,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'Terrarising',
    totalScore: 43298,
    membershipType: 2,
    classType: 2,
    rank: 73,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'IRIIGSIBY3000',
    totalScore: 43179,
    membershipType: 1,
    classType: 1,
    rank: 74,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'iamseanthedon',
    totalScore: 42461,
    membershipType: 1,
    classType: 1,
    rank: 75,
    profilePicturePath: '/img/profile/avatars/bungiedayav1.jpg',
  },
  {
    displayName: 'Mr JayBasic',
    totalScore: 42329,
    membershipType: 1,
    classType: 1,
    rank: 76,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'Fulcrum',
    totalScore: 41562,
    membershipType: 4,
    classType: 2,
    rank: 77,
    profilePicturePath: '/img/profile/avatars/cc34.jpg',
  },
  {
    displayName: 'imthe1legbstrd',
    totalScore: 41437,
    membershipType: 1,
    classType: 1,
    rank: 78,
    profilePicturePath: '/img/profile/avatars/cc14.jpg',
  },
  {
    displayName: 'Flapjack Bros',
    totalScore: 41281,
    membershipType: 1,
    classType: 2,
    rank: 79,
    profilePicturePath: '/img/profile/avatars/cc29.jpg',
  },
  {
    displayName: 'Stackzzz_18',
    totalScore: 41024,
    membershipType: 2,
    classType: 1,
    rank: 80,
    profilePicturePath: '/img/profile/avatars/Destiny21.jpg',
  },
  {
    displayName: 'K1NGJamezz',
    totalScore: 41016,
    membershipType: 4,
    classType: 0,
    rank: 81,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 's m o k e y Ill',
    totalScore: 40891,
    membershipType: 1,
    classType: 1,
    rank: 82,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
  },
  {
    displayName: 'LE_PATRON_QC',
    totalScore: 40885,
    membershipType: 4,
    classType: 1,
    rank: 83,
    profilePicturePath: '/img/profile/avatars/e2015_11.jpg',
  },
  {
    displayName: 'Jormungandar',
    totalScore: 40778,
    membershipType: 2,
    classType: 0,
    rank: 84,
    profilePicturePath: '/img/profile/avatars/cc54.jpg',
  },
  {
    displayName: 'TiniestFlippers',
    totalScore: 40638,
    membershipType: 1,
    classType: 2,
    rank: 85,
    profilePicturePath: '/img/profile/avatars/bungieday_16.jpg',
  },
  {
    displayName: 'malignant',
    totalScore: 40528,
    membershipType: 1,
    classType: 2,
    rank: 86,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Gravablast',
    totalScore: 40184,
    membershipType: 4,
    classType: 2,
    rank: 87,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'D458347',
    totalScore: 40151,
    membershipType: 4,
    classType: 1,
    rank: 88,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Zaddak93',
    totalScore: 40129,
    membershipType: 2,
    classType: 0,
    rank: 89,
    profilePicturePath: '/img/profile/avatars/Destiny03.jpg',
  },
  {
    displayName: 'Corey80 ',
    totalScore: 39996,
    membershipType: 2,
    classType: 1,
    rank: 90,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
  },
  {
    displayName: 'GetnFriskyWitIt',
    totalScore: 39924,
    membershipType: 4,
    classType: 0,
    rank: 91,
    profilePicturePath: '/img/profile/avatars/bungieday_02.jpg',
  },
  {
    displayName: 'Infinite1026',
    totalScore: 39793,
    membershipType: 2,
    classType: 1,
    rank: 92,
    profilePicturePath: '/img/profile/avatars/Destiny07.jpg',
  },
  {
    displayName: 'Lamentofsin',
    totalScore: 39769,
    membershipType: 4,
    classType: 1,
    rank: 93,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=0&1=2&2=0&3=22&fi=37&bi=38&fl=1&m=3',
  },
  {
    displayName: 'Nindrone',
    totalScore: 39532,
    membershipType: 4,
    classType: 0,
    rank: 94,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'depressedewok81',
    totalScore: 39464,
    membershipType: 1,
    classType: 2,
    rank: 95,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'BomberWolf',
    totalScore: 39298,
    membershipType: 2,
    classType: 2,
    rank: 96,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
  },
  {
    displayName: 'FabbMaDD',
    totalScore: 39297,
    membershipType: 2,
    classType: 2,
    rank: 97,
    profilePicturePath: '/img/profile/avatars/bungiedayav4.jpg',
  },
  {
    displayName: 'BuTTeRfLi18',
    totalScore: 39213,
    membershipType: 4,
    classType: 2,
    rank: 98,
    profilePicturePath: '/img/profile/avatars/seventhcolumn7.gif',
  },
  {
    displayName: 'Bluehouse',
    totalScore: 39208,
    membershipType: 4,
    classType: 1,
    rank: 99,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Dray the cool',
    totalScore: 38991,
    membershipType: 1,
    classType: 1,
    rank: 100,
    profilePicturePath: '/img/profile/avatars/cc27.jpg',
  },
  {
    displayName: 'Profanendobscene',
    totalScore: 38945,
    membershipType: 2,
    classType: 1,
    rank: 101,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'Se7en14',
    totalScore: 38683,
    membershipType: 1,
    classType: 1,
    rank: 102,
    profilePicturePath: '/img/profile/avatars/Seven.gif',
  },
  {
    displayName: 'SGen1625',
    totalScore: 38622,
    membershipType: 2,
    classType: 2,
    rank: 103,
    profilePicturePath: '/img/profile/avatars/bungieday_23.jpg',
  },
  {
    displayName: 'ImpulseStrike00',
    totalScore: 38621,
    membershipType: 2,
    classType: 2,
    rank: 104,
    profilePicturePath: '/img/profile/avatars/05.jpg',
  },
  {
    displayName: 'night hunters90',
    totalScore: 38537,
    membershipType: 1,
    classType: 1,
    rank: 105,
    profilePicturePath: '/img/profile/avatars/Destiny04.jpg',
  },
  {
    displayName: 'Desroyer',
    totalScore: 38535,
    membershipType: 4,
    classType: 1,
    rank: 106,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Quasar',
    totalScore: 38496,
    membershipType: 4,
    classType: 1,
    rank: 107,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_45.jpg',
  },
  {
    displayName: 'BTMbodygaurd',
    totalScore: 38483,
    membershipType: 2,
    classType: 2,
    rank: 108,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
  },
  {
    displayName: 'Natas73666',
    totalScore: 38473,
    membershipType: 1,
    classType: 2,
    rank: 109,
    profilePicturePath: '/img/profile/avatars/victory-a.jpg',
  },
  {
    displayName: 'Drunknfighter35',
    totalScore: 38444,
    membershipType: 1,
    classType: 1,
    rank: 110,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'TheBeard702',
    totalScore: 38440,
    membershipType: 1,
    classType: 0,
    rank: 111,
    profilePicturePath: '/img/profile/avatars/cc00008.jpg',
  },
  {
    displayName: 'punsher',
    totalScore: 38368,
    membershipType: 2,
    classType: 0,
    rank: 112,
    profilePicturePath: '/img/profile/avatars/skull1w.gif',
  },
  {
    displayName: 'iWyll',
    totalScore: 38343,
    membershipType: 2,
    classType: 0,
    rank: 113,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'HeavenlyKanon',
    totalScore: 38311,
    membershipType: 4,
    classType: 2,
    rank: 114,
    profilePicturePath: '/img/profile/avatars/bungieday_16.jpg',
  },
  {
    displayName: 'MarshFellow',
    totalScore: 38236,
    membershipType: 4,
    classType: 1,
    rank: 115,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'Tiotirorich',
    totalScore: 38185,
    membershipType: 4,
    classType: 1,
    rank: 116,
    profilePicturePath: '/img/profile/avatars/avatar27.jpg',
  },
  {
    displayName: 'zoosdamoose',
    totalScore: 38161,
    membershipType: 1,
    classType: 1,
    rank: 117,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'Fluid_Scopes_',
    totalScore: 38076,
    membershipType: 2,
    classType: 0,
    rank: 118,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'creeperxd4',
    totalScore: 38066,
    membershipType: 1,
    classType: 2,
    rank: 119,
    profilePicturePath: '/img/profile/avatars/cc66.jpg',
  },
  {
    displayName: 'SpookuFy',
    totalScore: 38061,
    membershipType: 2,
    classType: 1,
    rank: 120,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'RiffRaff Giraffe',
    totalScore: 37966,
    membershipType: 2,
    classType: 0,
    rank: 121,
    profilePicturePath: '/img/profile/avatars/avatar2.jpg',
  },
  {
    displayName: 'CrazyRob',
    totalScore: 37902,
    membershipType: 4,
    classType: 2,
    rank: 122,
    profilePicturePath: '/img/profile/avatars/cc00005.jpg',
  },
  {
    displayName: 'Rinux',
    totalScore: 37893,
    membershipType: 4,
    classType: 1,
    rank: 123,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'RankRiver158429 ',
    totalScore: 37791,
    membershipType: 1,
    classType: 1,
    rank: 124,
    profilePicturePath: '/img/profile/avatars/themaestro.jpg',
  },
  {
    displayName: 'Ex0ticstarX',
    totalScore: 37747,
    membershipType: 4,
    classType: 0,
    rank: 125,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'I STAY REPORTED',
    totalScore: 37746,
    membershipType: 1,
    classType: 1,
    rank: 126,
    profilePicturePath: '/img/profile/avatars/s_image.jpg',
  },
  {
    displayName: 'MiesVanDeKalb',
    totalScore: 37654,
    membershipType: 2,
    classType: 1,
    rank: 127,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'ShahuaForTheWin',
    totalScore: 37615,
    membershipType: 1,
    classType: 0,
    rank: 128,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Onistry',
    totalScore: 37614,
    membershipType: 2,
    classType: 1,
    rank: 129,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Stout',
    totalScore: 37594,
    membershipType: 4,
    classType: 1,
    rank: 130,
    profilePicturePath: '/img/profile/avatars/Destiny26.jpg',
  },
  {
    displayName: 'Samc1987',
    totalScore: 37581,
    membershipType: 1,
    classType: 1,
    rank: 131,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'TD3BUF',
    totalScore: 37564,
    membershipType: 2,
    classType: 2,
    rank: 132,
    profilePicturePath: '/img/profile/avatars/bungieday_05.jpg',
  },
  {
    displayName: 'Gratuga',
    totalScore: 37561,
    membershipType: 2,
    classType: 0,
    rank: 133,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'NoremacG',
    totalScore: 37536,
    membershipType: 2,
    classType: 1,
    rank: 134,
    profilePicturePath: '/img/profile/avatars/bungieday_14.jpg',
  },
  {
    displayName: 'City',
    totalScore: 37495,
    membershipType: 4,
    classType: 0,
    rank: 135,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'LordDarkThorn77',
    totalScore: 37493,
    membershipType: 1,
    classType: 1,
    rank: 136,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'TMcKfly',
    totalScore: 37457,
    membershipType: 4,
    classType: 1,
    rank: 137,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'ChaosAngel2112',
    totalScore: 37442,
    membershipType: 1,
    classType: 1,
    rank: 138,
    profilePicturePath: '/img/profile/avatars/e2015_14.jpg',
  },
  {
    displayName: 'Unrulian',
    totalScore: 37438,
    membershipType: 2,
    classType: 1,
    rank: 139,
    profilePicturePath: '/img/profile/avatars/cc72.jpg',
  },
  {
    displayName: 'chisniper',
    totalScore: 37400,
    membershipType: 4,
    classType: 1,
    rank: 140,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'BravesSteve',
    totalScore: 37386,
    membershipType: 2,
    classType: 1,
    rank: 141,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'kyabetu25252',
    totalScore: 37359,
    membershipType: 2,
    classType: 1,
    rank: 142,
    profilePicturePath: '/img/profile/avatars/avatar25.jpg',
  },
  {
    displayName: 'ZrSL',
    totalScore: 37345,
    membershipType: 2,
    classType: 0,
    rank: 143,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_34.jpg',
  },
  {
    displayName: 'JJFang',
    totalScore: 37344,
    membershipType: 4,
    classType: 0,
    rank: 144,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Garfield_0457',
    totalScore: 37328,
    membershipType: 2,
    classType: 2,
    rank: 145,
    profilePicturePath: '/img/profile/avatars/cc000011.jpg',
  },
  {
    displayName: 'Motte',
    totalScore: 37320,
    membershipType: 4,
    classType: 0,
    rank: 146,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'NMxBanshee',
    totalScore: 37290,
    membershipType: 1,
    classType: 2,
    rank: 147,
    profilePicturePath: '/img/profile/avatars/Destiny13.jpg',
  },
  {
    displayName: 'Trickz22',
    totalScore: 37211,
    membershipType: 2,
    classType: 1,
    rank: 148,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'BrazilSniper99',
    totalScore: 37165,
    membershipType: 1,
    classType: 0,
    rank: 149,
    profilePicturePath: '/img/profile/avatars/cc14.jpg',
  },
  {
    displayName: 'BetterHawkeye',
    totalScore: 37123,
    membershipType: 1,
    classType: 2,
    rank: 150,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_40.jpg',
  },
  {
    displayName: 'amanda_snipes',
    totalScore: 37120,
    membershipType: 4,
    classType: 2,
    rank: 151,
    profilePicturePath: '/img/profile/avatars/cc000010.jpg',
  },
  {
    displayName: 'SIeepIy',
    totalScore: 37117,
    membershipType: 1,
    classType: 1,
    rank: 152,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'N8han',
    totalScore: 37106,
    membershipType: 4,
    classType: 1,
    rank: 153,
    profilePicturePath: '/img/profile/avatars/huntinghunter.jpg',
  },
  {
    displayName: 'woodn3',
    totalScore: 37093,
    membershipType: 2,
    classType: 1,
    rank: 154,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'nepo',
    totalScore: 37061,
    membershipType: 4,
    classType: 0,
    rank: 155,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Countryboy09200',
    totalScore: 37044,
    membershipType: 1,
    classType: 1,
    rank: 156,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'ItsxHawkeyex',
    totalScore: 36989,
    membershipType: 1,
    classType: 1,
    rank: 157,
    profilePicturePath: '/img/profile/avatars/cc00007.jpg',
  },
  {
    displayName: 'SubAtomicBlaze1',
    totalScore: 36985,
    membershipType: 2,
    classType: 0,
    rank: 158,
    profilePicturePath: '/img/profile/avatars/cc24.jpg',
  },
  {
    displayName: 'KillCosbee',
    totalScore: 36954,
    membershipType: 2,
    classType: 1,
    rank: 159,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'kingal29',
    totalScore: 36953,
    membershipType: 2,
    classType: 0,
    rank: 160,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'ALM1GHTY D0LLA',
    totalScore: 36901,
    membershipType: 1,
    classType: 1,
    rank: 161,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'choose282',
    totalScore: 36892,
    membershipType: 2,
    classType: 0,
    rank: 162,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'streetleagues101',
    totalScore: 36844,
    membershipType: 2,
    classType: 1,
    rank: 163,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'SteelRickochet',
    totalScore: 36794,
    membershipType: 1,
    classType: 1,
    rank: 164,
    profilePicturePath: '/img/profile/avatars/cc20.jpg',
  },
  {
    displayName: 'Jamaximus_81',
    totalScore: 36783,
    membershipType: 2,
    classType: 1,
    rank: 165,
    profilePicturePath: '/img/profile/avatars/e2015_03.jpg',
  },
  {
    displayName: 'boldbigfoot',
    totalScore: 36782,
    membershipType: 1,
    classType: 0,
    rank: 166,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'The25HourGamer',
    totalScore: 36770,
    membershipType: 4,
    classType: 2,
    rank: 167,
    profilePicturePath: '/img/profile/avatars/odst_buck.gif',
  },
  {
    displayName: 'NabeelSteel',
    totalScore: 36767,
    membershipType: 4,
    classType: 1,
    rank: 168,
    profilePicturePath: '/img/profile/avatars/avatar23.jpg',
  },
  {
    displayName: 'chrachni',
    totalScore: 36764,
    membershipType: 4,
    classType: 1,
    rank: 169,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'X-SmOoTh-SoUL-X',
    totalScore: 36715,
    membershipType: 2,
    classType: 0,
    rank: 170,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'swyman17',
    totalScore: 36708,
    membershipType: 2,
    classType: 1,
    rank: 171,
    profilePicturePath: '/img/profile/avatars/cc00007.jpg',
  },
  {
    displayName: 'Steal_d0om101',
    totalScore: 36590,
    membershipType: 2,
    classType: 1,
    rank: 172,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'greennumber9',
    totalScore: 36584,
    membershipType: 2,
    classType: 2,
    rank: 173,
    profilePicturePath: '/img/profile/avatars/cc000016.jpg',
  },
  {
    displayName: 'Mayhem439',
    totalScore: 36580,
    membershipType: 2,
    classType: 0,
    rank: 174,
    profilePicturePath: '/img/profile/avatars/logo-skull2.gif',
  },
  {
    displayName: 'Ego Sum Ira',
    totalScore: 36564,
    membershipType: 1,
    classType: 2,
    rank: 175,
    profilePicturePath: '/img/profile/avatars/cc00008.jpg',
  },
  {
    displayName: 'tw0sh0t2345',
    totalScore: 36562,
    membershipType: 1,
    classType: 2,
    rank: 176,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'on3sh0t2345',
    totalScore: 36545,
    membershipType: 1,
    classType: 2,
    rank: 177,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'LuDaBu',
    totalScore: 36518,
    membershipType: 4,
    classType: 2,
    rank: 178,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Lord Dark',
    totalScore: 36516,
    membershipType: 2,
    classType: 2,
    rank: 179,
    profilePicturePath: '/img/profile/avatars/avatar2.jpg',
  },
  {
    displayName: 'Quickdraw_4',
    totalScore: 36508,
    membershipType: 4,
    classType: 2,
    rank: 180,
    profilePicturePath: '/img/profile/avatars/cc21.jpg',
  },
  {
    displayName: 'bud everett',
    totalScore: 36493,
    membershipType: 2,
    classType: 1,
    rank: 181,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Xylo_Void',
    totalScore: 36466,
    membershipType: 2,
    classType: 1,
    rank: 182,
    profilePicturePath: '/img/profile/avatars/Destiny16.jpg',
  },
  {
    displayName: 'Eagl97',
    totalScore: 36453,
    membershipType: 2,
    classType: 2,
    rank: 183,
    profilePicturePath: '/img/profile/avatars/bungieday_23.jpg',
  },
  {
    displayName: 'BAU Fuzzy Wuzzy',
    totalScore: 36448,
    membershipType: 4,
    classType: 1,
    rank: 184,
    profilePicturePath: '/img/profile/avatars/CarnageZone.gif',
  },
  {
    displayName: 'Ninjahitman15',
    totalScore: 36443,
    membershipType: 2,
    classType: 2,
    rank: 185,
    profilePicturePath: '/img/profile/avatars/cc62.jpg',
  },
  {
    displayName: 'iGSlawTeR',
    totalScore: 36432,
    membershipType: 1,
    classType: 1,
    rank: 186,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'MrNeko42',
    totalScore: 36420,
    membershipType: 2,
    classType: 2,
    rank: 187,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=9&1=27&2=9&3=21&fi=2&bi=49&fl=1&m=3',
  },
  {
    displayName: 'SlickToxin',
    totalScore: 36408,
    membershipType: 4,
    classType: 0,
    rank: 188,
    profilePicturePath: '/img/profile/avatars/logo-skull2.gif',
  },
  {
    displayName: 'Astolso',
    totalScore: 36396,
    membershipType: 1,
    classType: 0,
    rank: 189,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Pheer Zero',
    totalScore: 36388,
    membershipType: 1,
    classType: 2,
    rank: 190,
    profilePicturePath: '/img/profile/avatars/bungieday_02.jpg',
  },
  {
    displayName: 'felixthecat-drew',
    totalScore: 36377,
    membershipType: 2,
    classType: 2,
    rank: 191,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Spidercides',
    totalScore: 36340,
    membershipType: 4,
    classType: 1,
    rank: 192,
    profilePicturePath: '/img/profile/avatars/cc000011.jpg',
  },
  {
    displayName: 'ViciousReaper0',
    totalScore: 36325,
    membershipType: 2,
    classType: 1,
    rank: 193,
    profilePicturePath: '/img/profile/avatars/cc46.jpg',
  },
  {
    displayName: 'Zenn',
    totalScore: 36315,
    membershipType: 4,
    classType: 1,
    rank: 194,
    profilePicturePath: '/img/profile/avatars/cc000016.jpg',
  },
  {
    displayName: 'Daemon-Hunter',
    totalScore: 36289,
    membershipType: 4,
    classType: 1,
    rank: 195,
    profilePicturePath: '/img/profile/avatars/cc67.jpg',
  },
  {
    displayName: 'iiNCLEMENT',
    totalScore: 36285,
    membershipType: 1,
    classType: 2,
    rank: 196,
    profilePicturePath: '/img/profile/avatars/cc33.jpg',
  },
  {
    displayName: 'Agum48',
    totalScore: 36274,
    membershipType: 4,
    classType: 0,
    rank: 197,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
  },
  {
    displayName: 'Drunkensai1or',
    totalScore: 36271,
    membershipType: 4,
    classType: 1,
    rank: 198,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
  },
  {
    displayName: 'squidward353',
    totalScore: 36238,
    membershipType: 2,
    classType: 1,
    rank: 199,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'GuidingLight',
    totalScore: 36234,
    membershipType: 4,
    classType: 1,
    rank: 200,
    profilePicturePath: '/img/profile/avatars/odst_skull_fire.png',
  },
];

export default week6;
