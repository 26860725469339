import React from 'react';

import HeroCompetitionStyled from './HeroCompetition.style';
import riotsLogo from '../../images/riots-logo.svg';

import Heading from '../Heading/Heading';

const HeroCompetition = () => {
  return (
    <HeroCompetitionStyled>
      <div className="HeroCompetition__video">
        <video autoPlay loop muted playsInline>
          <source
            src="https://s3.amazonaws.com/oblivion-data/public/destiny/d2_weapons.mp4"
            type="video/mp4"
          />
        </video>
      </div>

      <div className="HeroCompetition__inner">
        <div className="HeroCompetition__main">
          <div className="HeroCompetition__logo">
            <div className="HeroCompetition__logoImg">
              <img src={riotsLogo} alt="" />
            </div>
            <h2 className="HeroCompetition__title">
              ROCKSTAR RIOTS 8‑WEEK COMPETITION
            </h2>
          </div>

          <div>
            <div className="HeroCompetition__txt">
              Rockstar Energy powers the Destiny 2: Forsaken competition series.
              Play for weekly prizes, earn ranking by game mode, and a chance to
              win a fly-away experience to the live finals event at Bungie
              Studios in Washington, USA.
            </div>
          </div>
        </div>

        <div className="HeroCompetition__engrams">
          <Heading>SERIES SCHEDULE</Heading>
          <div className="HeroCompetition__challenge">
            <div>
              <h4>
                <span>Open Round</span>
              </h4>
              <p>
                <span>
                  Play in-game challenges to win prizes - open to the community.
                </span>
              </p>

              <ul>
                <li>
                  <span>Week 1</span> / CRUCIBLE / 10.6 - 10.13
                </li>
                <li>
                  <span>Week 2</span> / STRIKES / 10.14 - 10.20
                </li>
                <li>
                  <span>Week 3</span> / GAMBIT / 10.21 - 10.27
                </li>
              </ul>
            </div>

            <div>
              <h4>
                <span>Legendary Round</span>
              </h4>
              <p>
                <span>
                  Enter for premium prizes. Weekly top 200 earns a spot in
                  Qualifier Round - 1 can code to unlock and play each week.
                </span>
              </p>

              <ul>
                <li>
                  <span>Week 4</span> / CRUCIBLE / 10.28 - 11.3{' '}
                  <span className="HeroCompetition__badge">LOCKED</span>
                </li>
                <li>
                  <span>Week 5</span> / STRIKES / 11.4 - 11.10{' '}
                  <span className="HeroCompetition__badge">LOCKED</span>
                </li>
                <li>
                  <span>Week 6</span> / GAMBIT / 11.11 - 11.17{' '}
                  <span className="HeroCompetition__badge">LOCKED</span>
                </li>
              </ul>
            </div>

            <div>
              <h4>
                <span>Qualifier Round</span>
              </h4>
              <p>
                <span>
                  Top 600 from Legendary Round play to win 1 of 4 seats for a
                  fly‑away trip to compete at Bungie Studios.
                </span>
              </p>

              <ul>
                <li>
                  <span>Week 7</span> / CRUCIBLE / 11.25 - 12.1{' '}
                  <span className="HeroCompetition__badge">LOCKED</span>
                </li>
              </ul>
            </div>

            <div>
              <h4>
                <span>LIVE FINALS @ BUNGIE - 12.18</span>
              </h4>
              <p>
                <span>
                  Top 4 from Qualifer Round will be flown out to Bungie Studios
                  to play head to head with Bungie Team - Watch Live on Twitch.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </HeroCompetitionStyled>
  );
};

export default HeroCompetition;
