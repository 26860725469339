const week7 = [
  {
    displayName: 'Borchardt',
    totalScore: 155598,
    membershipType: 4,
    classType: 1,
    rank: 1,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'GuidingLight',
    totalScore: 153309,
    membershipType: 4,
    classType: 1,
    rank: 2,
    profilePicturePath: '/img/profile/avatars/odst_skull_fire.png',
  },
  {
    displayName: 'NeZu',
    totalScore: 147180,
    membershipType: 4,
    classType: 1,
    rank: 3,
    profilePicturePath: '/img/profile/avatars/7thCol.gif',
  },
  {
    displayName: 'ImpulseA27',
    totalScore: 147179,
    membershipType: 4,
    classType: 1,
    rank: 4,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_45.jpg',
  },
  {
    displayName: 'crazycoop40',
    totalScore: 139271,
    membershipType: 2,
    classType: 0,
    rank: 5,
    profilePicturePath: '/img/profile/avatars/bungieday_12.jpg',
  },
  {
    displayName: 'JOiNMYHiTLiSTx',
    totalScore: 125051,
    membershipType: 2,
    classType: 1,
    rank: 6,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'SIMSTER4LIFE',
    totalScore: 119415,
    membershipType: 2,
    classType: 1,
    rank: 7,
    profilePicturePath: '/img/profile/avatars/cc20.jpg',
  },
  {
    displayName: 'ViiRuS',
    totalScore: 88194,
    membershipType: 4,
    classType: 1,
    rank: 8,
    profilePicturePath: '/img/profile/avatars/awoz_avatar.gif',
  },
  {
    displayName: 'drpepper ninja1',
    totalScore: 87899,
    membershipType: 1,
    classType: 1,
    rank: 9,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_41.jpg',
  },
  {
    displayName: 'GUNSABLAZ1NG',
    totalScore: 84217,
    membershipType: 1,
    classType: 0,
    rank: 10,
    profilePicturePath: '/img/profile/avatars/bungieday_24.jpg',
  },
  {
    displayName: 'GrayZXA',
    totalScore: 75688,
    membershipType: 2,
    classType: 1,
    rank: 11,
    profilePicturePath: '/img/profile/avatars/avatar18.jpg',
  },
  {
    displayName: 'Rawdog_710',
    totalScore: 67063,
    membershipType: 2,
    classType: 1,
    rank: 12,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Micca1996',
    totalScore: 60694,
    membershipType: 2,
    classType: 2,
    rank: 13,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'PDIGGY22',
    totalScore: 51861,
    membershipType: 2,
    classType: 2,
    rank: 14,
    profilePicturePath: '/img/profile/avatars/cc000015.jpg',
  },
  {
    displayName: 'inFAMOUS Ninja',
    totalScore: 48016,
    membershipType: 2,
    classType: 1,
    rank: 15,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'Huffy',
    totalScore: 43157,
    membershipType: 4,
    classType: 1,
    rank: 16,
    profilePicturePath: '/img/profile/avatars/14.jpg',
  },
  {
    displayName: 'Rocketblaster369',
    totalScore: 42268,
    membershipType: 2,
    classType: 2,
    rank: 17,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'TeggySI',
    totalScore: 41395,
    membershipType: 1,
    classType: 0,
    rank: 18,
    profilePicturePath: '/img/profile/avatars/a_reach_4.jpg',
  },
  {
    displayName: 'DrewskyStomp',
    totalScore: 38690,
    membershipType: 4,
    classType: 1,
    rank: 19,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_41.jpg',
  },
  {
    displayName: 'iWyll',
    totalScore: 36812,
    membershipType: 2,
    classType: 0,
    rank: 20,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'NekoBlackGem',
    totalScore: 34462,
    membershipType: 2,
    classType: 2,
    rank: 21,
    profilePicturePath: '/img/profile/avatars/th_BN2Avs15.jpg',
  },
  {
    displayName: 'ZkMushroom',
    totalScore: 34325,
    membershipType: 2,
    classType: 1,
    rank: 22,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_05.jpg',
  },
  {
    displayName: 'PotatoeGuru',
    totalScore: 32473,
    membershipType: 2,
    classType: 2,
    rank: 23,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_19.jpg',
  },
  {
    displayName: 'Aries',
    totalScore: 31876,
    membershipType: 4,
    classType: 2,
    rank: 24,
    profilePicturePath: '/img/profile/avatars/cc00008.jpg',
  },
  {
    displayName: 'CG Brendino',
    totalScore: 31378,
    membershipType: 1,
    classType: 1,
    rank: 25,
    profilePicturePath: '/img/profile/avatars/e2015_14.jpg',
  },
  {
    displayName: 'GetnFriskyWitIt',
    totalScore: 29838,
    membershipType: 4,
    classType: 0,
    rank: 26,
    profilePicturePath: '/img/profile/avatars/bungieday_02.jpg',
  },
  {
    displayName: 'theycallmemopp',
    totalScore: 29708,
    membershipType: 2,
    classType: 1,
    rank: 27,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'TonyWanKnobi',
    totalScore: 28898,
    membershipType: 1,
    classType: 1,
    rank: 28,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_26.jpg',
  },
  {
    displayName: 'Ronatello',
    totalScore: 28716,
    membershipType: 2,
    classType: 1,
    rank: 29,
    profilePicturePath: '/img/profile/avatars/bungieday_06.jpg',
  },
  {
    displayName: 'TerrorizeThe805',
    totalScore: 27990,
    membershipType: 2,
    classType: 1,
    rank: 30,
    profilePicturePath: '/img/profile/avatars/bungieday_17.jpg',
  },
  {
    displayName: 'Ix ShootTheJ xI',
    totalScore: 27766,
    membershipType: 1,
    classType: 1,
    rank: 31,
    profilePicturePath: '/img/profile/avatars/odst_skull_silver.png',
  },
  {
    displayName: 'Xysty',
    totalScore: 27236,
    membershipType: 1,
    classType: 1,
    rank: 32,
    profilePicturePath: '/img/profile/avatars/Destiny06.jpg',
  },
  {
    displayName: 'Xamthos',
    totalScore: 27118,
    membershipType: 2,
    classType: 2,
    rank: 33,
    profilePicturePath: '/img/profile/avatars/e2015_15.jpg',
  },
  {
    displayName: 'Cokkymonster',
    totalScore: 26695,
    membershipType: 2,
    classType: 2,
    rank: 34,
    profilePicturePath: '/img/profile/avatars/cc68.jpg',
  },
  {
    displayName: 'Threehorn A72',
    totalScore: 26221,
    membershipType: 1,
    classType: 1,
    rank: 35,
    profilePicturePath: '/img/profile/avatars/chiefdy7.gif',
  },
  {
    displayName: 'KN0WLYF3',
    totalScore: 25826,
    membershipType: 4,
    classType: 1,
    rank: 36,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'lolrofl69',
    totalScore: 25771,
    membershipType: 2,
    classType: 0,
    rank: 37,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
  },
  {
    displayName: 'SIN7DIABLO',
    totalScore: 23752,
    membershipType: 1,
    classType: 0,
    rank: 38,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'iamseanthedon',
    totalScore: 23707,
    membershipType: 1,
    classType: 1,
    rank: 39,
    profilePicturePath: '/img/profile/avatars/bungiedayav1.jpg',
  },
  {
    displayName: 'Gratuga',
    totalScore: 22903,
    membershipType: 2,
    classType: 0,
    rank: 40,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Vixon',
    totalScore: 22867,
    membershipType: 2,
    classType: 2,
    rank: 41,
    profilePicturePath: '/img/profile/avatars/cc50.jpg',
  },
  {
    displayName: 'Ninja_Yoda24',
    totalScore: 21711,
    membershipType: 2,
    classType: 1,
    rank: 42,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'SCORPION-AJ-92',
    totalScore: 21245,
    membershipType: 2,
    classType: 0,
    rank: 43,
    profilePicturePath: '/img/profile/avatars/e2015_15.jpg',
  },
  {
    displayName: 'Steal_d0om101',
    totalScore: 20642,
    membershipType: 2,
    classType: 1,
    rank: 44,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'IAM2501',
    totalScore: 20425,
    membershipType: 2,
    classType: 2,
    rank: 45,
    profilePicturePath: '/img/profile/avatars/bungieday_14.jpg',
  },
  {
    displayName: 'm3d1cAt10n',
    totalScore: 20131,
    membershipType: 2,
    classType: 1,
    rank: 46,
    profilePicturePath: '/img/profile/avatars/untitled2vk3.gif',
  },
  {
    displayName: 'flashcat85',
    totalScore: 19999,
    membershipType: 1,
    classType: 2,
    rank: 47,
    profilePicturePath: '/img/profile/avatars/avatar29.jpg',
  },
  {
    displayName: 'trudeaulove',
    totalScore: 19761,
    membershipType: 2,
    classType: 2,
    rank: 48,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'MA5T3RCH13F82',
    totalScore: 19695,
    membershipType: 1,
    classType: 0,
    rank: 49,
    profilePicturePath: '/img/profile/avatars/bungieday_14.jpg',
  },
  {
    displayName: 'Sergeant Gouge',
    totalScore: 19687,
    membershipType: 1,
    classType: 1,
    rank: 50,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'desi81597',
    totalScore: 19146,
    membershipType: 2,
    classType: 1,
    rank: 51,
    profilePicturePath: '/img/profile/avatars/Destiny21.jpg',
  },
  {
    displayName: 'SirDiesAlot',
    totalScore: 18634,
    membershipType: 4,
    classType: 0,
    rank: 52,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'WhiteGknight',
    totalScore: 18391,
    membershipType: 2,
    classType: 2,
    rank: 53,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'shisha bauer123',
    totalScore: 18368,
    membershipType: 2,
    classType: 1,
    rank: 54,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_34.jpg',
  },
  {
    displayName: 'HappyJoey91559',
    totalScore: 18352,
    membershipType: 1,
    classType: 0,
    rank: 55,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'Murtag',
    totalScore: 18204,
    membershipType: 4,
    classType: 0,
    rank: 56,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
  },
  {
    displayName: 'Regulator5678',
    totalScore: 18087,
    membershipType: 1,
    classType: 1,
    rank: 57,
    profilePicturePath: '/img/profile/avatars/cc32.jpg',
  },
  {
    displayName: 'airsickflea3401',
    totalScore: 17730,
    membershipType: 1,
    classType: 2,
    rank: 58,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_41.jpg',
  },
  {
    displayName: 'BodaciousSteve',
    totalScore: 16376,
    membershipType: 2,
    classType: 0,
    rank: 59,
    profilePicturePath: '/img/profile/avatars/cc47.jpg',
  },
  {
    displayName: 'Draco',
    totalScore: 16286,
    membershipType: 2,
    classType: 1,
    rank: 60,
    profilePicturePath: '/img/profile/avatars/bungieday_05.jpg',
  },
  {
    displayName: 'SoCalBear',
    totalScore: 15480,
    membershipType: 2,
    classType: 2,
    rank: 61,
    profilePicturePath: '/img/profile/avatars/s_AVATAR.jpg',
  },
  {
    displayName: 'Rinux',
    totalScore: 15336,
    membershipType: 4,
    classType: 1,
    rank: 62,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'SethMaddex',
    totalScore: 15163,
    membershipType: 2,
    classType: 0,
    rank: 63,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_26.jpg',
  },
  {
    displayName: 'Repaired Leaks',
    totalScore: 15105,
    membershipType: 1,
    classType: 1,
    rank: 64,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_44.jpg',
  },
  {
    displayName: 'sirloganmoore1',
    totalScore: 14906,
    membershipType: 2,
    classType: 1,
    rank: 65,
    profilePicturePath: '/img/profile/avatars/avatar3.jpg',
  },
  {
    displayName: 'Donnerdog',
    totalScore: 14712,
    membershipType: 4,
    classType: 1,
    rank: 66,
    profilePicturePath: '/img/profile/avatars/cc73.jpg',
  },
  {
    displayName: 'BLUEvAsH101',
    totalScore: 14668,
    membershipType: 2,
    classType: 2,
    rank: 67,
    profilePicturePath: '/img/profile/avatars/e2015_09.jpg',
  },
  {
    displayName: 'TheScooby',
    totalScore: 14602,
    membershipType: 1,
    classType: 1,
    rank: 68,
    profilePicturePath: '/img/profile/avatars/cc72.jpg',
  },
  {
    displayName: 'LordDarkThorn77',
    totalScore: 14456,
    membershipType: 1,
    classType: 1,
    rank: 69,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Playerstephen45',
    totalScore: 14332,
    membershipType: 2,
    classType: 0,
    rank: 70,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Mrdmp305',
    totalScore: 14317,
    membershipType: 4,
    classType: 1,
    rank: 71,
    profilePicturePath: '/img/profile/avatars/cc00007.jpg',
  },
  {
    displayName: 'MiguelYDG',
    totalScore: 14292,
    membershipType: 2,
    classType: 1,
    rank: 72,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Umbra',
    totalScore: 14263,
    membershipType: 4,
    classType: 1,
    rank: 73,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
  },
  {
    displayName: 'joeman62000',
    totalScore: 14225,
    membershipType: 2,
    classType: 2,
    rank: 74,
    profilePicturePath: '/img/profile/avatars/e2015_15.jpg',
  },
  {
    displayName: 'D458347',
    totalScore: 14154,
    membershipType: 4,
    classType: 1,
    rank: 75,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'LazyPandaCat',
    totalScore: 13969,
    membershipType: 2,
    classType: 0,
    rank: 76,
    profilePicturePath: '/img/profile/avatars/Destiny16.jpg',
  },
  {
    displayName: 'saber_rayko',
    totalScore: 13774,
    membershipType: 2,
    classType: 1,
    rank: 77,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'A large Potato',
    totalScore: 13422,
    membershipType: 4,
    classType: 0,
    rank: 78,
    profilePicturePath: '/img/profile/avatars/flamingninja.gif',
  },
  {
    displayName: 'JLPERRY16',
    totalScore: 13367,
    membershipType: 2,
    classType: 0,
    rank: 79,
    profilePicturePath: '/img/profile/avatars/Destiny11.jpg',
  },
  {
    displayName: 'Louoh',
    totalScore: 13147,
    membershipType: 4,
    classType: 0,
    rank: 80,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Shoopiedoop',
    totalScore: 13080,
    membershipType: 2,
    classType: 2,
    rank: 81,
    profilePicturePath: '/img/profile/avatars/cc66.jpg',
  },
  {
    displayName: 'N8han',
    totalScore: 12529,
    membershipType: 4,
    classType: 1,
    rank: 82,
    profilePicturePath: '/img/profile/avatars/huntinghunter.jpg',
  },
  {
    displayName: 'Xylo_Void',
    totalScore: 12459,
    membershipType: 2,
    classType: 1,
    rank: 83,
    profilePicturePath: '/img/profile/avatars/Destiny16.jpg',
  },
  {
    displayName: 'Stout',
    totalScore: 12306,
    membershipType: 4,
    classType: 1,
    rank: 84,
    profilePicturePath: '/img/profile/avatars/Destiny26.jpg',
  },
  {
    displayName: 'Ekuegan',
    totalScore: 12303,
    membershipType: 4,
    classType: 1,
    rank: 85,
    profilePicturePath: '/img/profile/avatars/Destiny06.jpg',
  },
  {
    displayName: 'Profanendobscene',
    totalScore: 12251,
    membershipType: 2,
    classType: 1,
    rank: 86,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'TD3BUF',
    totalScore: 12193,
    membershipType: 2,
    classType: 2,
    rank: 87,
    profilePicturePath: '/img/profile/avatars/bungieday_05.jpg',
  },
  {
    displayName: 'SlickToxin',
    totalScore: 12100,
    membershipType: 4,
    classType: 0,
    rank: 88,
    profilePicturePath: '/img/profile/avatars/logo-skull2.gif',
  },
  {
    displayName: 'r351574nc3',
    totalScore: 11951,
    membershipType: 2,
    classType: 1,
    rank: 89,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_26.jpg',
  },
  {
    displayName: 'breakneck420',
    totalScore: 11948,
    membershipType: 2,
    classType: 0,
    rank: 90,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Kelxyel',
    totalScore: 11873,
    membershipType: 2,
    classType: 1,
    rank: 91,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Sunoe',
    totalScore: 11717,
    membershipType: 1,
    classType: 1,
    rank: 92,
    profilePicturePath: '/img/profile/avatars/cc23.jpg',
  },
  {
    displayName: 'OGGodLee',
    totalScore: 11526,
    membershipType: 2,
    classType: 1,
    rank: 93,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Angelbabyluv',
    totalScore: 11479,
    membershipType: 2,
    classType: 2,
    rank: 94,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'oxzzy',
    totalScore: 11467,
    membershipType: 2,
    classType: 0,
    rank: 95,
    profilePicturePath: '/img/profile/avatars/odst_skull_3_2.png',
  },
  {
    displayName: 'Reshetoff',
    totalScore: 11420,
    membershipType: 2,
    classType: 1,
    rank: 96,
    profilePicturePath: '/img/profile/avatars/bungiedayav1.jpg',
  },
  {
    displayName: 'malignant',
    totalScore: 11397,
    membershipType: 1,
    classType: 2,
    rank: 97,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Slashhammer2015',
    totalScore: 11255,
    membershipType: 2,
    classType: 2,
    rank: 98,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_09.jpg',
  },
  {
    displayName: 'Chess',
    totalScore: 10803,
    membershipType: 1,
    classType: 1,
    rank: 99,
    profilePicturePath: '/img/profile/avatars/shield2.jpg',
  },
  {
    displayName: 'owlz-HD',
    totalScore: 10710,
    membershipType: 2,
    classType: 1,
    rank: 100,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'CaptureTheSea',
    totalScore: 10544,
    membershipType: 1,
    classType: 1,
    rank: 101,
    profilePicturePath: '/img/profile/avatars/Seven.gif',
  },
  {
    displayName: 'Frenzy',
    totalScore: 10454,
    membershipType: 4,
    classType: 1,
    rank: 102,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'taylorrae0923',
    totalScore: 10330,
    membershipType: 2,
    classType: 0,
    rank: 103,
    profilePicturePath: '/img/profile/avatars/Johnson-clear--back.gif',
  },
  {
    displayName: 'KillCosbee',
    totalScore: 10295,
    membershipType: 2,
    classType: 1,
    rank: 104,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Jormungandar',
    totalScore: 10217,
    membershipType: 2,
    classType: 0,
    rank: 105,
    profilePicturePath: '/img/profile/avatars/cc54.jpg',
  },
  {
    displayName: 'vampirefreak86',
    totalScore: 10124,
    membershipType: 1,
    classType: 0,
    rank: 106,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'mchanke',
    totalScore: 10099,
    membershipType: 2,
    classType: 1,
    rank: 107,
    profilePicturePath: '/img/profile/avatars/avatar10.jpg',
  },
  {
    displayName: 'Epitoc',
    totalScore: 9956,
    membershipType: 4,
    classType: 0,
    rank: 108,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'classi641',
    totalScore: 9949,
    membershipType: 2,
    classType: 2,
    rank: 109,
    profilePicturePath: '/img/profile/avatars/jolly_roger.jpg',
  },
  {
    displayName: 'BomberWolf',
    totalScore: 9916,
    membershipType: 2,
    classType: 2,
    rank: 110,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
  },
  {
    displayName: 's m o k e y Ill',
    totalScore: 9794,
    membershipType: 1,
    classType: 1,
    rank: 111,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
  },
  {
    displayName: 'SwatxXxComatose',
    totalScore: 9784,
    membershipType: 1,
    classType: 1,
    rank: 112,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
  },
  {
    displayName: 'At0mic',
    totalScore: 9644,
    membershipType: 4,
    classType: 1,
    rank: 113,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'Gravablast',
    totalScore: 9307,
    membershipType: 4,
    classType: 2,
    rank: 114,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Countryboy09200',
    totalScore: 9129,
    membershipType: 1,
    classType: 1,
    rank: 115,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'xXHendriKX',
    totalScore: 9077,
    membershipType: 2,
    classType: 2,
    rank: 116,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
  },
  {
    displayName: 'KillerDonTwan',
    totalScore: 9022,
    membershipType: 1,
    classType: 2,
    rank: 117,
    profilePicturePath: '/img/profile/avatars/logo-skull2.gif',
  },
  {
    displayName: 'aron141',
    totalScore: 9018,
    membershipType: 2,
    classType: 1,
    rank: 118,
    profilePicturePath: '/img/profile/avatars/bungieday_13.jpg',
  },
  {
    displayName: 'NickC_6069',
    totalScore: 8846,
    membershipType: 2,
    classType: 2,
    rank: 119,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'MarshFellow',
    totalScore: 8751,
    membershipType: 4,
    classType: 1,
    rank: 120,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'GhostKingGorilla',
    totalScore: 8633,
    membershipType: 2,
    classType: 2,
    rank: 121,
    profilePicturePath: '/img/profile/avatars/Destiny04.jpg',
  },
  {
    displayName: 'BlinginEagle',
    totalScore: 8528,
    membershipType: 2,
    classType: 1,
    rank: 122,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
  },
  {
    displayName: 'APEX TAIGER',
    totalScore: 8441,
    membershipType: 2,
    classType: 1,
    rank: 123,
    profilePicturePath: '/img/profile/avatars/bungieday_19.jpg',
  },
  {
    displayName: 'Oefvet2006',
    totalScore: 8363,
    membershipType: 4,
    classType: 1,
    rank: 124,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
  },
  {
    displayName: 'RiffRaff Giraffe',
    totalScore: 8332,
    membershipType: 2,
    classType: 0,
    rank: 125,
    profilePicturePath: '/img/profile/avatars/avatar2.jpg',
  },
  {
    displayName: 'iGSlawTeR',
    totalScore: 8290,
    membershipType: 1,
    classType: 1,
    rank: 126,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Mr 3 ManFred 3',
    totalScore: 8245,
    membershipType: 1,
    classType: 1,
    rank: 127,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'bizzy817',
    totalScore: 8242,
    membershipType: 2,
    classType: 1,
    rank: 128,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'KingSeryoga21',
    totalScore: 8185,
    membershipType: 2,
    classType: 1,
    rank: 129,
    profilePicturePath: '/img/profile/avatars/cc66.jpg',
  },
  {
    displayName: 'HunnyBadgeDGAF',
    totalScore: 8178,
    membershipType: 1,
    classType: 0,
    rank: 130,
    profilePicturePath: '/img/profile/avatars/s_AwesomeAvatar.jpg',
  },
  {
    displayName: 'Hakunamataco',
    totalScore: 8132,
    membershipType: 2,
    classType: 2,
    rank: 131,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Toastji2400',
    totalScore: 8122,
    membershipType: 4,
    classType: 2,
    rank: 132,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
  },
  {
    displayName: 'FGX',
    totalScore: 8098,
    membershipType: 4,
    classType: 0,
    rank: 133,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=0&1=2&2=2&3=2&fi=16&bi=40&fl=0&m=1',
  },
  {
    displayName: 'Shed I am',
    totalScore: 7951,
    membershipType: 1,
    classType: 2,
    rank: 134,
    profilePicturePath: '/img/profile/avatars/cc54.jpg',
  },
  {
    displayName: 'l TheIronLord l',
    totalScore: 7950,
    membershipType: 1,
    classType: 1,
    rank: 135,
    profilePicturePath: '/img/profile/avatars/Destiny04.jpg',
  },
  {
    displayName: 'spentwolf',
    totalScore: 7852,
    membershipType: 2,
    classType: 0,
    rank: 136,
    profilePicturePath: '/img/profile/avatars/bungiedayav2.jpg',
  },
  {
    displayName: 'Jonny',
    totalScore: 7812,
    membershipType: 4,
    classType: 0,
    rank: 137,
    profilePicturePath: '/img/profile/avatars/Destiny21.jpg',
  },
  {
    displayName: 'XIl Buddha lIX',
    totalScore: 7733,
    membershipType: 1,
    classType: 1,
    rank: 138,
    profilePicturePath: '/img/profile/avatars/bungieday_08.jpg',
  },
  {
    displayName: 'Ninjahitman15',
    totalScore: 7635,
    membershipType: 2,
    classType: 2,
    rank: 139,
    profilePicturePath: '/img/profile/avatars/cc62.jpg',
  },
  {
    displayName: 'Tomi2801',
    totalScore: 7607,
    membershipType: 2,
    classType: 2,
    rank: 140,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'squishbetta',
    totalScore: 7542,
    membershipType: 2,
    classType: 2,
    rank: 141,
    profilePicturePath: '/img/profile/avatars/bungiedayav1.jpg',
  },
  {
    displayName: 'IRON BOURN',
    totalScore: 7475,
    membershipType: 1,
    classType: 0,
    rank: 142,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_25.jpg',
  },
  {
    displayName: 'Brekuza',
    totalScore: 7364,
    membershipType: 1,
    classType: 1,
    rank: 143,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'splinter2078',
    totalScore: 7254,
    membershipType: 2,
    classType: 1,
    rank: 144,
    profilePicturePath: '/img/profile/avatars/cc20.jpg',
  },
  {
    displayName: 'Hayzess',
    totalScore: 7254,
    membershipType: 1,
    classType: 1,
    rank: 145,
    profilePicturePath: '/img/profile/avatars/cc20.jpg',
  },
  {
    displayName: 'Kisaragibot',
    totalScore: 7131,
    membershipType: 1,
    classType: 1,
    rank: 146,
    profilePicturePath: '/img/profile/avatars/cc000010.jpg',
  },
  {
    displayName: 'Willou_7',
    totalScore: 7058,
    membershipType: 2,
    classType: 1,
    rank: 147,
    profilePicturePath: '/img/profile/avatars/the_rip_saw_avtar.gif.gif',
  },
  {
    displayName: 'Ultimichael',
    totalScore: 7055,
    membershipType: 2,
    classType: 0,
    rank: 148,
    profilePicturePath: '/img/profile/avatars/bungieday_08.jpg',
  },
  {
    displayName: 'Mehgis',
    totalScore: 7031,
    membershipType: 1,
    classType: 1,
    rank: 149,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'stealthpig03',
    totalScore: 6808,
    membershipType: 2,
    classType: 1,
    rank: 150,
    profilePicturePath: '/img/profile/avatars/bungiedayav4.jpg',
  },
  {
    displayName: 'gkl220',
    totalScore: 6739,
    membershipType: 1,
    classType: 0,
    rank: 151,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'K1NGJamezz',
    totalScore: 6710,
    membershipType: 4,
    classType: 0,
    rank: 152,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Grug',
    totalScore: 6694,
    membershipType: 1,
    classType: 1,
    rank: 153,
    profilePicturePath: '/img/profile/avatars/Destiny26.jpg',
  },
  {
    displayName: 'Qbano86',
    totalScore: 6662,
    membershipType: 2,
    classType: 0,
    rank: 154,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'Icetransam2000',
    totalScore: 6648,
    membershipType: 2,
    classType: 0,
    rank: 155,
    profilePicturePath: '/img/profile/avatars/th_Bn58.jpg',
  },
  {
    displayName: 'Sneadd',
    totalScore: 6643,
    membershipType: 4,
    classType: 2,
    rank: 156,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'HighHuckleberry',
    totalScore: 6560,
    membershipType: 1,
    classType: 1,
    rank: 157,
    profilePicturePath: '/img/profile/avatars/cc00002.jpg',
  },
  {
    displayName: 'g4rybus3y713',
    totalScore: 6525,
    membershipType: 2,
    classType: 0,
    rank: 158,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'baronandi',
    totalScore: 6455,
    membershipType: 2,
    classType: 0,
    rank: 159,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Rubenator208',
    totalScore: 6418,
    membershipType: 2,
    classType: 2,
    rank: 160,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'Llamas at IHOP',
    totalScore: 6400,
    membershipType: 1,
    classType: 2,
    rank: 161,
    profilePicturePath: '/img/profile/avatars/cc21.jpg',
  },
  {
    displayName: 'Superslide25',
    totalScore: 6260,
    membershipType: 2,
    classType: 1,
    rank: 162,
    profilePicturePath: '/img/profile/avatars/cc72.jpg',
  },
  {
    displayName: 'slicknic96',
    totalScore: 6255,
    membershipType: 1,
    classType: 2,
    rank: 163,
    profilePicturePath: '/img/profile/avatars/bungieday_06.jpg',
  },
  {
    displayName: 'ViciousReaper0',
    totalScore: 6159,
    membershipType: 2,
    classType: 1,
    rank: 164,
    profilePicturePath: '/img/profile/avatars/cc46.jpg',
  },
  {
    displayName: 'BiingoKiller',
    totalScore: 6139,
    membershipType: 4,
    classType: 0,
    rank: 165,
    profilePicturePath: '/img/profile/avatars/bungieday_04.jpg',
  },
  {
    displayName: 'Master Arikan ',
    totalScore: 6125,
    membershipType: 4,
    classType: 2,
    rank: 166,
    profilePicturePath: '/img/profile/avatars/odst_dutch.gif',
  },
  {
    displayName: 'ThePurplest',
    totalScore: 6075,
    membershipType: 1,
    classType: 1,
    rank: 167,
    profilePicturePath: '/img/profile/avatars/bungieday_02.jpg',
  },
  {
    displayName: 'Evaqs',
    totalScore: 6034,
    membershipType: 4,
    classType: 2,
    rank: 168,
    profilePicturePath: '/img/profile/avatars/sevenz.gif',
  },
  {
    displayName: 'SUP3RRAY',
    totalScore: 6004,
    membershipType: 1,
    classType: 0,
    rank: 169,
    profilePicturePath: '/img/profile/avatars/e2015_11.jpg',
  },
  {
    displayName: 'amanda_snipes',
    totalScore: 5905,
    membershipType: 4,
    classType: 2,
    rank: 170,
    profilePicturePath: '/img/profile/avatars/cc000010.jpg',
  },
  {
    displayName: 'xxDroppinSacksxx',
    totalScore: 5890,
    membershipType: 2,
    classType: 1,
    rank: 171,
    profilePicturePath: '/img/profile/avatars/cc54.jpg',
  },
  {
    displayName: 'chisniper',
    totalScore: 5796,
    membershipType: 4,
    classType: 1,
    rank: 172,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'LordEdition',
    totalScore: 5791,
    membershipType: 2,
    classType: 1,
    rank: 173,
    profilePicturePath: '/img/profile/avatars/avatar4.jpg',
  },
  {
    displayName: 'willah g',
    totalScore: 5785,
    membershipType: 1,
    classType: 2,
    rank: 174,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
  },
  {
    displayName: 'Bluepurs',
    totalScore: 5766,
    membershipType: 1,
    classType: 1,
    rank: 175,
    profilePicturePath: '/img/profile/avatars/skull1w.gif',
  },
  {
    displayName: 'Ki77y666',
    totalScore: 5758,
    membershipType: 1,
    classType: 2,
    rank: 176,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'RedFlyingTurtles',
    totalScore: 5667,
    membershipType: 2,
    classType: 1,
    rank: 177,
    profilePicturePath: '/img/profile/avatars/avatar27.jpg',
  },
  {
    displayName: 'twitch-Doxxnoxx',
    totalScore: 5618,
    membershipType: 2,
    classType: 2,
    rank: 178,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'luis937',
    totalScore: 5594,
    membershipType: 2,
    classType: 1,
    rank: 179,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'DDT09',
    totalScore: 5361,
    membershipType: 2,
    classType: 0,
    rank: 180,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'PandasPajamas',
    totalScore: 5230,
    membershipType: 2,
    classType: 1,
    rank: 181,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
  },
  {
    displayName: 'Blossuhm',
    totalScore: 5216,
    membershipType: 1,
    classType: 1,
    rank: 182,
    profilePicturePath: '/img/profile/avatars/a_reach_9.jpg',
  },
  {
    displayName: 'majinbuu79',
    totalScore: 5178,
    membershipType: 1,
    classType: 1,
    rank: 183,
    profilePicturePath: '/img/profile/avatars/gravemindzz.gif',
  },
  {
    displayName: 'EagleScope',
    totalScore: 5160,
    membershipType: 2,
    classType: 2,
    rank: 184,
    profilePicturePath: '/img/profile/avatars/sevenz.gif',
  },
  {
    displayName: 'ducatic',
    totalScore: 5095,
    membershipType: 4,
    classType: 0,
    rank: 185,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Stackzzz_18',
    totalScore: 5060,
    membershipType: 2,
    classType: 1,
    rank: 186,
    profilePicturePath: '/img/profile/avatars/Destiny21.jpg',
  },
  {
    displayName: 'austinjamaal',
    totalScore: 4947,
    membershipType: 4,
    classType: 1,
    rank: 187,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
  },
  {
    displayName: 'dl oreo lb',
    totalScore: 4909,
    membershipType: 1,
    classType: 1,
    rank: 188,
    profilePicturePath: '/img/profile/avatars/avatar27.jpg',
  },
  {
    displayName: 'wiikidsly',
    totalScore: 4905,
    membershipType: 2,
    classType: 1,
    rank: 189,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_44.jpg',
  },
  {
    displayName: 'TiniestFlippers',
    totalScore: 4812,
    membershipType: 1,
    classType: 2,
    rank: 190,
    profilePicturePath: '/img/profile/avatars/bungieday_16.jpg',
  },
  {
    displayName: 'MrMacDaddySr',
    totalScore: 4795,
    membershipType: 2,
    classType: 1,
    rank: 191,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Sylicant',
    totalScore: 4709,
    membershipType: 1,
    classType: 1,
    rank: 192,
    profilePicturePath: '/img/profile/avatars/cc73.jpg',
  },
  {
    displayName: 'Zoderak',
    totalScore: 4656,
    membershipType: 2,
    classType: 0,
    rank: 193,
    profilePicturePath: '/img/profile/avatars/e2015_11.jpg',
  },
  {
    displayName: 'Daemon-Hunter',
    totalScore: 4621,
    membershipType: 4,
    classType: 1,
    rank: 194,
    profilePicturePath: '/img/profile/avatars/cc67.jpg',
  },
  {
    displayName: 'Tehrminatr',
    totalScore: 4567,
    membershipType: 4,
    classType: 0,
    rank: 195,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'Tezuk92',
    totalScore: 4504,
    membershipType: 2,
    classType: 2,
    rank: 196,
    profilePicturePath: '/img/profile/avatars/bungieday_16.jpg',
  },
  {
    displayName: 'BillyMcfloy',
    totalScore: 4454,
    membershipType: 4,
    classType: 0,
    rank: 197,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Ex0ticstarX',
    totalScore: 4419,
    membershipType: 4,
    classType: 0,
    rank: 198,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'IRIIGSIBY3000',
    totalScore: 4409,
    membershipType: 1,
    classType: 1,
    rank: 199,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'MorPhonic',
    totalScore: 4344,
    membershipType: 4,
    classType: 0,
    rank: 200,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_45.jpg',
  },
];

export default week7;
