const week2 = [
  {
    displayName: 'holforty',
    totalScore: 429257,
    membershipType: 2,
    classType: 1,
    rank: 1,
    profilePicturePath: '/img/profile/avatars/cc46.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'L01z0k3r',
    totalScore: 380409,
    membershipType: 4,
    classType: 0,
    rank: 2,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_29.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'jayman-one',
    totalScore: 377666,
    membershipType: 2,
    classType: 1,
    rank: 3,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Vinilla',
    totalScore: 374542,
    membershipType: 4,
    classType: 0,
    rank: 4,
    profilePicturePath: '/img/profile/avatars/cc08.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Collateral_',
    totalScore: 372970,
    membershipType: 4,
    classType: 0,
    rank: 5,
    profilePicturePath: '/img/profile/avatars/cc07.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'FlawlessCombo',
    totalScore: 362369,
    membershipType: 2,
    classType: 1,
    rank: 6,
    profilePicturePath: '/img/profile/avatars/cc63.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'GUNSABLAZ1NG',
    totalScore: 359980,
    membershipType: 1,
    classType: 0,
    rank: 7,
    profilePicturePath: '/img/profile/avatars/bungieday_24.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'M0NKEYMAJIK',
    totalScore: 237059,
    membershipType: 1,
    classType: 0,
    rank: 8,
    profilePicturePath: '/img/profile/avatars/bungieday_13.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Blazespot',
    totalScore: 182840,
    membershipType: 4,
    classType: 0,
    rank: 9,
    profilePicturePath: '/img/profile/avatars/cc00003.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'RoyalKiller26',
    totalScore: 173525,
    membershipType: 1,
    classType: 1,
    rank: 10,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'TitanSlayer1000',
    totalScore: 171277,
    membershipType: 1,
    classType: 1,
    rank: 11,
    profilePicturePath: '/img/profile/avatars/septagonsuperanimated6.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'TheHero',
    totalScore: 136823,
    membershipType: 4,
    classType: 1,
    rank: 12,
    profilePicturePath: '/img/profile/avatars/avatar4.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'HALO3KILLER187',
    totalScore: 133971,
    membershipType: 1,
    classType: 0,
    rank: 13,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=3&1=0&2=10&3=3&fi=52&bi=15&fl=1&m=1',
    style: { scale: 0 },
  },
  {
    displayName: 'crazycoop40',
    totalScore: 131496,
    membershipType: 2,
    classType: 0,
    rank: 14,
    profilePicturePath: '/img/profile/avatars/bungieday_12.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Typism',
    totalScore: 126118,
    membershipType: 1,
    classType: 0,
    rank: 15,
    profilePicturePath: '/img/profile/avatars/s_NobleTeam.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Nindrone',
    totalScore: 121156,
    membershipType: 4,
    classType: 0,
    rank: 16,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'FartingUnicorn',
    totalScore: 101387,
    membershipType: 4,
    classType: 1,
    rank: 17,
    profilePicturePath: '/img/profile/avatars/Destiny26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'AMALGMN',
    totalScore: 93491,
    membershipType: 4,
    classType: 0,
    rank: 18,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'I M a g g z I',
    totalScore: 88808,
    membershipType: 4,
    classType: 1,
    rank: 19,
    profilePicturePath: '/img/profile/avatars/bungieday_22.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'DamonAlbarn',
    totalScore: 85091,
    membershipType: 4,
    classType: 2,
    rank: 20,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'seiteki',
    totalScore: 83099,
    membershipType: 4,
    classType: 1,
    rank: 21,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'HerstonFerard',
    totalScore: 78232,
    membershipType: 2,
    classType: 2,
    rank: 22,
    profilePicturePath: '/img/profile/avatars/bungieday_10.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'joncrrnz',
    totalScore: 76539,
    membershipType: 2,
    classType: 2,
    rank: 23,
    profilePicturePath: '/img/profile/avatars/NABIUL_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Cyanide_Toxiic',
    totalScore: 75939,
    membershipType: 2,
    classType: 1,
    rank: 24,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'mchanke',
    totalScore: 75566,
    membershipType: 2,
    classType: 1,
    rank: 25,
    profilePicturePath: '/img/profile/avatars/avatar10.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Je_Fa_Music',
    totalScore: 75487,
    membershipType: 2,
    classType: 1,
    rank: 26,
    profilePicturePath: '/img/profile/avatars/warbow_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'OperationBOAR',
    totalScore: 73266,
    membershipType: 1,
    classType: 1,
    rank: 27,
    profilePicturePath: '/img/profile/avatars/seventhcolumn7.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'h2owned',
    totalScore: 70538,
    membershipType: 4,
    classType: 1,
    rank: 28,
    profilePicturePath: '/img/profile/avatars/e2015_14.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'RECOIL31337',
    totalScore: 69675,
    membershipType: 2,
    classType: 1,
    rank: 29,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'jak10x',
    totalScore: 67262,
    membershipType: 2,
    classType: 1,
    rank: 30,
    profilePicturePath: '/img/profile/avatars/cc00008.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Tbon3Strik3r',
    totalScore: 60771,
    membershipType: 1,
    classType: 2,
    rank: 31,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'MaGiKMaN0420',
    totalScore: 60277,
    membershipType: 2,
    classType: 1,
    rank: 32,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'D51bama',
    totalScore: 56761,
    membershipType: 2,
    classType: 0,
    rank: 33,
    profilePicturePath: '/img/profile/avatars/attention2.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'CrypticRICKz-c13',
    totalScore: 52149,
    membershipType: 2,
    classType: 1,
    rank: 34,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'OGGodLee',
    totalScore: 51228,
    membershipType: 2,
    classType: 1,
    rank: 35,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Sama',
    totalScore: 51204,
    membershipType: 4,
    classType: 1,
    rank: 36,
    profilePicturePath: '/img/profile/avatars/cc52.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'XionLighty',
    totalScore: 51152,
    membershipType: 2,
    classType: 1,
    rank: 37,
    profilePicturePath: '/img/profile/avatars/cc28.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'BeNastyy420',
    totalScore: 50682,
    membershipType: 2,
    classType: 2,
    rank: 38,
    profilePicturePath: '/img/profile/avatars/Destiny02.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Heman',
    totalScore: 50151,
    membershipType: 4,
    classType: 1,
    rank: 39,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Sadamie',
    totalScore: 49524,
    membershipType: 2,
    classType: 1,
    rank: 40,
    profilePicturePath: '/img/profile/avatars/themaestro.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Avril95',
    totalScore: 48343,
    membershipType: 2,
    classType: 0,
    rank: 41,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'jester41758',
    totalScore: 48316,
    membershipType: 2,
    classType: 0,
    rank: 42,
    profilePicturePath: '/img/profile/avatars/cc60.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'PotatoeGuru',
    totalScore: 46451,
    membershipType: 2,
    classType: 2,
    rank: 43,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_19.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'jvs1288',
    totalScore: 46394,
    membershipType: 2,
    classType: 2,
    rank: 44,
    profilePicturePath: '/img/profile/avatars/Monitor01_T.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'sirloganmoore1',
    totalScore: 46250,
    membershipType: 2,
    classType: 1,
    rank: 45,
    profilePicturePath: '/img/profile/avatars/avatar3.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'SunSamuraii',
    totalScore: 46067,
    membershipType: 2,
    classType: 1,
    rank: 46,
    profilePicturePath: '/img/profile/avatars/trigunT7R_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Derpindibble',
    totalScore: 44398,
    membershipType: 4,
    classType: 2,
    rank: 47,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'AzleSnow',
    totalScore: 44184,
    membershipType: 4,
    classType: 1,
    rank: 48,
    profilePicturePath: '/img/profile/avatars/Destiny06.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'shangaVIBES',
    totalScore: 42131,
    membershipType: 4,
    classType: 2,
    rank: 49,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ChewyDDR35',
    totalScore: 41300,
    membershipType: 2,
    classType: 0,
    rank: 50,
    profilePicturePath: '/img/profile/avatars/bungieday_22.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Miasma',
    totalScore: 41224,
    membershipType: 4,
    classType: 0,
    rank: 51,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_29.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'LA VERNO',
    totalScore: 40813,
    membershipType: 1,
    classType: 0,
    rank: 52,
    profilePicturePath: '/img/profile/avatars/cc22.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Daysofalion',
    totalScore: 40246,
    membershipType: 2,
    classType: 1,
    rank: 53,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Chikn',
    totalScore: 39811,
    membershipType: 4,
    classType: 1,
    rank: 54,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'tkmoney54',
    totalScore: 39710,
    membershipType: 2,
    classType: 0,
    rank: 55,
    profilePicturePath: '/img/profile/avatars/Destiny25.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'dragonblade2011',
    totalScore: 39534,
    membershipType: 1,
    classType: 2,
    rank: 56,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_10.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'BomberWolf',
    totalScore: 39448,
    membershipType: 2,
    classType: 2,
    rank: 57,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'JohnnyBoy8263',
    totalScore: 38829,
    membershipType: 1,
    classType: 1,
    rank: 58,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ladyvirus69',
    totalScore: 38538,
    membershipType: 1,
    classType: 2,
    rank: 59,
    profilePicturePath: '/img/profile/avatars/cc000012.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'flashcat85',
    totalScore: 38332,
    membershipType: 1,
    classType: 2,
    rank: 60,
    profilePicturePath: '/img/profile/avatars/avatar29.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'xx2GunzzSr',
    totalScore: 37998,
    membershipType: 2,
    classType: 2,
    rank: 61,
    profilePicturePath: '/img/profile/avatars/avatar9.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'downrightmike',
    totalScore: 37610,
    membershipType: 2,
    classType: 0,
    rank: 62,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Fishman',
    totalScore: 36960,
    membershipType: 4,
    classType: 2,
    rank: 63,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'miahtmm16',
    totalScore: 36824,
    membershipType: 2,
    classType: 1,
    rank: 64,
    profilePicturePath: '/img/profile/avatars/cc42.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'TerrorizeThe805',
    totalScore: 36051,
    membershipType: 2,
    classType: 1,
    rank: 65,
    profilePicturePath: '/img/profile/avatars/bungieday_17.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'wiikidsly',
    totalScore: 36002,
    membershipType: 2,
    classType: 1,
    rank: 66,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_44.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Sonicgen2012',
    totalScore: 35738,
    membershipType: 1,
    classType: 1,
    rank: 67,
    profilePicturePath: '/img/profile/avatars/avatar1.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ramon',
    totalScore: 35283,
    membershipType: 1,
    classType: 1,
    rank: 68,
    profilePicturePath: '/img/profile/avatars/h3_icon.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'crimsonkill',
    totalScore: 35269,
    membershipType: 4,
    classType: 1,
    rank: 69,
    profilePicturePath: '/img/profile/avatars/cc00006.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'therealNooB1721',
    totalScore: 34927,
    membershipType: 2,
    classType: 1,
    rank: 70,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Bluepurs',
    totalScore: 34825,
    membershipType: 1,
    classType: 1,
    rank: 71,
    profilePicturePath: '/img/profile/avatars/skull1w.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'pitita-82',
    totalScore: 34641,
    membershipType: 2,
    classType: 1,
    rank: 72,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'kcbeans76',
    totalScore: 34613,
    membershipType: 2,
    classType: 0,
    rank: 73,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Samc1987',
    totalScore: 34156,
    membershipType: 1,
    classType: 1,
    rank: 74,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Ronatello',
    totalScore: 34054,
    membershipType: 2,
    classType: 1,
    rank: 75,
    profilePicturePath: '/img/profile/avatars/bungieday_06.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Midnight Gaming',
    totalScore: 33989,
    membershipType: 2,
    classType: 1,
    rank: 76,
    profilePicturePath: '/img/profile/avatars/cc67.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'bizzy817',
    totalScore: 33557,
    membershipType: 2,
    classType: 1,
    rank: 77,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'LHR MASTRCHIEF',
    totalScore: 32035,
    membershipType: 4,
    classType: 1,
    rank: 78,
    profilePicturePath: '/img/profile/avatars/Johnson-clear--back.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'DisarmingDream',
    totalScore: 31459,
    membershipType: 2,
    classType: 0,
    rank: 79,
    profilePicturePath: '/img/profile/avatars/bungieday_05.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'crinho',
    totalScore: 31184,
    membershipType: 2,
    classType: 0,
    rank: 80,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Daredevil236',
    totalScore: 31076,
    membershipType: 2,
    classType: 1,
    rank: 81,
    profilePicturePath: '/img/profile/avatars/skull1w.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Virgil',
    totalScore: 30991,
    membershipType: 2,
    classType: 0,
    rank: 82,
    profilePicturePath: '/img/profile/avatars/bungieday_10.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Wednesday',
    totalScore: 30864,
    membershipType: 4,
    classType: 1,
    rank: 83,
    profilePicturePath: '/img/profile/avatars/bungieday_07.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'command123',
    totalScore: 30718,
    membershipType: 2,
    classType: 1,
    rank: 84,
    profilePicturePath: '/img/profile/avatars/cc62.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Shotgun Daddy',
    totalScore: 30672,
    membershipType: 2,
    classType: 0,
    rank: 85,
    profilePicturePath: '/img/profile/avatars/avatar2.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'xHapagirl_1979x',
    totalScore: 30531,
    membershipType: 2,
    classType: 1,
    rank: 86,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Savage680',
    totalScore: 30466,
    membershipType: 2,
    classType: 0,
    rank: 87,
    profilePicturePath: '/img/profile/avatars/th_Bn67.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'BeanRunning',
    totalScore: 30181,
    membershipType: 1,
    classType: 1,
    rank: 88,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'NekoBlackGem',
    totalScore: 29635,
    membershipType: 2,
    classType: 2,
    rank: 89,
    profilePicturePath: '/img/profile/avatars/th_BN2Avs15.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ExoticxKnine921',
    totalScore: 29580,
    membershipType: 1,
    classType: 1,
    rank: 90,
    profilePicturePath: '/img/profile/avatars/cc47.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Volsstrecker',
    totalScore: 28879,
    membershipType: 2,
    classType: 0,
    rank: 91,
    profilePicturePath: '/img/profile/avatars/apez.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Mailor',
    totalScore: 28795,
    membershipType: 4,
    classType: 1,
    rank: 92,
    profilePicturePath: '/img/profile/avatars/cc23.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'WhiteGknight',
    totalScore: 28564,
    membershipType: 2,
    classType: 2,
    rank: 93,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'foreversketchy',
    totalScore: 28425,
    membershipType: 2,
    classType: 0,
    rank: 94,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'SH_RKB0i',
    totalScore: 28373,
    membershipType: 2,
    classType: 1,
    rank: 95,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_19.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Scooty_Mc_Scoot',
    totalScore: 28227,
    membershipType: 2,
    classType: 0,
    rank: 96,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Flaminghydro',
    totalScore: 27687,
    membershipType: 2,
    classType: 2,
    rank: 97,
    profilePicturePath: '/img/profile/avatars/th_Bn58.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ZiecoZebra',
    totalScore: 27523,
    membershipType: 2,
    classType: 0,
    rank: 98,
    profilePicturePath: '/img/profile/avatars/th_cortanaHGN_001.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'avXRiku',
    totalScore: 27418,
    membershipType: 1,
    classType: 2,
    rank: 99,
    profilePicturePath: '/img/profile/avatars/avatar29.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'streetleagues101',
    totalScore: 27307,
    membershipType: 2,
    classType: 1,
    rank: 100,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Dudeman000',
    totalScore: 27233,
    membershipType: 2,
    classType: 0,
    rank: 101,
    profilePicturePath: '/img/profile/avatars/cc44.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'sasukex17',
    totalScore: 26975,
    membershipType: 1,
    classType: 1,
    rank: 102,
    profilePicturePath: '/img/profile/avatars/bungieday_24.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ItsSkepiii',
    totalScore: 26963,
    membershipType: 2,
    classType: 1,
    rank: 103,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'VERBUGA',
    totalScore: 26935,
    membershipType: 1,
    classType: 1,
    rank: 104,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_43.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'FRISKYWHAAAA',
    totalScore: 26929,
    membershipType: 2,
    classType: 1,
    rank: 105,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'KillerCruz49',
    totalScore: 26476,
    membershipType: 2,
    classType: 1,
    rank: 106,
    profilePicturePath: '/img/profile/avatars/marathon5.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'MeYouandMaryJane',
    totalScore: 26444,
    membershipType: 2,
    classType: 1,
    rank: 107,
    profilePicturePath: '/img/profile/avatars/bungieday_14.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Grim',
    totalScore: 26354,
    membershipType: 1,
    classType: 1,
    rank: 108,
    profilePicturePath: '/img/profile/avatars/avatar2.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'TOOLhead26',
    totalScore: 26255,
    membershipType: 2,
    classType: 0,
    rank: 109,
    profilePicturePath: '/img/profile/avatars/avatar8.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Apache',
    totalScore: 26143,
    membershipType: 2,
    classType: 1,
    rank: 110,
    profilePicturePath: '/img/profile/avatars/cc64.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'FirEnHeLL',
    totalScore: 26096,
    membershipType: 2,
    classType: 2,
    rank: 111,
    profilePicturePath: '/img/profile/avatars/odst_skull_fire.png',
    style: { scale: 0 },
  },
  {
    displayName: 'ElectoHD',
    totalScore: 26008,
    membershipType: 1,
    classType: 1,
    rank: 112,
    profilePicturePath: '/img/profile/avatars/cc45.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Awesomedudez4590',
    totalScore: 25995,
    membershipType: 2,
    classType: 0,
    rank: 113,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'almeda1804',
    totalScore: 25513,
    membershipType: 2,
    classType: 0,
    rank: 114,
    profilePicturePath: '/img/profile/avatars/awoz_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Bobby JoeMen',
    totalScore: 25421,
    membershipType: 4,
    classType: 1,
    rank: 115,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Southernrose160',
    totalScore: 25353,
    membershipType: 2,
    classType: 1,
    rank: 116,
    profilePicturePath: '/img/profile/avatars/bungieday_04.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'M_Facus',
    totalScore: 25349,
    membershipType: 2,
    classType: 1,
    rank: 117,
    profilePicturePath: '/img/profile/avatars/bungieday_13.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'justica653',
    totalScore: 24361,
    membershipType: 2,
    classType: 2,
    rank: 118,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'DaLifeForce',
    totalScore: 24163,
    membershipType: 2,
    classType: 1,
    rank: 119,
    profilePicturePath: '/img/profile/avatars/cc47.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'vampirefreak86',
    totalScore: 24128,
    membershipType: 1,
    classType: 0,
    rank: 120,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Boodhram12',
    totalScore: 24069,
    membershipType: 2,
    classType: 2,
    rank: 121,
    profilePicturePath: '/img/profile/avatars/s_Vitruvian_Chief.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'LivinDeadGurl87',
    totalScore: 23795,
    membershipType: 2,
    classType: 0,
    rank: 122,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Cuucu-',
    totalScore: 23391,
    membershipType: 2,
    classType: 2,
    rank: 123,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Rexik13',
    totalScore: 23295,
    membershipType: 2,
    classType: 1,
    rank: 124,
    profilePicturePath: '/img/profile/avatars/hayabusaw.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'NekoJosei',
    totalScore: 23240,
    membershipType: 2,
    classType: 2,
    rank: 125,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Alchemist90069',
    totalScore: 22826,
    membershipType: 2,
    classType: 0,
    rank: 126,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'WellDiagram69',
    totalScore: 22594,
    membershipType: 1,
    classType: 2,
    rank: 127,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_29.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Akira Kurusu',
    totalScore: 22520,
    membershipType: 4,
    classType: 2,
    rank: 128,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Enho',
    totalScore: 22513,
    membershipType: 4,
    classType: 0,
    rank: 129,
    profilePicturePath: '/img/profile/avatars/cc00009.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'SethMaddex',
    totalScore: 22342,
    membershipType: 2,
    classType: 0,
    rank: 130,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'drachenkrieger92',
    totalScore: 22239,
    membershipType: 2,
    classType: 0,
    rank: 131,
    profilePicturePath: '/img/profile/avatars/Destiny25.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'TheXPlanet',
    totalScore: 22191,
    membershipType: 2,
    classType: 1,
    rank: 132,
    profilePicturePath: '/img/profile/avatars/cc30.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'DustytheWarlock',
    totalScore: 22185,
    membershipType: 2,
    classType: 2,
    rank: 133,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ThePandaDg',
    totalScore: 22145,
    membershipType: 1,
    classType: 1,
    rank: 134,
    profilePicturePath: '/img/profile/avatars/14.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Brownsky85',
    totalScore: 22093,
    membershipType: 2,
    classType: 1,
    rank: 135,
    profilePicturePath: '/img/profile/avatars/cc21.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Dragosnuv 806',
    totalScore: 22006,
    membershipType: 1,
    classType: 2,
    rank: 136,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'JamDan1000',
    totalScore: 21946,
    membershipType: 2,
    classType: 2,
    rank: 137,
    profilePicturePath: '/img/profile/avatars/e2015_09.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'devilatmidnite',
    totalScore: 21894,
    membershipType: 1,
    classType: 0,
    rank: 138,
    profilePicturePath: '/img/profile/avatars/avatar1.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'A Traffic Cone',
    totalScore: 21735,
    membershipType: 2,
    classType: 1,
    rank: 139,
    profilePicturePath: '/img/profile/avatars/oTACTITIONo_picture.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'xkillerbarbiex',
    totalScore: 21584,
    membershipType: 2,
    classType: 1,
    rank: 140,
    profilePicturePath: '/img/profile/avatars/s_locklove.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Crimsoncrisis17',
    totalScore: 21559,
    membershipType: 2,
    classType: 1,
    rank: 141,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'flicKsh0t',
    totalScore: 21446,
    membershipType: 4,
    classType: 0,
    rank: 142,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Lost_Cylision',
    totalScore: 21421,
    membershipType: 2,
    classType: 0,
    rank: 143,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Emazingluke93',
    totalScore: 21412,
    membershipType: 2,
    classType: 2,
    rank: 144,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'SRSG_REAPER',
    totalScore: 21279,
    membershipType: 2,
    classType: 1,
    rank: 145,
    profilePicturePath: '/img/profile/avatars/cc33.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Corey80 ',
    totalScore: 21201,
    membershipType: 2,
    classType: 1,
    rank: 146,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Contracts__',
    totalScore: 21118,
    membershipType: 2,
    classType: 0,
    rank: 147,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'ZeroNaylor',
    totalScore: 21109,
    membershipType: 1,
    classType: 1,
    rank: 148,
    profilePicturePath: '/img/profile/avatars/c0ldvengeance_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'VinWop',
    totalScore: 21026,
    membershipType: 1,
    classType: 0,
    rank: 149,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'kamakazy karas9',
    totalScore: 20790,
    membershipType: 1,
    classType: 2,
    rank: 150,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_42.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'JOiNMYHiTLiSTx',
    totalScore: 20783,
    membershipType: 2,
    classType: 1,
    rank: 151,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Lilith2611',
    totalScore: 20610,
    membershipType: 2,
    classType: 0,
    rank: 152,
    profilePicturePath: '/img/profile/avatars/cc42.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'EvilHERONeos',
    totalScore: 20586,
    membershipType: 2,
    classType: 1,
    rank: 153,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_21.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'x_Wrath_Rey_x',
    totalScore: 20541,
    membershipType: 2,
    classType: 1,
    rank: 154,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'AceOneZero',
    totalScore: 20482,
    membershipType: 2,
    classType: 1,
    rank: 155,
    profilePicturePath: '/img/profile/avatars/bungieday_24.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'IronXVI',
    totalScore: 20430,
    membershipType: 2,
    classType: 1,
    rank: 156,
    profilePicturePath: '/img/profile/avatars/odst_skull_8_3.png',
    style: { scale: 0 },
  },
  {
    displayName: 'Slickpack',
    totalScore: 20292,
    membershipType: 2,
    classType: 1,
    rank: 157,
    profilePicturePath: '/img/profile/avatars/bungieday_04.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Blackopium93',
    totalScore: 20216,
    membershipType: 1,
    classType: 2,
    rank: 158,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Sneak_420',
    totalScore: 20144,
    membershipType: 2,
    classType: 1,
    rank: 159,
    profilePicturePath: '/img/profile/avatars/e2015_14.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'DesignedSkul',
    totalScore: 20104,
    membershipType: 2,
    classType: 1,
    rank: 160,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'INFamousSwiftz',
    totalScore: 20087,
    membershipType: 2,
    classType: 1,
    rank: 161,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'LastODeMeheecans',
    totalScore: 20042,
    membershipType: 2,
    classType: 0,
    rank: 162,
    profilePicturePath: '/img/profile/avatars/cc000013.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Diggie',
    totalScore: 19931,
    membershipType: 2,
    classType: 2,
    rank: 163,
    profilePicturePath: '/img/profile/avatars/bungieday_10.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Tezuk92',
    totalScore: 19844,
    membershipType: 2,
    classType: 2,
    rank: 164,
    profilePicturePath: '/img/profile/avatars/bungieday_16.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Im Pker',
    totalScore: 19757,
    membershipType: 4,
    classType: 1,
    rank: 165,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_27.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Svelter',
    totalScore: 19729,
    membershipType: 4,
    classType: 1,
    rank: 166,
    profilePicturePath: '/img/profile/avatars/cc000016.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'DirtySwine',
    totalScore: 19592,
    membershipType: 4,
    classType: 1,
    rank: 167,
    profilePicturePath: '/img/profile/avatars/cc29.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'RedFlyingTurtles',
    totalScore: 19502,
    membershipType: 2,
    classType: 1,
    rank: 168,
    profilePicturePath: '/img/profile/avatars/avatar27.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Castle',
    totalScore: 19491,
    membershipType: 4,
    classType: 2,
    rank: 169,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'B3ndR101-tlahr23',
    totalScore: 19451,
    membershipType: 2,
    classType: 0,
    rank: 170,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Johnny2Baddd',
    totalScore: 19410,
    membershipType: 4,
    classType: 0,
    rank: 171,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Cokkymonster',
    totalScore: 19404,
    membershipType: 2,
    classType: 2,
    rank: 172,
    profilePicturePath: '/img/profile/avatars/cc68.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'I_Am_Dovahkiin17',
    totalScore: 19354,
    membershipType: 2,
    classType: 1,
    rank: 173,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Deus Ex BBQ',
    totalScore: 19313,
    membershipType: 1,
    classType: 2,
    rank: 174,
    profilePicturePath: '/img/profile/avatars/avatar12.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Legolewster',
    totalScore: 19271,
    membershipType: 2,
    classType: 2,
    rank: 175,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_43.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'OOThatWhiteKid',
    totalScore: 19242,
    membershipType: 1,
    classType: 0,
    rank: 176,
    profilePicturePath: '/img/profile/avatars/tychooo9.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Crowskee',
    totalScore: 19229,
    membershipType: 4,
    classType: 0,
    rank: 177,
    profilePicturePath: '/img/profile/avatars/cc05.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'HyphenNinja',
    totalScore: 19215,
    membershipType: 2,
    classType: 2,
    rank: 178,
    profilePicturePath: '/img/profile/avatars/Destiny10.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'schizoisland',
    totalScore: 19137,
    membershipType: 2,
    classType: 2,
    rank: 179,
    profilePicturePath: '/img/profile/avatars/cc05.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'funmaker669966',
    totalScore: 19123,
    membershipType: 4,
    classType: 0,
    rank: 180,
    profilePicturePath: '/img/profile/avatars/cc53.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Cravensmash01',
    totalScore: 19103,
    membershipType: 2,
    classType: 1,
    rank: 181,
    profilePicturePath: '/img/profile/avatars/cc20.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'BLUEvAsH101',
    totalScore: 19074,
    membershipType: 2,
    classType: 2,
    rank: 182,
    profilePicturePath: '/img/profile/avatars/e2015_09.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Khaos',
    totalScore: 19004,
    membershipType: 4,
    classType: 2,
    rank: 183,
    profilePicturePath: '/img/profile/avatars/cc50.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Oefvet2006',
    totalScore: 18930,
    membershipType: 4,
    classType: 1,
    rank: 184,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'simplekitty',
    totalScore: 18927,
    membershipType: 2,
    classType: 2,
    rank: 185,
    profilePicturePath: '/img/profile/avatars/crew_health_plate.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'imthaBIGone',
    totalScore: 18838,
    membershipType: 2,
    classType: 0,
    rank: 186,
    profilePicturePath: '/img/profile/avatars/bungieday_08.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Airinfo ninja',
    totalScore: 18798,
    membershipType: 1,
    classType: 1,
    rank: 187,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Krigster',
    totalScore: 18791,
    membershipType: 4,
    classType: 0,
    rank: 188,
    profilePicturePath: '/img/profile/avatars/oonsk.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'sleepyJogi',
    totalScore: 18760,
    membershipType: 2,
    classType: 1,
    rank: 189,
    profilePicturePath: '/img/profile/avatars/avatar26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'shade',
    totalScore: 18746,
    membershipType: 4,
    classType: 1,
    rank: 190,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_05.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'n1nJAmes',
    totalScore: 18733,
    membershipType: 2,
    classType: 1,
    rank: 191,
    profilePicturePath: '/img/profile/avatars/attention2.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'MrFortesoulexe',
    totalScore: 18697,
    membershipType: 2,
    classType: 1,
    rank: 192,
    profilePicturePath: '/img/profile/avatars/cc28.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Poiboi419',
    totalScore: 18552,
    membershipType: 2,
    classType: 2,
    rank: 193,
    profilePicturePath: '/img/profile/avatars/cc00007.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Articus 1989',
    totalScore: 18479,
    membershipType: 1,
    classType: 1,
    rank: 194,
    profilePicturePath: '/img/profile/avatars/marine_ava.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'WaterGardian',
    totalScore: 18388,
    membershipType: 1,
    classType: 0,
    rank: 195,
    profilePicturePath: '/img/profile/avatars/Destiny04.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'RandaleRalf43',
    totalScore: 18373,
    membershipType: 1,
    classType: 1,
    rank: 196,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_09.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Mizu',
    totalScore: 18327,
    membershipType: 1,
    classType: 1,
    rank: 197,
    profilePicturePath: '/img/profile/avatars/hayabusaw.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'bleach punk',
    totalScore: 18290,
    membershipType: 2,
    classType: 2,
    rank: 198,
    profilePicturePath: '/img/profile/avatars/Destiny16.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'samdodge92',
    totalScore: 18270,
    membershipType: 2,
    classType: 0,
    rank: 199,
    profilePicturePath: '/img/profile/avatars/cc66.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Andre2319170',
    totalScore: 18182,
    membershipType: 2,
    classType: 2,
    rank: 200,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
];

export default week2;
