const week3 = [
  {
    displayName: 'Modern Tryhard',
    totalScore: 308451,
    membershipType: 4,
    classType: 2,
    rank: 1,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'DatsWotiStepin',
    totalScore: 292338,
    membershipType: 2,
    classType: 1,
    rank: 2,
    profilePicturePath: '/img/profile/avatars/cc54.jpg',
  },
  {
    displayName: 'Ronatello',
    totalScore: 246052,
    membershipType: 2,
    classType: 1,
    rank: 3,
    profilePicturePath: '/img/profile/avatars/bungieday_06.jpg',
  },
  {
    displayName: 'ImpulseStrike00',
    totalScore: 244504,
    membershipType: 2,
    classType: 2,
    rank: 4,
    profilePicturePath: '/img/profile/avatars/05.jpg',
  },
  {
    displayName: 'mmercury4',
    totalScore: 240162,
    membershipType: 2,
    classType: 2,
    rank: 5,
    profilePicturePath: '/img/profile/avatars/cc72.jpg',
  },
  {
    displayName: 'M0NKEYMAJIK',
    totalScore: 231445,
    membershipType: 1,
    classType: 0,
    rank: 6,
    profilePicturePath: '/img/profile/avatars/bungieday_13.jpg',
  },
  {
    displayName: 'katlyn_kat',
    totalScore: 205752,
    membershipType: 2,
    classType: 2,
    rank: 7,
    profilePicturePath: '/img/profile/avatars/cc06.jpg',
  },
  {
    displayName: 'LUCK852',
    totalScore: 197948,
    membershipType: 2,
    classType: 0,
    rank: 8,
    profilePicturePath: '/img/profile/avatars/bungieday_08.jpg',
  },
  {
    displayName: 'OperationBOAR',
    totalScore: 163569,
    membershipType: 1,
    classType: 1,
    rank: 9,
    profilePicturePath: '/img/profile/avatars/seventhcolumn7.gif',
  },
  {
    displayName: 'Schmiegster',
    totalScore: 156867,
    membershipType: 4,
    classType: 1,
    rank: 10,
    profilePicturePath: '/img/profile/avatars/cc33.jpg',
  },
  {
    displayName: 'Petrifyze',
    totalScore: 155116,
    membershipType: 4,
    classType: 0,
    rank: 11,
    profilePicturePath: '/img/profile/avatars/e2015_15.jpg',
  },
  {
    displayName: 'HALO3KILLER187',
    totalScore: 122363,
    membershipType: 1,
    classType: 0,
    rank: 12,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=3&1=0&2=10&3=3&fi=52&bi=15&fl=1&m=1',
  },
  {
    displayName: 'Ember',
    totalScore: 101711,
    membershipType: 4,
    classType: 2,
    rank: 13,
    profilePicturePath: '/img/profile/avatars/08.jpg',
  },
  {
    displayName: 'City',
    totalScore: 98159,
    membershipType: 4,
    classType: 0,
    rank: 14,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'bizzy817',
    totalScore: 91023,
    membershipType: 2,
    classType: 1,
    rank: 15,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'SoCalBear',
    totalScore: 79168,
    membershipType: 2,
    classType: 2,
    rank: 16,
    profilePicturePath: '/img/profile/avatars/s_AVATAR.jpg',
  },
  {
    displayName: 'NinjazZz',
    totalScore: 77484,
    membershipType: 4,
    classType: 0,
    rank: 17,
    profilePicturePath: '/img/profile/avatars/bungiedayav4.jpg',
  },
  {
    displayName: 'QuantomSandwich',
    totalScore: 73279,
    membershipType: 1,
    classType: 1,
    rank: 18,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'North1984',
    totalScore: 68009,
    membershipType: 1,
    classType: 2,
    rank: 19,
    profilePicturePath: '/img/profile/avatars/huntinghunter.jpg',
  },
  {
    displayName: 'APEX TAIGER',
    totalScore: 63453,
    membershipType: 2,
    classType: 1,
    rank: 20,
    profilePicturePath: '/img/profile/avatars/bungieday_19.jpg',
  },
  {
    displayName: 'brentd25',
    totalScore: 61948,
    membershipType: 2,
    classType: 1,
    rank: 21,
    profilePicturePath: '/img/profile/avatars/s_Vitruvian_Chief.jpg',
  },
  {
    displayName: 'Nindrone',
    totalScore: 60752,
    membershipType: 4,
    classType: 0,
    rank: 22,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'LeoSea_Alpha',
    totalScore: 56791,
    membershipType: 2,
    classType: 1,
    rank: 23,
    profilePicturePath: '/img/profile/avatars/s_Vitruvian_Chief.jpg',
  },
  {
    displayName: 'RoyalKiller26',
    totalScore: 55611,
    membershipType: 1,
    classType: 1,
    rank: 24,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'seiteki',
    totalScore: 54639,
    membershipType: 4,
    classType: 1,
    rank: 25,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'WNG ZER0',
    totalScore: 54044,
    membershipType: 1,
    classType: 1,
    rank: 26,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=22&1=0&2=1&3=21&fi=10&bi=4&fl=1&m=3',
  },
  {
    displayName: 'Stani',
    totalScore: 53986,
    membershipType: 4,
    classType: 0,
    rank: 27,
    profilePicturePath: '/img/profile/avatars/cc51.jpg',
  },
  {
    displayName: 'GrayZXA',
    totalScore: 52981,
    membershipType: 2,
    classType: 1,
    rank: 28,
    profilePicturePath: '/img/profile/avatars/avatar18.jpg',
  },
  {
    displayName: 'PonyConspiracy',
    totalScore: 50827,
    membershipType: 2,
    classType: 0,
    rank: 29,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'atschuler',
    totalScore: 50771,
    membershipType: 2,
    classType: 1,
    rank: 30,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'joncrrnz',
    totalScore: 48983,
    membershipType: 2,
    classType: 2,
    rank: 31,
    profilePicturePath: '/img/profile/avatars/NABIUL_avatar.gif',
  },
  {
    displayName: 'PotatoeGuru',
    totalScore: 48339,
    membershipType: 2,
    classType: 2,
    rank: 32,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_19.jpg',
  },
  {
    displayName: 'Mythic_Red',
    totalScore: 47413,
    membershipType: 2,
    classType: 0,
    rank: 33,
    profilePicturePath: '/img/profile/avatars/bungieday_23.jpg',
  },
  {
    displayName: 'VerdeMachine',
    totalScore: 46108,
    membershipType: 2,
    classType: 2,
    rank: 34,
    profilePicturePath: '/img/profile/avatars/cc05.jpg',
  },
  {
    displayName: 'Infinite1026',
    totalScore: 44614,
    membershipType: 2,
    classType: 1,
    rank: 35,
    profilePicturePath: '/img/profile/avatars/Destiny07.jpg',
  },
  {
    displayName: 'D51bama',
    totalScore: 43815,
    membershipType: 2,
    classType: 0,
    rank: 36,
    profilePicturePath: '/img/profile/avatars/attention2.gif',
  },
  {
    displayName: 'Lud--Gamer',
    totalScore: 43167,
    membershipType: 4,
    classType: 1,
    rank: 37,
    profilePicturePath: '/img/profile/avatars/bungieday_05.jpg',
  },
  {
    displayName: 'ladyvirus69',
    totalScore: 39301,
    membershipType: 1,
    classType: 2,
    rank: 38,
    profilePicturePath: '/img/profile/avatars/cc000012.jpg',
  },
  {
    displayName: 'Thaddius12',
    totalScore: 39270,
    membershipType: 2,
    classType: 2,
    rank: 39,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Xylo_Void',
    totalScore: 38926,
    membershipType: 2,
    classType: 1,
    rank: 40,
    profilePicturePath: '/img/profile/avatars/Destiny16.jpg',
  },
  {
    displayName: 'YOURKEWL99',
    totalScore: 38205,
    membershipType: 4,
    classType: 1,
    rank: 41,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Dangerquack',
    totalScore: 38082,
    membershipType: 2,
    classType: 1,
    rank: 42,
    profilePicturePath: '/img/profile/avatars/sevenz.gif',
  },
  {
    displayName: 'Terrarising',
    totalScore: 37092,
    membershipType: 2,
    classType: 2,
    rank: 43,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'D458347',
    totalScore: 36970,
    membershipType: 4,
    classType: 1,
    rank: 44,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Nathen-N-8',
    totalScore: 36777,
    membershipType: 2,
    classType: 0,
    rank: 45,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
  },
  {
    displayName: 'witshcrecker2',
    totalScore: 35872,
    membershipType: 2,
    classType: 1,
    rank: 46,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'PSYCHO18797',
    totalScore: 35805,
    membershipType: 2,
    classType: 1,
    rank: 47,
    profilePicturePath: '/img/profile/avatars/Destiny05.jpg',
  },
  {
    displayName: 'Akira Kurusu',
    totalScore: 35525,
    membershipType: 4,
    classType: 2,
    rank: 48,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
  },
  {
    displayName: 'BrunoFish15',
    totalScore: 34849,
    membershipType: 4,
    classType: 2,
    rank: 49,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'classi641',
    totalScore: 34557,
    membershipType: 2,
    classType: 2,
    rank: 50,
    profilePicturePath: '/img/profile/avatars/jolly_roger.jpg',
  },
  {
    displayName: 'pistolbust',
    totalScore: 34535,
    membershipType: 2,
    classType: 0,
    rank: 51,
    profilePicturePath: '/img/profile/avatars/odst_sarge.gif',
  },
  {
    displayName: 'AwesomeJN2015',
    totalScore: 34337,
    membershipType: 2,
    classType: 1,
    rank: 52,
    profilePicturePath: '/img/profile/avatars/avatar29.jpg',
  },
  {
    displayName: 'KaiserKing01',
    totalScore: 33866,
    membershipType: 1,
    classType: 1,
    rank: 53,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'MissBaked',
    totalScore: 33753,
    membershipType: 2,
    classType: 1,
    rank: 54,
    profilePicturePath: '/img/profile/avatars/cc52.jpg',
  },
  {
    displayName: 'At0mic',
    totalScore: 33491,
    membershipType: 4,
    classType: 1,
    rank: 55,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'evil1981inside',
    totalScore: 32744,
    membershipType: 2,
    classType: 1,
    rank: 56,
    profilePicturePath: '/img/profile/avatars/cc29.jpg',
  },
  {
    displayName: 'NeKo_GrimReaper',
    totalScore: 32560,
    membershipType: 2,
    classType: 2,
    rank: 57,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
  },
  {
    displayName: 'felixthecat-drew',
    totalScore: 31954,
    membershipType: 2,
    classType: 2,
    rank: 58,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'ChangoMike',
    totalScore: 31828,
    membershipType: 4,
    classType: 0,
    rank: 59,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Natas73666',
    totalScore: 31655,
    membershipType: 1,
    classType: 2,
    rank: 60,
    profilePicturePath: '/img/profile/avatars/victory-a.jpg',
  },
  {
    displayName: 'KN0WLYF3',
    totalScore: 30862,
    membershipType: 4,
    classType: 1,
    rank: 61,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'purplepeople306',
    totalScore: 30699,
    membershipType: 1,
    classType: 2,
    rank: 62,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Desroyer',
    totalScore: 30311,
    membershipType: 4,
    classType: 1,
    rank: 63,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Noddis',
    totalScore: 30017,
    membershipType: 4,
    classType: 0,
    rank: 64,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Azkarok',
    totalScore: 29865,
    membershipType: 2,
    classType: 2,
    rank: 65,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'jamaica305',
    totalScore: 29576,
    membershipType: 2,
    classType: 0,
    rank: 66,
    profilePicturePath: '/img/profile/avatars/e2015_02.jpg',
  },
  {
    displayName: 'KA0S969696',
    totalScore: 29571,
    membershipType: 2,
    classType: 0,
    rank: 67,
    profilePicturePath: '/img/profile/avatars/cc28.jpg',
  },
  {
    displayName: 'ARIES2301',
    totalScore: 29364,
    membershipType: 2,
    classType: 2,
    rank: 68,
    profilePicturePath: '/img/profile/avatars/avatar12.jpg',
  },
  {
    displayName: 'ARAEMSEA',
    totalScore: 29357,
    membershipType: 2,
    classType: 1,
    rank: 69,
    profilePicturePath: '/img/profile/avatars/bungieday_24.jpg',
  },
  {
    displayName: 'Xx_Cobra36_xX',
    totalScore: 28498,
    membershipType: 4,
    classType: 1,
    rank: 70,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'X-SmOoTh-SoUL-X',
    totalScore: 28247,
    membershipType: 2,
    classType: 0,
    rank: 71,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Gunman0099',
    totalScore: 28172,
    membershipType: 1,
    classType: 1,
    rank: 72,
    profilePicturePath: '/img/profile/avatars/Destiny20.jpg',
  },
  {
    displayName: 'Blossuhm',
    totalScore: 28069,
    membershipType: 1,
    classType: 1,
    rank: 73,
    profilePicturePath: '/img/profile/avatars/a_reach_9.jpg',
  },
  {
    displayName: 'twitch-Doxxnoxx',
    totalScore: 28062,
    membershipType: 2,
    classType: 2,
    rank: 74,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Vinilla',
    totalScore: 27852,
    membershipType: 4,
    classType: 0,
    rank: 75,
    profilePicturePath: '/img/profile/avatars/cc08.jpg',
  },
  {
    displayName: 'Om3gaBlack XBL',
    totalScore: 27306,
    membershipType: 1,
    classType: 2,
    rank: 76,
    profilePicturePath: '/img/profile/avatars/Destiny26.jpg',
  },
  {
    displayName: 'A7yrGamer',
    totalScore: 27265,
    membershipType: 2,
    classType: 0,
    rank: 77,
    profilePicturePath: '/img/profile/avatars/shield2.jpg',
  },
  {
    displayName: 'TRON197462',
    totalScore: 27263,
    membershipType: 2,
    classType: 1,
    rank: 78,
    profilePicturePath: '/img/profile/avatars/skull1w.gif',
  },
  {
    displayName: 'BravesSteve',
    totalScore: 27230,
    membershipType: 2,
    classType: 1,
    rank: 79,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'ramon',
    totalScore: 27097,
    membershipType: 1,
    classType: 1,
    rank: 80,
    profilePicturePath: '/img/profile/avatars/h3_icon.gif',
  },
  {
    displayName: 'Silverjedi1987',
    totalScore: 26639,
    membershipType: 1,
    classType: 1,
    rank: 81,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'aka_Smitty_420',
    totalScore: 26627,
    membershipType: 2,
    classType: 1,
    rank: 82,
    profilePicturePath: '/img/profile/avatars/s_AwesomeAvatar.jpg',
  },
  {
    displayName: 'ZachJ',
    totalScore: 26210,
    membershipType: 2,
    classType: 2,
    rank: 83,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'DaShazzV9',
    totalScore: 26060,
    membershipType: 2,
    classType: 0,
    rank: 84,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'Golden Riches',
    totalScore: 25898,
    membershipType: 4,
    classType: 1,
    rank: 85,
    profilePicturePath: '/img/profile/avatars/cc00008.jpg',
  },
  {
    displayName: 'Draco',
    totalScore: 25714,
    membershipType: 2,
    classType: 1,
    rank: 86,
    profilePicturePath: '/img/profile/avatars/bungieday_05.jpg',
  },
  {
    displayName: 'DirtySwine',
    totalScore: 25686,
    membershipType: 4,
    classType: 1,
    rank: 87,
    profilePicturePath: '/img/profile/avatars/cc29.jpg',
  },
  {
    displayName: 'BillyMcfloy',
    totalScore: 25673,
    membershipType: 4,
    classType: 0,
    rank: 88,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Benj',
    totalScore: 25639,
    membershipType: 4,
    classType: 1,
    rank: 89,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'Lar3nik',
    totalScore: 25570,
    membershipType: 2,
    classType: 0,
    rank: 90,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'kashmcdash169',
    totalScore: 25498,
    membershipType: 2,
    classType: 1,
    rank: 91,
    profilePicturePath: '/img/profile/avatars/cc07.jpg',
  },
  {
    displayName: 'Volsstrecker',
    totalScore: 25238,
    membershipType: 2,
    classType: 0,
    rank: 92,
    profilePicturePath: '/img/profile/avatars/apez.gif',
  },
  {
    displayName: 'Running Prdigy',
    totalScore: 25228,
    membershipType: 4,
    classType: 1,
    rank: 93,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Zurgery',
    totalScore: 25071,
    membershipType: 4,
    classType: 2,
    rank: 94,
    profilePicturePath: '/img/profile/avatars/cc00002.jpg',
  },
  {
    displayName: 'SnippyMars694',
    totalScore: 24961,
    membershipType: 1,
    classType: 2,
    rank: 95,
    profilePicturePath: '/img/profile/avatars/CarnageZone.gif',
  },
  {
    displayName: 'ImSOAsavage',
    totalScore: 24672,
    membershipType: 4,
    classType: 0,
    rank: 96,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Stout',
    totalScore: 24544,
    membershipType: 4,
    classType: 1,
    rank: 97,
    profilePicturePath: '/img/profile/avatars/Destiny26.jpg',
  },
  {
    displayName: 'NTowers',
    totalScore: 24519,
    membershipType: 2,
    classType: 1,
    rank: 98,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'Diggie',
    totalScore: 24493,
    membershipType: 2,
    classType: 2,
    rank: 99,
    profilePicturePath: '/img/profile/avatars/bungieday_10.jpg',
  },
  {
    displayName: 'Jbrisse93',
    totalScore: 24479,
    membershipType: 2,
    classType: 1,
    rank: 100,
    profilePicturePath: '/img/profile/avatars/bungieday_14.jpg',
  },
  {
    displayName: 'Drehix',
    totalScore: 24086,
    membershipType: 1,
    classType: 1,
    rank: 101,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'faulky2852',
    totalScore: 24083,
    membershipType: 2,
    classType: 0,
    rank: 102,
    profilePicturePath: '/img/profile/avatars/Destiny25.jpg',
  },
  {
    displayName: 'Arsun Fist',
    totalScore: 24003,
    membershipType: 1,
    classType: 2,
    rank: 103,
    profilePicturePath: '/img/profile/avatars/cc32.jpg',
  },
  {
    displayName: 'Jeff Dominates',
    totalScore: 23979,
    membershipType: 1,
    classType: 2,
    rank: 104,
    profilePicturePath: '/img/profile/avatars/Destiny05.jpg',
  },
  {
    displayName: 'cluemasta',
    totalScore: 23883,
    membershipType: 2,
    classType: 2,
    rank: 105,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Qbano86',
    totalScore: 23190,
    membershipType: 2,
    classType: 0,
    rank: 106,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'SubAtomicBlaze1',
    totalScore: 23032,
    membershipType: 2,
    classType: 0,
    rank: 107,
    profilePicturePath: '/img/profile/avatars/cc24.jpg',
  },
  {
    displayName: 'OKTAYG',
    totalScore: 22828,
    membershipType: 4,
    classType: 1,
    rank: 108,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
  },
  {
    displayName: 'Serrated Viper',
    totalScore: 22671,
    membershipType: 2,
    classType: 1,
    rank: 109,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_44.jpg',
  },
  {
    displayName: 'Dr Ment0r',
    totalScore: 22664,
    membershipType: 4,
    classType: 1,
    rank: 110,
    profilePicturePath: '/img/profile/avatars/cc18.jpg',
  },
  {
    displayName: 'deltawolf5575',
    totalScore: 22397,
    membershipType: 2,
    classType: 1,
    rank: 111,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'SwatxXxComatose',
    totalScore: 22349,
    membershipType: 1,
    classType: 1,
    rank: 112,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
  },
  {
    displayName: 'HomieBackrubs',
    totalScore: 22284,
    membershipType: 1,
    classType: 1,
    rank: 113,
    profilePicturePath: '/img/profile/avatars/bungieday_01.jpg',
  },
  {
    displayName: 'ThePandaDg',
    totalScore: 22242,
    membershipType: 1,
    classType: 1,
    rank: 114,
    profilePicturePath: '/img/profile/avatars/14.jpg',
  },
  {
    displayName: 'Fresh_Prince_077',
    totalScore: 22224,
    membershipType: 2,
    classType: 1,
    rank: 115,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'SH_RKB0i',
    totalScore: 22042,
    membershipType: 2,
    classType: 1,
    rank: 116,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_19.jpg',
  },
  {
    displayName: 'OGGodLee',
    totalScore: 21835,
    membershipType: 2,
    classType: 1,
    rank: 117,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'mb4ife',
    totalScore: 21801,
    membershipType: 2,
    classType: 2,
    rank: 118,
    profilePicturePath: '/img/profile/avatars/bungieday_02.jpg',
  },
  {
    displayName: 'ur_3key',
    totalScore: 21780,
    membershipType: 2,
    classType: 0,
    rank: 119,
    profilePicturePath: '/img/profile/avatars/bungieday_14.jpg',
  },
  {
    displayName: 'CARLOS-TZK',
    totalScore: 21680,
    membershipType: 2,
    classType: 2,
    rank: 120,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'refix',
    totalScore: 21608,
    membershipType: 4,
    classType: 1,
    rank: 121,
    profilePicturePath: '/img/profile/avatars/90x90ava.jpg',
  },
  {
    displayName: 'Corey80 ',
    totalScore: 21552,
    membershipType: 2,
    classType: 1,
    rank: 122,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
  },
  {
    displayName: 'g4rybus3y713',
    totalScore: 21258,
    membershipType: 2,
    classType: 0,
    rank: 123,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'xXcybercyborgXx',
    totalScore: 21217,
    membershipType: 2,
    classType: 0,
    rank: 124,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'Ghst Of Reach',
    totalScore: 21209,
    membershipType: 4,
    classType: 1,
    rank: 125,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=2&1=0&2=1&3=2&fi=39&bi=4&fl=1&m=2',
  },
  {
    displayName: 'Chibi Hoax',
    totalScore: 21207,
    membershipType: 1,
    classType: 1,
    rank: 126,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'xDelgado509',
    totalScore: 21207,
    membershipType: 2,
    classType: 1,
    rank: 127,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Jormungandar',
    totalScore: 21190,
    membershipType: 2,
    classType: 0,
    rank: 128,
    profilePicturePath: '/img/profile/avatars/cc54.jpg',
  },
  {
    displayName: 'Brochu',
    totalScore: 21149,
    membershipType: 2,
    classType: 1,
    rank: 129,
    profilePicturePath: '/img/profile/avatars/bungieday_03.jpg',
  },
  {
    displayName: 'Heman',
    totalScore: 21116,
    membershipType: 4,
    classType: 1,
    rank: 130,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
  },
  {
    displayName: 'cpih',
    totalScore: 20633,
    membershipType: 4,
    classType: 1,
    rank: 131,
    profilePicturePath: '/img/profile/avatars/odst_skull_8_2.png',
  },
  {
    displayName: 'KingothaNorth',
    totalScore: 20595,
    membershipType: 2,
    classType: 1,
    rank: 132,
    profilePicturePath: '/img/profile/avatars/a_reach_9.jpg',
  },
  {
    displayName: 'Vorlord',
    totalScore: 20575,
    membershipType: 2,
    classType: 2,
    rank: 133,
    profilePicturePath: '/img/profile/avatars/Destiny20.jpg',
  },
  {
    displayName: 'Rez PIz',
    totalScore: 20533,
    membershipType: 1,
    classType: 0,
    rank: 134,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_19.jpg',
  },
  {
    displayName: 'The Kid Kanji',
    totalScore: 20512,
    membershipType: 2,
    classType: 1,
    rank: 135,
    profilePicturePath: '/img/profile/avatars/th_BN2Avs82.jpg',
  },
  {
    displayName: 'GamePun',
    totalScore: 20459,
    membershipType: 2,
    classType: 1,
    rank: 136,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'Xia Johan',
    totalScore: 20312,
    membershipType: 4,
    classType: 1,
    rank: 137,
    profilePicturePath: '/img/profile/avatars/bungieday_04.jpg',
  },
  {
    displayName: 'Tezuk92',
    totalScore: 20248,
    membershipType: 2,
    classType: 2,
    rank: 138,
    profilePicturePath: '/img/profile/avatars/bungieday_16.jpg',
  },
  {
    displayName: 'Datenshix',
    totalScore: 20233,
    membershipType: 2,
    classType: 2,
    rank: 139,
    profilePicturePath: '/img/profile/avatars/cc07.jpg',
  },
  {
    displayName: 'schulzy12',
    totalScore: 20088,
    membershipType: 4,
    classType: 0,
    rank: 140,
    profilePicturePath: '/img/profile/avatars/cc55.jpg',
  },
  {
    displayName: 'Zezzer',
    totalScore: 20070,
    membershipType: 2,
    classType: 1,
    rank: 141,
    profilePicturePath: '/img/profile/avatars/bungieday_13.jpg',
  },
  {
    displayName: 'LazyBoyStreaming',
    totalScore: 20066,
    membershipType: 1,
    classType: 0,
    rank: 142,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'SGen1625',
    totalScore: 19995,
    membershipType: 2,
    classType: 2,
    rank: 143,
    profilePicturePath: '/img/profile/avatars/bungieday_23.jpg',
  },
  {
    displayName: 'PHILLYFEELG00D',
    totalScore: 19902,
    membershipType: 1,
    classType: 0,
    rank: 144,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Nike Death',
    totalScore: 19833,
    membershipType: 1,
    classType: 0,
    rank: 145,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
  },
  {
    displayName: 'Bad B0Y 454',
    totalScore: 19828,
    membershipType: 1,
    classType: 1,
    rank: 146,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Trent Scott',
    totalScore: 19808,
    membershipType: 4,
    classType: 1,
    rank: 147,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Meximan6669',
    totalScore: 19798,
    membershipType: 1,
    classType: 2,
    rank: 148,
    profilePicturePath: '/img/profile/avatars/th_BnetAvs33.jpg',
  },
  {
    displayName: 'graeme00',
    totalScore: 19672,
    membershipType: 2,
    classType: 1,
    rank: 149,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'KareBearr JM',
    totalScore: 19614,
    membershipType: 1,
    classType: 1,
    rank: 150,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'FabbMaDD',
    totalScore: 19592,
    membershipType: 2,
    classType: 2,
    rank: 151,
    profilePicturePath: '/img/profile/avatars/bungiedayav4.jpg',
  },
  {
    displayName: 'Onhanseniijoo',
    totalScore: 19545,
    membershipType: 2,
    classType: 2,
    rank: 152,
    profilePicturePath: '/img/profile/avatars/skull1w.gif',
  },
  {
    displayName: 'Randizz7e',
    totalScore: 19539,
    membershipType: 2,
    classType: 1,
    rank: 153,
    profilePicturePath: '/img/profile/avatars/cc06.jpg',
  },
  {
    displayName: 'Trickz22',
    totalScore: 19476,
    membershipType: 2,
    classType: 1,
    rank: 154,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'QuadradoRedondo',
    totalScore: 19406,
    membershipType: 4,
    classType: 1,
    rank: 155,
    profilePicturePath: '/img/profile/avatars/cc47.jpg',
  },
  {
    displayName: 'DonCeaser',
    totalScore: 19299,
    membershipType: 1,
    classType: 1,
    rank: 156,
    profilePicturePath: '/img/profile/avatars/bungieday_10.jpg',
  },
  {
    displayName: 'X Vepz X',
    totalScore: 19189,
    membershipType: 1,
    classType: 1,
    rank: 157,
    profilePicturePath: '/img/profile/avatars/cc49.jpg',
  },
  {
    displayName: 'FenRed',
    totalScore: 19160,
    membershipType: 1,
    classType: 0,
    rank: 158,
    profilePicturePath: '/img/profile/avatars/cc64.jpg',
  },
  {
    displayName: 'Samc1987',
    totalScore: 19131,
    membershipType: 1,
    classType: 1,
    rank: 159,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'JoyStickMaster',
    totalScore: 19112,
    membershipType: 1,
    classType: 2,
    rank: 160,
    profilePicturePath: '/img/profile/avatars/cc00007.jpg',
  },
  {
    displayName: 'WinnyTheLegend',
    totalScore: 19075,
    membershipType: 4,
    classType: 0,
    rank: 161,
    profilePicturePath: '/img/profile/avatars/cc63.jpg',
  },
  {
    displayName: 'vizualassassin77',
    totalScore: 19011,
    membershipType: 2,
    classType: 1,
    rank: 162,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Dino',
    totalScore: 18996,
    membershipType: 1,
    classType: 2,
    rank: 163,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_34.jpg',
  },
  {
    displayName: 'B1ue orochi',
    totalScore: 18994,
    membershipType: 1,
    classType: 2,
    rank: 164,
    profilePicturePath: '/img/profile/avatars/bungiedayav2.jpg',
  },
  {
    displayName: 'LE_PATRON_QC',
    totalScore: 18933,
    membershipType: 4,
    classType: 1,
    rank: 165,
    profilePicturePath: '/img/profile/avatars/e2015_11.jpg',
  },
  {
    displayName: 'B31GREEN',
    totalScore: 18924,
    membershipType: 1,
    classType: 1,
    rank: 166,
    profilePicturePath: '/img/profile/avatars/cc08.jpg',
  },
  {
    displayName: 'JBates91',
    totalScore: 18797,
    membershipType: 2,
    classType: 1,
    rank: 167,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Zorg',
    totalScore: 18761,
    membershipType: 4,
    classType: 1,
    rank: 168,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'Sir_Dom97',
    totalScore: 18749,
    membershipType: 2,
    classType: 1,
    rank: 169,
    profilePicturePath: '/img/profile/avatars/cc29.jpg',
  },
  {
    displayName: 'Inf1d3l32',
    totalScore: 18728,
    membershipType: 2,
    classType: 0,
    rank: 170,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'freezhart',
    totalScore: 18716,
    membershipType: 4,
    classType: 2,
    rank: 171,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Fulcrum',
    totalScore: 18668,
    membershipType: 4,
    classType: 2,
    rank: 172,
    profilePicturePath: '/img/profile/avatars/cc34.jpg',
  },
  {
    displayName: 'JdRibcage',
    totalScore: 18613,
    membershipType: 2,
    classType: 1,
    rank: 173,
    profilePicturePath: '/img/profile/avatars/bungieday_14.jpg',
  },
  {
    displayName: 'sirloganmoore1',
    totalScore: 18476,
    membershipType: 2,
    classType: 1,
    rank: 174,
    profilePicturePath: '/img/profile/avatars/avatar3.jpg',
  },
  {
    displayName: 'nykyrian1',
    totalScore: 18462,
    membershipType: 1,
    classType: 2,
    rank: 175,
    profilePicturePath: '/img/profile/avatars/sep.jpg',
  },
  {
    displayName: 'Ferchi',
    totalScore: 18374,
    membershipType: 4,
    classType: 1,
    rank: 176,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
  },
  {
    displayName: 'ToxicVaporz0069',
    totalScore: 18359,
    membershipType: 1,
    classType: 0,
    rank: 177,
    profilePicturePath: '/img/profile/avatars/cc60.jpg',
  },
  {
    displayName: 'Kaleidoscoped',
    totalScore: 18323,
    membershipType: 1,
    classType: 0,
    rank: 178,
    profilePicturePath: '/img/profile/avatars/avatar27.jpg',
  },
  {
    displayName: 'greennumber9',
    totalScore: 18292,
    membershipType: 2,
    classType: 2,
    rank: 179,
    profilePicturePath: '/img/profile/avatars/cc000016.jpg',
  },
  {
    displayName: 'nightwalker',
    totalScore: 18229,
    membershipType: 4,
    classType: 0,
    rank: 180,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'TheBouncingMalu',
    totalScore: 18175,
    membershipType: 1,
    classType: 0,
    rank: 181,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'Mrsheep713',
    totalScore: 18002,
    membershipType: 1,
    classType: 2,
    rank: 182,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'sped316',
    totalScore: 17961,
    membershipType: 1,
    classType: 2,
    rank: 183,
    profilePicturePath: '/img/profile/avatars/bungieday_06.jpg',
  },
  {
    displayName: 'SKULL GRINDER3',
    totalScore: 17931,
    membershipType: 1,
    classType: 0,
    rank: 184,
    profilePicturePath: '/img/profile/avatars/cc59.jpg',
  },
  {
    displayName: 'bleach punk',
    totalScore: 17924,
    membershipType: 2,
    classType: 2,
    rank: 185,
    profilePicturePath: '/img/profile/avatars/Destiny16.jpg',
  },
  {
    displayName: 'BLACK-ZONE-',
    totalScore: 17898,
    membershipType: 2,
    classType: 1,
    rank: 186,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
  },
  {
    displayName: 'michael11353',
    totalScore: 17852,
    membershipType: 2,
    classType: 1,
    rank: 187,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'SupremeFighter',
    totalScore: 17849,
    membershipType: 2,
    classType: 0,
    rank: 188,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'Root Beer Drunk',
    totalScore: 17841,
    membershipType: 2,
    classType: 1,
    rank: 189,
    profilePicturePath: '/img/profile/avatars/th_BN2Avs82.jpg',
  },
  {
    displayName: 'ElitePVP',
    totalScore: 17837,
    membershipType: 2,
    classType: 0,
    rank: 190,
    profilePicturePath: '/img/profile/avatars/a_reach_14.gif',
  },
  {
    displayName: 'fallenark',
    totalScore: 17791,
    membershipType: 4,
    classType: 0,
    rank: 191,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'CelestialKittenx',
    totalScore: 17789,
    membershipType: 2,
    classType: 2,
    rank: 192,
    profilePicturePath: '/img/profile/avatars/cc19.jpg',
  },
  {
    displayName: 'White Whither',
    totalScore: 17770,
    membershipType: 2,
    classType: 1,
    rank: 193,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Bacaboy69',
    totalScore: 17767,
    membershipType: 2,
    classType: 1,
    rank: 194,
    profilePicturePath: '/img/profile/avatars/cc58.jpg',
  },
  {
    displayName: 'Dull',
    totalScore: 17630,
    membershipType: 1,
    classType: 1,
    rank: 195,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'ZealousEnigma',
    totalScore: 17616,
    membershipType: 2,
    classType: 1,
    rank: 196,
    profilePicturePath: '/img/profile/avatars/cc23.jpg',
  },
  {
    displayName: 'Shoopiedoop',
    totalScore: 17549,
    membershipType: 2,
    classType: 2,
    rank: 197,
    profilePicturePath: '/img/profile/avatars/cc66.jpg',
  },
  {
    displayName: 'Overdose XO',
    totalScore: 17521,
    membershipType: 2,
    classType: 2,
    rank: 198,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_10.jpg',
  },
  {
    displayName: 'Techmaturgic',
    totalScore: 17459,
    membershipType: 4,
    classType: 0,
    rank: 199,
    profilePicturePath: '/img/profile/avatars/cc14.jpg',
  },
  {
    displayName: 'JimmyNutron650',
    totalScore: 17452,
    membershipType: 2,
    classType: 1,
    rank: 200,
    profilePicturePath: '/img/profile/avatars/bungieday_04.jpg',
  },
];

export default week3;
