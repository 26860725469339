const week1 = [
  {
    displayName: 'JLPERRY16',
    totalScore: 123700,
    membershipType: 2,
    classType: 0,
    rank: 1,
    profilePicturePath: '/img/profile/avatars/Destiny11.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'JOiNMYHiTLiSTx',
    totalScore: 112594,
    membershipType: 2,
    classType: 1,
    rank: 2,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'crazycoop40',
    totalScore: 108164,
    membershipType: 2,
    classType: 0,
    rank: 3,
    profilePicturePath: '/img/profile/avatars/bungieday_12.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'willah g',
    totalScore: 106504,
    membershipType: 1,
    classType: 2,
    rank: 4,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'NeKo_GrimReaper',
    totalScore: 104965,
    membershipType: 2,
    classType: 2,
    rank: 5,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Daniel Orozco',
    totalScore: 103006,
    membershipType: 2,
    classType: 1,
    rank: 6,
    profilePicturePath: '/img/profile/avatars/cc50.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ZachJ',
    totalScore: 101739,
    membershipType: 2,
    classType: 2,
    rank: 7,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'B1ue orochi',
    totalScore: 88521,
    membershipType: 1,
    classType: 2,
    rank: 8,
    profilePicturePath: '/img/profile/avatars/bungiedayav2.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Tragic',
    totalScore: 87867,
    membershipType: 4,
    classType: 1,
    rank: 9,
    profilePicturePath: '/img/profile/avatars/cc28.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'tw0sh0t2345',
    totalScore: 81167,
    membershipType: 1,
    classType: 2,
    rank: 10,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'GUNSABLAZ1NG',
    totalScore: 78936,
    membershipType: 1,
    classType: 0,
    rank: 11,
    profilePicturePath: '/img/profile/avatars/bungieday_24.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'M0NKEYMAJIK',
    totalScore: 72546,
    membershipType: 1,
    classType: 0,
    rank: 12,
    profilePicturePath: '/img/profile/avatars/bungieday_13.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Jaren ward',
    totalScore: 63465,
    membershipType: 1,
    classType: 1,
    rank: 13,
    profilePicturePath: '/img/profile/avatars/s_AVATAR.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'HALO3KILLER187',
    totalScore: 59455,
    membershipType: 1,
    classType: 0,
    rank: 14,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=3&1=0&2=10&3=3&fi=52&bi=15&fl=1&m=1',
    style: { scale: 0 },
  },
  {
    displayName: 'Elegant',
    totalScore: 56303,
    membershipType: 4,
    classType: 1,
    rank: 15,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Southernrose160',
    totalScore: 50364,
    membershipType: 2,
    classType: 1,
    rank: 16,
    profilePicturePath: '/img/profile/avatars/bungieday_04.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'trudeaulove',
    totalScore: 48806,
    membershipType: 2,
    classType: 2,
    rank: 17,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'slayer53 owns u',
    totalScore: 48355,
    membershipType: 1,
    classType: 2,
    rank: 18,
    profilePicturePath: '/img/profile/avatars/Johnson-clear--back.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Unversed',
    totalScore: 45174,
    membershipType: 2,
    classType: 2,
    rank: 19,
    profilePicturePath: '/img/profile/avatars/cc34.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'rogue217',
    totalScore: 44279,
    membershipType: 2,
    classType: 0,
    rank: 20,
    profilePicturePath: '/img/profile/avatars/bungieday_16.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Cooper102789',
    totalScore: 43958,
    membershipType: 1,
    classType: 1,
    rank: 21,
    profilePicturePath: '/img/profile/avatars/a_reach_15.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'g12345daniel',
    totalScore: 41450,
    membershipType: 2,
    classType: 0,
    rank: 22,
    profilePicturePath: '/img/profile/avatars/cc00001.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'xx2GunzzSr',
    totalScore: 40574,
    membershipType: 2,
    classType: 2,
    rank: 23,
    profilePicturePath: '/img/profile/avatars/avatar9.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'avXRiku',
    totalScore: 39342,
    membershipType: 1,
    classType: 2,
    rank: 24,
    profilePicturePath: '/img/profile/avatars/avatar29.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ladyvirus69',
    totalScore: 39298,
    membershipType: 1,
    classType: 2,
    rank: 25,
    profilePicturePath: '/img/profile/avatars/cc000012.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Grummpybil',
    totalScore: 38431,
    membershipType: 2,
    classType: 1,
    rank: 26,
    profilePicturePath: '/img/profile/avatars/bungieday_03.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'AceOneZero',
    totalScore: 38084,
    membershipType: 2,
    classType: 1,
    rank: 27,
    profilePicturePath: '/img/profile/avatars/bungieday_24.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Kisaragibot',
    totalScore: 37893,
    membershipType: 1,
    classType: 1,
    rank: 28,
    profilePicturePath: '/img/profile/avatars/cc000010.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Knitehawk',
    totalScore: 37324,
    membershipType: 1,
    classType: 1,
    rank: 29,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_27.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Critical6aming',
    totalScore: 36861,
    membershipType: 1,
    classType: 0,
    rank: 30,
    profilePicturePath: '/img/profile/avatars/cc00009.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'PandaSix_HD',
    totalScore: 36405,
    membershipType: 2,
    classType: 1,
    rank: 31,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'mchanke',
    totalScore: 36124,
    membershipType: 2,
    classType: 1,
    rank: 32,
    profilePicturePath: '/img/profile/avatars/avatar10.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Jeffrakiss',
    totalScore: 35278,
    membershipType: 1,
    classType: 2,
    rank: 33,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Final_Meds',
    totalScore: 35209,
    membershipType: 2,
    classType: 1,
    rank: 34,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_45.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ZiecoZebra',
    totalScore: 34225,
    membershipType: 2,
    classType: 0,
    rank: 35,
    profilePicturePath: '/img/profile/avatars/th_cortanaHGN_001.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Brennan Roberts',
    totalScore: 33808,
    membershipType: 1,
    classType: 0,
    rank: 36,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_17.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'nemo6435',
    totalScore: 33416,
    membershipType: 2,
    classType: 2,
    rank: 37,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'XSolosoldierX',
    totalScore: 33192,
    membershipType: 2,
    classType: 1,
    rank: 38,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_44.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'DesignedSkul',
    totalScore: 31639,
    membershipType: 2,
    classType: 1,
    rank: 39,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'ZkMushroom',
    totalScore: 31237,
    membershipType: 2,
    classType: 1,
    rank: 40,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_05.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'G00R00',
    totalScore: 31216,
    membershipType: 2,
    classType: 0,
    rank: 41,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'izak333',
    totalScore: 31178,
    membershipType: 2,
    classType: 2,
    rank: 42,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'NekoBlackGem',
    totalScore: 30984,
    membershipType: 2,
    classType: 2,
    rank: 43,
    profilePicturePath: '/img/profile/avatars/th_BN2Avs15.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'C1own',
    totalScore: 30805,
    membershipType: 2,
    classType: 0,
    rank: 44,
    profilePicturePath: '/img/profile/avatars/e2015_11.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'I M a g g z I',
    totalScore: 30674,
    membershipType: 4,
    classType: 1,
    rank: 45,
    profilePicturePath: '/img/profile/avatars/bungieday_22.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'PotatoeGuru',
    totalScore: 30487,
    membershipType: 2,
    classType: 2,
    rank: 46,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_19.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'leapafaith',
    totalScore: 30331,
    membershipType: 2,
    classType: 2,
    rank: 47,
    profilePicturePath: '/img/profile/avatars/Destiny25.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'devilatmidnite',
    totalScore: 30318,
    membershipType: 1,
    classType: 0,
    rank: 48,
    profilePicturePath: '/img/profile/avatars/avatar1.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Donnerdog',
    totalScore: 30082,
    membershipType: 4,
    classType: 1,
    rank: 49,
    profilePicturePath: '/img/profile/avatars/cc73.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Je_Fa_Music',
    totalScore: 29678,
    membershipType: 2,
    classType: 1,
    rank: 50,
    profilePicturePath: '/img/profile/avatars/warbow_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'on3sh0t2345',
    totalScore: 29449,
    membershipType: 1,
    classType: 2,
    rank: 51,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'AIKO23',
    totalScore: 29145,
    membershipType: 1,
    classType: 1,
    rank: 52,
    profilePicturePath:
      'https://halo.bungie.net/Stats/halo3/playermodel.ashx?p1=0&p2=9&p3=6&p4=6&p5=7&p6=18&p7=3&p8=7&p9=90',
    style: { scale: 0 },
  },
  {
    displayName: 'm3d1cAt10n',
    totalScore: 28979,
    membershipType: 2,
    classType: 1,
    rank: 53,
    profilePicturePath: '/img/profile/avatars/untitled2vk3.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'flashcat85',
    totalScore: 28857,
    membershipType: 1,
    classType: 2,
    rank: 54,
    profilePicturePath: '/img/profile/avatars/avatar29.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'bizzy817',
    totalScore: 28748,
    membershipType: 2,
    classType: 1,
    rank: 55,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Skorpion8997',
    totalScore: 28701,
    membershipType: 2,
    classType: 1,
    rank: 56,
    profilePicturePath: '/img/profile/avatars/bungiedayav2.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'r351574nc3',
    totalScore: 28643,
    membershipType: 2,
    classType: 1,
    rank: 57,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'LGD snipe',
    totalScore: 28509,
    membershipType: 1,
    classType: 2,
    rank: 58,
    profilePicturePath: '/img/profile/avatars/shield2.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'viking1528',
    totalScore: 28205,
    membershipType: 2,
    classType: 2,
    rank: 59,
    profilePicturePath: '/img/profile/avatars/cc00007.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Henry050323',
    totalScore: 27948,
    membershipType: 2,
    classType: 1,
    rank: 60,
    profilePicturePath: '/img/profile/avatars/shield2.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Huffy',
    totalScore: 27844,
    membershipType: 4,
    classType: 1,
    rank: 61,
    profilePicturePath: '/img/profile/avatars/14.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Outslaid',
    totalScore: 27491,
    membershipType: 1,
    classType: 1,
    rank: 62,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'quartzkiller',
    totalScore: 27377,
    membershipType: 2,
    classType: 0,
    rank: 63,
    profilePicturePath: '/img/profile/avatars/cc44.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'TheOnlyUniverse',
    totalScore: 27377,
    membershipType: 2,
    classType: 0,
    rank: 64,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'josthyn_23',
    totalScore: 27238,
    membershipType: 2,
    classType: 0,
    rank: 65,
    profilePicturePath: '/img/profile/avatars/cc00005.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Cuucu-',
    totalScore: 27220,
    membershipType: 2,
    classType: 2,
    rank: 66,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'XxFuZiOn_TRiczxX',
    totalScore: 27183,
    membershipType: 2,
    classType: 1,
    rank: 67,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'CiKWildPipe',
    totalScore: 27044,
    membershipType: 4,
    classType: 1,
    rank: 68,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'hellrazer21',
    totalScore: 27040,
    membershipType: 2,
    classType: 2,
    rank: 69,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Sunoe',
    totalScore: 26887,
    membershipType: 1,
    classType: 1,
    rank: 70,
    profilePicturePath: '/img/profile/avatars/cc23.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'CaLiThug4Cherry',
    totalScore: 26882,
    membershipType: 2,
    classType: 0,
    rank: 71,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'MoNkEy-_D_-RuFyX',
    totalScore: 26844,
    membershipType: 2,
    classType: 1,
    rank: 72,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'luisnavyseal25',
    totalScore: 26744,
    membershipType: 2,
    classType: 1,
    rank: 73,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'MrBlAsTyBlAsT',
    totalScore: 26743,
    membershipType: 1,
    classType: 1,
    rank: 74,
    profilePicturePath: '/img/profile/avatars/h3sniper.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'stephan21510024',
    totalScore: 26718,
    membershipType: 2,
    classType: 0,
    rank: 75,
    profilePicturePath: '/img/profile/avatars/odst_skull_4_4.png',
    style: { scale: 0 },
  },
  {
    displayName: 'LuciferCane',
    totalScore: 26355,
    membershipType: 1,
    classType: 1,
    rank: 76,
    profilePicturePath: '/img/profile/avatars/cc00007.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'KinSlayer',
    totalScore: 26306,
    membershipType: 4,
    classType: 1,
    rank: 77,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'RENO',
    totalScore: 26102,
    membershipType: 4,
    classType: 1,
    rank: 78,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'RAIDERS',
    totalScore: 25862,
    membershipType: 1,
    classType: 1,
    rank: 79,
    profilePicturePath: '/img/profile/avatars/legendary.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Vixon',
    totalScore: 25825,
    membershipType: 2,
    classType: 2,
    rank: 80,
    profilePicturePath: '/img/profile/avatars/cc50.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'slicknic96',
    totalScore: 25695,
    membershipType: 1,
    classType: 2,
    rank: 81,
    profilePicturePath: '/img/profile/avatars/bungieday_06.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Zero Spades',
    totalScore: 25638,
    membershipType: 2,
    classType: 0,
    rank: 82,
    profilePicturePath: '/img/profile/avatars/bungieday_22.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Rex Reginald',
    totalScore: 25539,
    membershipType: 1,
    classType: 1,
    rank: 83,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'KANECHAOS',
    totalScore: 25413,
    membershipType: 1,
    classType: 1,
    rank: 84,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'stink finger',
    totalScore: 25345,
    membershipType: 1,
    classType: 1,
    rank: 85,
    profilePicturePath: '/img/profile/avatars/cc15.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'BSDspeer',
    totalScore: 25088,
    membershipType: 2,
    classType: 2,
    rank: 86,
    profilePicturePath: '/img/profile/avatars/cc58.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Reshetoff',
    totalScore: 24885,
    membershipType: 2,
    classType: 1,
    rank: 87,
    profilePicturePath: '/img/profile/avatars/bungiedayav1.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Energy Flow',
    totalScore: 24885,
    membershipType: 2,
    classType: 2,
    rank: 88,
    profilePicturePath: '/img/profile/avatars/victory-a.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'DontGtSadGtGlad',
    totalScore: 24825,
    membershipType: 1,
    classType: 1,
    rank: 89,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'YoungDropOut98',
    totalScore: 24658,
    membershipType: 1,
    classType: 1,
    rank: 90,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_29.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'drpepper ninja1',
    totalScore: 24628,
    membershipType: 1,
    classType: 1,
    rank: 91,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_41.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'TheScooby',
    totalScore: 24623,
    membershipType: 1,
    classType: 1,
    rank: 92,
    profilePicturePath: '/img/profile/avatars/cc72.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'James4817',
    totalScore: 24606,
    membershipType: 2,
    classType: 1,
    rank: 93,
    profilePicturePath: '/img/profile/avatars/e2015_11.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'MA5T3RCH13F82',
    totalScore: 24316,
    membershipType: 1,
    classType: 0,
    rank: 94,
    profilePicturePath: '/img/profile/avatars/bungieday_14.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'XxWayneChungxX',
    totalScore: 24209,
    membershipType: 1,
    classType: 0,
    rank: 95,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'irish1620',
    totalScore: 24065,
    membershipType: 2,
    classType: 2,
    rank: 96,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_41.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Deadpoolvaz',
    totalScore: 24050,
    membershipType: 1,
    classType: 2,
    rank: 97,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_15.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'fablek',
    totalScore: 24048,
    membershipType: 2,
    classType: 1,
    rank: 98,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'breakneck420',
    totalScore: 24002,
    membershipType: 2,
    classType: 0,
    rank: 99,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'TitanSlayer1000',
    totalScore: 23911,
    membershipType: 1,
    classType: 1,
    rank: 100,
    profilePicturePath: '/img/profile/avatars/septagonsuperanimated6.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'BloomlnFulAffect',
    totalScore: 23793,
    membershipType: 2,
    classType: 1,
    rank: 101,
    profilePicturePath: '/img/profile/avatars/cc47.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'AngryBuII1',
    totalScore: 23584,
    membershipType: 2,
    classType: 1,
    rank: 102,
    profilePicturePath: '/img/profile/avatars/odst_skull_fire.png',
    style: { scale: 0 },
  },
  {
    displayName: 'capitaljays',
    totalScore: 23472,
    membershipType: 1,
    classType: 1,
    rank: 103,
    profilePicturePath: '/img/profile/avatars/cc47.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Dr_Cooper_',
    totalScore: 23468,
    membershipType: 2,
    classType: 1,
    rank: 104,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Soham',
    totalScore: 23423,
    membershipType: 4,
    classType: 2,
    rank: 105,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Xysty',
    totalScore: 23313,
    membershipType: 1,
    classType: 1,
    rank: 106,
    profilePicturePath: '/img/profile/avatars/Destiny06.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'insertfalseid',
    totalScore: 23277,
    membershipType: 2,
    classType: 1,
    rank: 107,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Shadowmosesisle',
    totalScore: 23051,
    membershipType: 2,
    classType: 0,
    rank: 108,
    profilePicturePath: '/img/profile/avatars/bungieday_21.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Sifu Snoopy',
    totalScore: 23031,
    membershipType: 1,
    classType: 1,
    rank: 109,
    profilePicturePath: '/img/profile/avatars/bungieday_13.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'jesajaaaa',
    totalScore: 22963,
    membershipType: 1,
    classType: 0,
    rank: 110,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'LargeC196',
    totalScore: 22953,
    membershipType: 2,
    classType: 0,
    rank: 111,
    profilePicturePath: '/img/profile/avatars/cc45.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Araktust',
    totalScore: 22795,
    membershipType: 2,
    classType: 1,
    rank: 112,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'xSilverHazzex3',
    totalScore: 22756,
    membershipType: 2,
    classType: 1,
    rank: 113,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 's73_squee',
    totalScore: 22540,
    membershipType: 4,
    classType: 2,
    rank: 114,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=9&1=25&2=8&3=9&fi=24&bi=0&fl=1&m=3',
    style: { scale: 0 },
  },
  {
    displayName: 'SIeepIy',
    totalScore: 22504,
    membershipType: 1,
    classType: 1,
    rank: 115,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'darkwarth23',
    totalScore: 22444,
    membershipType: 2,
    classType: 1,
    rank: 116,
    profilePicturePath: '/img/profile/avatars/cc72.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'zan',
    totalScore: 22362,
    membershipType: 2,
    classType: 2,
    rank: 117,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'REPULSEgamer ',
    totalScore: 22362,
    membershipType: 2,
    classType: 1,
    rank: 118,
    profilePicturePath: '/img/profile/avatars/e2015_15.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'yohoudini',
    totalScore: 22345,
    membershipType: 4,
    classType: 1,
    rank: 119,
    profilePicturePath: '/img/profile/avatars/bungieday_05.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'omegita636',
    totalScore: 22315,
    membershipType: 1,
    classType: 1,
    rank: 120,
    profilePicturePath: '/img/profile/avatars/cc53.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Gacixx',
    totalScore: 22235,
    membershipType: 1,
    classType: 1,
    rank: 121,
    profilePicturePath: '/img/profile/avatars/bungieday_04.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ShahuaForTheWin',
    totalScore: 22069,
    membershipType: 1,
    classType: 0,
    rank: 122,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Mizu',
    totalScore: 22055,
    membershipType: 1,
    classType: 1,
    rank: 123,
    profilePicturePath: '/img/profile/avatars/hayabusaw.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'PandasPajamas',
    totalScore: 21987,
    membershipType: 2,
    classType: 1,
    rank: 124,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'caboose5858',
    totalScore: 21921,
    membershipType: 1,
    classType: 1,
    rank: 125,
    profilePicturePath: '/img/profile/avatars/bluechief-flamethrower.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Project Moon',
    totalScore: 21903,
    membershipType: 2,
    classType: 1,
    rank: 126,
    profilePicturePath: '/img/profile/avatars/Destiny04.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Louoh',
    totalScore: 21864,
    membershipType: 4,
    classType: 0,
    rank: 127,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'DeathEnforce',
    totalScore: 21860,
    membershipType: 4,
    classType: 1,
    rank: 128,
    profilePicturePath: '/img/profile/avatars/cc54.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'MrJeske',
    totalScore: 21827,
    membershipType: 2,
    classType: 2,
    rank: 129,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'amanda_snipes',
    totalScore: 21780,
    membershipType: 4,
    classType: 2,
    rank: 130,
    profilePicturePath: '/img/profile/avatars/cc000010.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Silver',
    totalScore: 21672,
    membershipType: 2,
    classType: 1,
    rank: 131,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'taylorrae0923',
    totalScore: 21667,
    membershipType: 2,
    classType: 0,
    rank: 132,
    profilePicturePath: '/img/profile/avatars/Johnson-clear--back.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'CasshernX2',
    totalScore: 21646,
    membershipType: 1,
    classType: 2,
    rank: 133,
    profilePicturePath: '/img/profile/avatars/oonsk.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'XIl Buddha lIX',
    totalScore: 21564,
    membershipType: 1,
    classType: 1,
    rank: 134,
    profilePicturePath: '/img/profile/avatars/bungieday_08.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'AlexCrimson01',
    totalScore: 21476,
    membershipType: 2,
    classType: 2,
    rank: 135,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Kruger',
    totalScore: 21447,
    membershipType: 1,
    classType: 0,
    rank: 136,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'stealthpig03',
    totalScore: 21445,
    membershipType: 2,
    classType: 1,
    rank: 137,
    profilePicturePath: '/img/profile/avatars/bungiedayav4.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'superwario86',
    totalScore: 21399,
    membershipType: 2,
    classType: 0,
    rank: 138,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'killkenny123123',
    totalScore: 21353,
    membershipType: 1,
    classType: 0,
    rank: 139,
    profilePicturePath: '/img/profile/avatars/avatar4.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'rainbadazz',
    totalScore: 21271,
    membershipType: 2,
    classType: 1,
    rank: 140,
    profilePicturePath: '/img/profile/avatars/e2015_12.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'pteFranke',
    totalScore: 21232,
    membershipType: 2,
    classType: 1,
    rank: 141,
    profilePicturePath: '/img/profile/avatars/Destiny09.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'SYNShaman',
    totalScore: 21155,
    membershipType: 4,
    classType: 1,
    rank: 142,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Belearas',
    totalScore: 21101,
    membershipType: 4,
    classType: 0,
    rank: 143,
    profilePicturePath: '/img/profile/avatars/cc24.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ADeushe',
    totalScore: 21096,
    membershipType: 2,
    classType: 2,
    rank: 144,
    profilePicturePath: '/img/profile/avatars/cc00008.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'DYhuga04',
    totalScore: 21082,
    membershipType: 2,
    classType: 2,
    rank: 145,
    profilePicturePath: '/img/profile/avatars/cc01.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Kelxyel',
    totalScore: 21034,
    membershipType: 2,
    classType: 1,
    rank: 146,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'JakboG',
    totalScore: 21024,
    membershipType: 2,
    classType: 1,
    rank: 147,
    profilePicturePath: '/img/profile/avatars/th_BN2Avs13.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Valve',
    totalScore: 20980,
    membershipType: 4,
    classType: 2,
    rank: 148,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_01.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'recobfamily',
    totalScore: 20923,
    membershipType: 2,
    classType: 1,
    rank: 149,
    profilePicturePath: '/img/profile/avatars/cc24.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Stout',
    totalScore: 20856,
    membershipType: 4,
    classType: 1,
    rank: 150,
    profilePicturePath: '/img/profile/avatars/Destiny26.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Roxly',
    totalScore: 20815,
    membershipType: 1,
    classType: 0,
    rank: 151,
    profilePicturePath: '/img/profile/avatars/cc72.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ElDoDsItE',
    totalScore: 20809,
    membershipType: 2,
    classType: 1,
    rank: 152,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Chuck Norris',
    totalScore: 20809,
    membershipType: 2,
    classType: 0,
    rank: 153,
    profilePicturePath: '/img/profile/avatars/cc24.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'armyforlife68',
    totalScore: 20806,
    membershipType: 2,
    classType: 1,
    rank: 154,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Oborrok',
    totalScore: 20735,
    membershipType: 2,
    classType: 0,
    rank: 155,
    profilePicturePath: '/img/profile/avatars/th_Bn67.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Oefvet2006',
    totalScore: 20704,
    membershipType: 4,
    classType: 1,
    rank: 156,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'BomberWolf',
    totalScore: 20605,
    membershipType: 2,
    classType: 2,
    rank: 157,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Kishe',
    totalScore: 20601,
    membershipType: 1,
    classType: 1,
    rank: 158,
    profilePicturePath: '/img/profile/avatars/victory-a.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'pitita-82',
    totalScore: 20559,
    membershipType: 2,
    classType: 1,
    rank: 159,
    profilePicturePath: '/img/profile/avatars/cc65.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Bearhands94',
    totalScore: 20554,
    membershipType: 4,
    classType: 1,
    rank: 160,
    profilePicturePath: '/img/profile/avatars/bungieday_13.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Rekt_D',
    totalScore: 20500,
    membershipType: 2,
    classType: 1,
    rank: 161,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'SlippinSquirtle',
    totalScore: 20424,
    membershipType: 4,
    classType: 0,
    rank: 162,
    profilePicturePath: '/img/profile/avatars/cc18.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Hayzess',
    totalScore: 20413,
    membershipType: 1,
    classType: 1,
    rank: 163,
    profilePicturePath: '/img/profile/avatars/cc20.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'SSMCloud0',
    totalScore: 20373,
    membershipType: 2,
    classType: 2,
    rank: 164,
    profilePicturePath: '/img/profile/avatars/s_onfire.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Twitch Jay_Axe',
    totalScore: 20367,
    membershipType: 2,
    classType: 2,
    rank: 165,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'DarkOPS',
    totalScore: 20309,
    membershipType: 2,
    classType: 1,
    rank: 166,
    profilePicturePath: '/img/profile/avatars/themaestro.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Ix ShootTheJ xI',
    totalScore: 20272,
    membershipType: 1,
    classType: 1,
    rank: 167,
    profilePicturePath: '/img/profile/avatars/odst_skull_silver.png',
    style: { scale: 0 },
  },
  {
    displayName: 'INFamousSwiftz',
    totalScore: 20211,
    membershipType: 2,
    classType: 1,
    rank: 168,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Tezuk92',
    totalScore: 20155,
    membershipType: 2,
    classType: 2,
    rank: 169,
    profilePicturePath: '/img/profile/avatars/bungieday_16.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'ZeroNaylor',
    totalScore: 20101,
    membershipType: 1,
    classType: 1,
    rank: 170,
    profilePicturePath: '/img/profile/avatars/c0ldvengeance_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Freemason',
    totalScore: 20081,
    membershipType: 1,
    classType: 2,
    rank: 171,
    profilePicturePath: '/img/profile/avatars/avatar12.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'wyzzy90',
    totalScore: 20052,
    membershipType: 2,
    classType: 1,
    rank: 172,
    profilePicturePath: '/img/profile/avatars/avatar4.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'UD Valiance',
    totalScore: 20022,
    membershipType: 1,
    classType: 1,
    rank: 173,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Cokkymonster',
    totalScore: 20013,
    membershipType: 2,
    classType: 2,
    rank: 174,
    profilePicturePath: '/img/profile/avatars/cc68.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Lauren Jamie',
    totalScore: 19956,
    membershipType: 2,
    classType: 1,
    rank: 175,
    profilePicturePath: '/img/profile/avatars/cc19.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'KannZaehlen1357',
    totalScore: 19948,
    membershipType: 2,
    classType: 0,
    rank: 176,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'OperationBOAR',
    totalScore: 19898,
    membershipType: 1,
    classType: 1,
    rank: 177,
    profilePicturePath: '/img/profile/avatars/seventhcolumn7.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'StephanieRae93',
    totalScore: 19882,
    membershipType: 4,
    classType: 2,
    rank: 178,
    profilePicturePath: '/img/profile/avatars/avatar3.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'iBlaxed',
    totalScore: 19867,
    membershipType: 1,
    classType: 1,
    rank: 179,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_20.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'sirloganmoore1',
    totalScore: 19861,
    membershipType: 2,
    classType: 1,
    rank: 180,
    profilePicturePath: '/img/profile/avatars/avatar3.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Willou_7',
    totalScore: 19855,
    membershipType: 2,
    classType: 1,
    rank: 181,
    profilePicturePath: '/img/profile/avatars/the_rip_saw_avtar.gif.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Kaleidoscoped',
    totalScore: 19795,
    membershipType: 1,
    classType: 0,
    rank: 182,
    profilePicturePath: '/img/profile/avatars/avatar27.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Essex',
    totalScore: 19771,
    membershipType: 4,
    classType: 1,
    rank: 183,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Exi_AnTHRaX',
    totalScore: 19758,
    membershipType: 2,
    classType: 1,
    rank: 184,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'JRZERO49',
    totalScore: 19755,
    membershipType: 2,
    classType: 1,
    rank: 185,
    profilePicturePath: '/img/profile/avatars/cc00008.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'F1reF0x',
    totalScore: 19736,
    membershipType: 2,
    classType: 2,
    rank: 186,
    profilePicturePath: '/img/profile/avatars/cc51.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Nicholas22601',
    totalScore: 19660,
    membershipType: 2,
    classType: 1,
    rank: 187,
    profilePicturePath: '/img/profile/avatars/avatar27.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'PhaoHD',
    totalScore: 19645,
    membershipType: 1,
    classType: 1,
    rank: 188,
    profilePicturePath: '/img/profile/avatars/cc13.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'TwistedTurbo',
    totalScore: 19625,
    membershipType: 2,
    classType: 1,
    rank: 189,
    profilePicturePath: '/img/profile/avatars/cc54.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'KiahBby',
    totalScore: 19392,
    membershipType: 2,
    classType: 0,
    rank: 190,
    profilePicturePath: '/img/profile/avatars/s_Kat_pwn.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'ice cube3009',
    totalScore: 19370,
    membershipType: 2,
    classType: 2,
    rank: 191,
    profilePicturePath: '/img/profile/avatars/cc34.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Ceewhy17',
    totalScore: 19366,
    membershipType: 2,
    classType: 0,
    rank: 192,
    profilePicturePath: '/img/profile/avatars/whitespartandualsmgs.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Some No Name',
    totalScore: 19337,
    membershipType: 4,
    classType: 1,
    rank: 193,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'Bocker',
    totalScore: 19329,
    membershipType: 2,
    classType: 2,
    rank: 194,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Dubwolfer',
    totalScore: 19279,
    membershipType: 2,
    classType: 2,
    rank: 195,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_02.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'SelfishToaster',
    totalScore: 19185,
    membershipType: 2,
    classType: 1,
    rank: 196,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
    style: { scale: 0 },
  },
  {
    displayName: 'HENRECON',
    totalScore: 19169,
    membershipType: 2,
    classType: 1,
    rank: 197,
    profilePicturePath: '/img/profile/avatars/cc000016.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'colorlessmind',
    totalScore: 19095,
    membershipType: 2,
    classType: 1,
    rank: 198,
    profilePicturePath: '/img/profile/avatars/cc47.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'Kaizer2785',
    totalScore: 19081,
    membershipType: 2,
    classType: 0,
    rank: 199,
    profilePicturePath: '/img/profile/avatars/boarding_left.jpg',
    style: { scale: 0 },
  },
  {
    displayName: 'BrodySD',
    totalScore: 19011,
    membershipType: 2,
    classType: 1,
    rank: 200,
    profilePicturePath: '/img/profile/avatars/shadowtiger_avatar.gif',
    style: { scale: 0 },
  },
];

export default week1;
