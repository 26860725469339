import React from 'react';

import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header';
import HeroCompetition from '../components/HeroCompetition/HeroCompetition';
import Leaderboard from '../components/Leaderboard/Leaderboard';
import Social from '../components/Social/Social';
import Footer from '../components/Footer/Footer';

import SEO from '../components/seo';

const SecondPage = props => (
  <Layout>
    <SEO title="Competition" />
    <Header pathname={props.location.pathname} />
    <HeroCompetition />
    <Leaderboard />
    <Social />
    <Footer />
  </Layout>
);

export default SecondPage;
