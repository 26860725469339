const week4 = [
  {
    displayName: 'JOiNMYHiTLiSTx',
    totalScore: 161142,
    membershipType: 2,
    classType: 1,
    rank: 1,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Darkhar',
    totalScore: 153065,
    membershipType: 2,
    classType: 1,
    rank: 2,
    profilePicturePath: '/img/profile/avatars/marathon5.gif',
  },
  {
    displayName: 'crazycoop40',
    totalScore: 136268,
    membershipType: 2,
    classType: 0,
    rank: 3,
    profilePicturePath: '/img/profile/avatars/bungieday_12.jpg',
  },
  {
    displayName: 'SIMSTER4LIFE',
    totalScore: 134943,
    membershipType: 2,
    classType: 1,
    rank: 4,
    profilePicturePath: '/img/profile/avatars/cc20.jpg',
  },
  {
    displayName: 'JLPERRY16',
    totalScore: 131103,
    membershipType: 2,
    classType: 0,
    rank: 5,
    profilePicturePath: '/img/profile/avatars/Destiny11.jpg',
  },
  {
    displayName: 'j_t_s_k',
    totalScore: 121155,
    membershipType: 2,
    classType: 1,
    rank: 6,
    profilePicturePath: '/img/profile/avatars/cc47.jpg',
  },
  {
    displayName: 'Voice of Osiris',
    totalScore: 120887,
    membershipType: 1,
    classType: 1,
    rank: 7,
    profilePicturePath: '/img/profile/avatars/dolbex_avatar.gif',
  },
  {
    displayName: 'willah g',
    totalScore: 118571,
    membershipType: 1,
    classType: 2,
    rank: 8,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
  },
  {
    displayName: 'on3sh0t2345',
    totalScore: 116085,
    membershipType: 1,
    classType: 2,
    rank: 9,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'CorneTTimeBRK',
    totalScore: 114988,
    membershipType: 1,
    classType: 2,
    rank: 10,
    profilePicturePath: '/img/profile/avatars/cc24.jpg',
  },
  {
    displayName: 'ZachJ',
    totalScore: 114969,
    membershipType: 2,
    classType: 2,
    rank: 11,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'Crimson_Blade666',
    totalScore: 108950,
    membershipType: 4,
    classType: 0,
    rank: 12,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'SoCalBear',
    totalScore: 95733,
    membershipType: 2,
    classType: 2,
    rank: 13,
    profilePicturePath: '/img/profile/avatars/s_AVATAR.jpg',
  },
  {
    displayName: 'D2ISBAD',
    totalScore: 88666,
    membershipType: 1,
    classType: 0,
    rank: 14,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'OperationBOAR',
    totalScore: 77128,
    membershipType: 1,
    classType: 1,
    rank: 15,
    profilePicturePath: '/img/profile/avatars/seventhcolumn7.gif',
  },
  {
    displayName: 'Darkspeeder5',
    totalScore: 75699,
    membershipType: 2,
    classType: 1,
    rank: 16,
    profilePicturePath: '/img/profile/avatars/cc000013.jpg',
  },
  {
    displayName: 'ZadrianKV',
    totalScore: 70581,
    membershipType: 1,
    classType: 1,
    rank: 17,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'SIeepIy',
    totalScore: 65714,
    membershipType: 1,
    classType: 1,
    rank: 18,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Louoh',
    totalScore: 64636,
    membershipType: 4,
    classType: 0,
    rank: 19,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'TripleTbrothers',
    totalScore: 63346,
    membershipType: 2,
    classType: 0,
    rank: 20,
    profilePicturePath: '/img/profile/avatars/bungieday_08.jpg',
  },
  {
    displayName: 'Donnerdog',
    totalScore: 62072,
    membershipType: 4,
    classType: 1,
    rank: 21,
    profilePicturePath: '/img/profile/avatars/cc73.jpg',
  },
  {
    displayName: 'MEGA_REBEL_17',
    totalScore: 59514,
    membershipType: 4,
    classType: 1,
    rank: 22,
    profilePicturePath: '/img/profile/avatars/bungieday_13.jpg',
  },
  {
    displayName: 'Elegantgun',
    totalScore: 58949,
    membershipType: 4,
    classType: 1,
    rank: 23,
    profilePicturePath: '/img/profile/avatars/cc18.jpg',
  },
  {
    displayName: 'ImpulseA27',
    totalScore: 58344,
    membershipType: 4,
    classType: 1,
    rank: 24,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_45.jpg',
  },
  {
    displayName: 'ByeByeFac3',
    totalScore: 57611,
    membershipType: 1,
    classType: 2,
    rank: 25,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Xysty',
    totalScore: 54513,
    membershipType: 1,
    classType: 1,
    rank: 26,
    profilePicturePath: '/img/profile/avatars/Destiny06.jpg',
  },
  {
    displayName: 'tw0sh0t2345',
    totalScore: 50101,
    membershipType: 1,
    classType: 2,
    rank: 27,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Dr_Cooper_',
    totalScore: 49976,
    membershipType: 2,
    classType: 1,
    rank: 28,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
  },
  {
    displayName: 'Angelbabyluv',
    totalScore: 49921,
    membershipType: 2,
    classType: 2,
    rank: 29,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'deamonprince13',
    totalScore: 49526,
    membershipType: 2,
    classType: 2,
    rank: 30,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_29.jpg',
  },
  {
    displayName: 'ToxicVaporz0069',
    totalScore: 48182,
    membershipType: 1,
    classType: 0,
    rank: 31,
    profilePicturePath: '/img/profile/avatars/cc60.jpg',
  },
  {
    displayName: 'EagleScope',
    totalScore: 48038,
    membershipType: 2,
    classType: 2,
    rank: 32,
    profilePicturePath: '/img/profile/avatars/sevenz.gif',
  },
  {
    displayName: 'Sunoe',
    totalScore: 47448,
    membershipType: 1,
    classType: 1,
    rank: 33,
    profilePicturePath: '/img/profile/avatars/cc23.jpg',
  },
  {
    displayName: 'Critical6aming',
    totalScore: 47020,
    membershipType: 1,
    classType: 0,
    rank: 34,
    profilePicturePath: '/img/profile/avatars/cc00009.jpg',
  },
  {
    displayName: 'Djeterfan',
    totalScore: 46453,
    membershipType: 2,
    classType: 1,
    rank: 35,
    profilePicturePath: '/img/profile/avatars/7isdarker.gif',
  },
  {
    displayName: 'Regulator5678',
    totalScore: 45382,
    membershipType: 1,
    classType: 1,
    rank: 36,
    profilePicturePath: '/img/profile/avatars/cc32.jpg',
  },
  {
    displayName: 'DesignedSkul',
    totalScore: 43919,
    membershipType: 2,
    classType: 1,
    rank: 37,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'GrayZXA',
    totalScore: 43516,
    membershipType: 2,
    classType: 1,
    rank: 38,
    profilePicturePath: '/img/profile/avatars/avatar18.jpg',
  },
  {
    displayName: 'stink finger',
    totalScore: 43405,
    membershipType: 1,
    classType: 1,
    rank: 39,
    profilePicturePath: '/img/profile/avatars/cc15.jpg',
  },
  {
    displayName: 'luis937',
    totalScore: 42720,
    membershipType: 2,
    classType: 1,
    rank: 40,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'dl oreo lb',
    totalScore: 42159,
    membershipType: 1,
    classType: 1,
    rank: 41,
    profilePicturePath: '/img/profile/avatars/avatar27.jpg',
  },
  {
    displayName: 'GhostKingGorilla',
    totalScore: 41826,
    membershipType: 2,
    classType: 2,
    rank: 42,
    profilePicturePath: '/img/profile/avatars/Destiny04.jpg',
  },
  {
    displayName: 'drpepper ninja1',
    totalScore: 41430,
    membershipType: 1,
    classType: 1,
    rank: 43,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_41.jpg',
  },
  {
    displayName: 'taylorrae0923',
    totalScore: 40595,
    membershipType: 2,
    classType: 0,
    rank: 44,
    profilePicturePath: '/img/profile/avatars/Johnson-clear--back.gif',
  },
  {
    displayName: 'Triz',
    totalScore: 39275,
    membershipType: 4,
    classType: 0,
    rank: 45,
    profilePicturePath: '/img/profile/avatars/cc04.jpg',
  },
  {
    displayName: 'ladyvirus69',
    totalScore: 38607,
    membershipType: 1,
    classType: 2,
    rank: 46,
    profilePicturePath: '/img/profile/avatars/cc000012.jpg',
  },
  {
    displayName: 'theycallmeu',
    totalScore: 38535,
    membershipType: 2,
    classType: 2,
    rank: 47,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'PUNZHD',
    totalScore: 37949,
    membershipType: 4,
    classType: 1,
    rank: 48,
    profilePicturePath: '/img/profile/avatars/cc000011.jpg',
  },
  {
    displayName: 'wiikidsly',
    totalScore: 37877,
    membershipType: 2,
    classType: 1,
    rank: 49,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_44.jpg',
  },
  {
    displayName: 'mchanke',
    totalScore: 37833,
    membershipType: 2,
    classType: 1,
    rank: 50,
    profilePicturePath: '/img/profile/avatars/avatar10.jpg',
  },
  {
    displayName: 'trudeaulove',
    totalScore: 37597,
    membershipType: 2,
    classType: 2,
    rank: 51,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'shisha bauer123',
    totalScore: 37565,
    membershipType: 2,
    classType: 1,
    rank: 52,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_34.jpg',
  },
  {
    displayName: 'Sry4U-1989',
    totalScore: 37558,
    membershipType: 2,
    classType: 1,
    rank: 53,
    profilePicturePath: '/img/profile/avatars/Destiny16.jpg',
  },
  {
    displayName: 'NickC_6069',
    totalScore: 37050,
    membershipType: 2,
    classType: 2,
    rank: 54,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_16.jpg',
  },
  {
    displayName: 'Kruger',
    totalScore: 36603,
    membershipType: 1,
    classType: 0,
    rank: 55,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'aron141',
    totalScore: 36366,
    membershipType: 2,
    classType: 1,
    rank: 56,
    profilePicturePath: '/img/profile/avatars/bungieday_13.jpg',
  },
  {
    displayName: 'Hakunamataco',
    totalScore: 36125,
    membershipType: 2,
    classType: 2,
    rank: 57,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'XIl Buddha lIX',
    totalScore: 36028,
    membershipType: 1,
    classType: 1,
    rank: 58,
    profilePicturePath: '/img/profile/avatars/bungieday_08.jpg',
  },
  {
    displayName: 'zan',
    totalScore: 35418,
    membershipType: 2,
    classType: 2,
    rank: 59,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
  },
  {
    displayName: 'StephanieRae93',
    totalScore: 35361,
    membershipType: 4,
    classType: 2,
    rank: 60,
    profilePicturePath: '/img/profile/avatars/avatar3.jpg',
  },
  {
    displayName: 'SIN7DIABLO',
    totalScore: 35189,
    membershipType: 1,
    classType: 0,
    rank: 61,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'GUNSABLAZ1NG',
    totalScore: 35150,
    membershipType: 1,
    classType: 0,
    rank: 62,
    profilePicturePath: '/img/profile/avatars/bungieday_24.jpg',
  },
  {
    displayName: 'l TheIronLord l',
    totalScore: 34981,
    membershipType: 1,
    classType: 1,
    rank: 63,
    profilePicturePath: '/img/profile/avatars/Destiny04.jpg',
  },
  {
    displayName: 'P3NG',
    totalScore: 34924,
    membershipType: 4,
    classType: 0,
    rank: 64,
    profilePicturePath: '/img/profile/avatars/7isdarker.gif',
  },
  {
    displayName: 'Kisaragibot',
    totalScore: 34647,
    membershipType: 1,
    classType: 1,
    rank: 65,
    profilePicturePath: '/img/profile/avatars/cc000010.jpg',
  },
  {
    displayName: 'LordEdition',
    totalScore: 34644,
    membershipType: 2,
    classType: 1,
    rank: 66,
    profilePicturePath: '/img/profile/avatars/avatar4.jpg',
  },
  {
    displayName: 'kcbeans76',
    totalScore: 34109,
    membershipType: 2,
    classType: 0,
    rank: 67,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'breakneck420',
    totalScore: 33703,
    membershipType: 2,
    classType: 0,
    rank: 68,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Rawdog_710',
    totalScore: 33605,
    membershipType: 2,
    classType: 1,
    rank: 69,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'SergeantMajor24',
    totalScore: 33542,
    membershipType: 1,
    classType: 2,
    rank: 70,
    profilePicturePath: '/img/profile/avatars/cc000016.jpg',
  },
  {
    displayName: 'Sahvek',
    totalScore: 33329,
    membershipType: 4,
    classType: 1,
    rank: 71,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'ChaosAngel2112',
    totalScore: 33161,
    membershipType: 1,
    classType: 1,
    rank: 72,
    profilePicturePath: '/img/profile/avatars/e2015_14.jpg',
  },
  {
    displayName: 'Grug',
    totalScore: 33080,
    membershipType: 1,
    classType: 1,
    rank: 73,
    profilePicturePath: '/img/profile/avatars/Destiny26.jpg',
  },
  {
    displayName: 'RX13',
    totalScore: 32730,
    membershipType: 4,
    classType: 2,
    rank: 74,
    profilePicturePath: '/img/profile/avatars/cc18.jpg',
  },
  {
    displayName: 'Kush Kittty',
    totalScore: 32587,
    membershipType: 1,
    classType: 1,
    rank: 75,
    profilePicturePath: '/img/profile/avatars/cc24.jpg',
  },
  {
    displayName: 'baronandi',
    totalScore: 32507,
    membershipType: 2,
    classType: 0,
    rank: 76,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Scorpyo x',
    totalScore: 32486,
    membershipType: 1,
    classType: 1,
    rank: 77,
    profilePicturePath: '/img/profile/avatars/e2015_14.jpg',
  },
  {
    displayName: 'OGGodLee',
    totalScore: 32462,
    membershipType: 2,
    classType: 1,
    rank: 78,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Skeletoes Runr',
    totalScore: 32277,
    membershipType: 1,
    classType: 2,
    rank: 79,
    profilePicturePath: '/img/profile/avatars/e2015_12.jpg',
  },
  {
    displayName: 'm3d1cAt10n',
    totalScore: 32084,
    membershipType: 2,
    classType: 1,
    rank: 80,
    profilePicturePath: '/img/profile/avatars/untitled2vk3.gif',
  },
  {
    displayName: 'xXLoVeMyScUfXx',
    totalScore: 32083,
    membershipType: 2,
    classType: 2,
    rank: 81,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'Avril95',
    totalScore: 31983,
    membershipType: 2,
    classType: 0,
    rank: 82,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
  },
  {
    displayName: 'theycallmemopp',
    totalScore: 31981,
    membershipType: 2,
    classType: 1,
    rank: 83,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'adragonasfire',
    totalScore: 31783,
    membershipType: 2,
    classType: 1,
    rank: 84,
    profilePicturePath: '/img/profile/avatars/e2015_15.jpg',
  },
  {
    displayName: 'XxThe_Rookie99xX',
    totalScore: 31751,
    membershipType: 2,
    classType: 0,
    rank: 85,
    profilePicturePath: '/img/profile/avatars/shield1.jpg',
  },
  {
    displayName: 'KillerDonTwan',
    totalScore: 31560,
    membershipType: 1,
    classType: 2,
    rank: 86,
    profilePicturePath: '/img/profile/avatars/logo-skull2.gif',
  },
  {
    displayName: 'NeZu',
    totalScore: 31550,
    membershipType: 4,
    classType: 1,
    rank: 87,
    profilePicturePath: '/img/profile/avatars/7thCol.gif',
  },
  {
    displayName: 'DMHCook',
    totalScore: 31519,
    membershipType: 1,
    classType: 2,
    rank: 88,
    profilePicturePath: '/img/profile/avatars/admin/moderator_dmhcook.gif',
  },
  {
    displayName: 'ThePurplest',
    totalScore: 31338,
    membershipType: 1,
    classType: 1,
    rank: 89,
    profilePicturePath: '/img/profile/avatars/bungieday_02.jpg',
  },
  {
    displayName: 'KingothaNorth',
    totalScore: 31311,
    membershipType: 2,
    classType: 1,
    rank: 90,
    profilePicturePath: '/img/profile/avatars/a_reach_9.jpg',
  },
  {
    displayName: 'Mr 3 ManFred 3',
    totalScore: 31289,
    membershipType: 1,
    classType: 1,
    rank: 91,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'AceOneZero',
    totalScore: 31117,
    membershipType: 2,
    classType: 1,
    rank: 92,
    profilePicturePath: '/img/profile/avatars/bungieday_24.jpg',
  },
  {
    displayName: 'Tomi2801',
    totalScore: 31040,
    membershipType: 2,
    classType: 2,
    rank: 93,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'bizzy817',
    totalScore: 31015,
    membershipType: 2,
    classType: 1,
    rank: 94,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'TMEGO3',
    totalScore: 30966,
    membershipType: 2,
    classType: 0,
    rank: 95,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'classi641',
    totalScore: 30880,
    membershipType: 2,
    classType: 2,
    rank: 96,
    profilePicturePath: '/img/profile/avatars/jolly_roger.jpg',
  },
  {
    displayName: 'J_r0ckx_x',
    totalScore: 30867,
    membershipType: 2,
    classType: 2,
    rank: 97,
    profilePicturePath: '/img/profile/avatars/cc72.jpg',
  },
  {
    displayName: 'Hayzess',
    totalScore: 30832,
    membershipType: 1,
    classType: 1,
    rank: 98,
    profilePicturePath: '/img/profile/avatars/cc20.jpg',
  },
  {
    displayName: 'xXHendriKX',
    totalScore: 30763,
    membershipType: 2,
    classType: 2,
    rank: 99,
    profilePicturePath: '/img/profile/avatars/cc57.jpg',
  },
  {
    displayName: 'JRZERO49',
    totalScore: 30653,
    membershipType: 2,
    classType: 1,
    rank: 100,
    profilePicturePath: '/img/profile/avatars/cc00008.jpg',
  },
  {
    displayName: 'Quickdraw_4',
    totalScore: 30545,
    membershipType: 4,
    classType: 2,
    rank: 101,
    profilePicturePath: '/img/profile/avatars/cc21.jpg',
  },
  {
    displayName: 'TonyWanKnobi',
    totalScore: 30349,
    membershipType: 1,
    classType: 1,
    rank: 102,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_26.jpg',
  },
  {
    displayName: 'Epitoc',
    totalScore: 30089,
    membershipType: 4,
    classType: 0,
    rank: 103,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'DrewskyStomp',
    totalScore: 30003,
    membershipType: 4,
    classType: 1,
    rank: 104,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_41.jpg',
  },
  {
    displayName: 'Death Hall',
    totalScore: 29897,
    membershipType: 2,
    classType: 1,
    rank: 105,
    profilePicturePath: '/img/profile/avatars/bungieday_04.jpg',
  },
  {
    displayName: 'ZkMushroom',
    totalScore: 29836,
    membershipType: 2,
    classType: 1,
    rank: 106,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_05.jpg',
  },
  {
    displayName: 'Stackzzz_18',
    totalScore: 29743,
    membershipType: 2,
    classType: 1,
    rank: 107,
    profilePicturePath: '/img/profile/avatars/Destiny21.jpg',
  },
  {
    displayName: 'Icetransam2000',
    totalScore: 29720,
    membershipType: 2,
    classType: 0,
    rank: 108,
    profilePicturePath: '/img/profile/avatars/th_Bn58.jpg',
  },
  {
    displayName: 'Blazespot',
    totalScore: 29700,
    membershipType: 4,
    classType: 0,
    rank: 109,
    profilePicturePath: '/img/profile/avatars/cc00003.jpg',
  },
  {
    displayName: 'N8han',
    totalScore: 29700,
    membershipType: 4,
    classType: 1,
    rank: 110,
    profilePicturePath: '/img/profile/avatars/huntinghunter.jpg',
  },
  {
    displayName: 'IAM2501',
    totalScore: 29648,
    membershipType: 2,
    classType: 2,
    rank: 111,
    profilePicturePath: '/img/profile/avatars/bungieday_14.jpg',
  },
  {
    displayName: 'BillyOC',
    totalScore: 29626,
    membershipType: 4,
    classType: 0,
    rank: 112,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'TwoLoud21',
    totalScore: 29531,
    membershipType: 2,
    classType: 1,
    rank: 113,
    profilePicturePath: '/img/profile/avatars/cc22.jpg',
  },
  {
    displayName: 'UD Valiance',
    totalScore: 29420,
    membershipType: 1,
    classType: 1,
    rank: 114,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'NeKo_GrimReaper',
    totalScore: 29384,
    membershipType: 2,
    classType: 2,
    rank: 115,
    profilePicturePath: '/img/profile/avatars/cc26.jpg',
  },
  {
    displayName: 'Tehrminatr',
    totalScore: 29322,
    membershipType: 4,
    classType: 0,
    rank: 116,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'PotatoeGuru',
    totalScore: 29309,
    membershipType: 2,
    classType: 2,
    rank: 117,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_19.jpg',
  },
  {
    displayName: 'SUP3RRAY',
    totalScore: 29191,
    membershipType: 1,
    classType: 0,
    rank: 118,
    profilePicturePath: '/img/profile/avatars/e2015_11.jpg',
  },
  {
    displayName: 'AwesomeJN2015',
    totalScore: 29170,
    membershipType: 2,
    classType: 1,
    rank: 119,
    profilePicturePath: '/img/profile/avatars/avatar29.jpg',
  },
  {
    displayName: 'its-Fanatic',
    totalScore: 29139,
    membershipType: 2,
    classType: 1,
    rank: 120,
    profilePicturePath: '/img/profile/avatars/cc23.jpg',
  },
  {
    displayName: 'mrcrinckles',
    totalScore: 29122,
    membershipType: 2,
    classType: 0,
    rank: 121,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'VinDaddy1208',
    totalScore: 28991,
    membershipType: 1,
    classType: 2,
    rank: 122,
    profilePicturePath: '/img/profile/avatars/jolly_roger_gears.jpg',
  },
  {
    displayName: 'SoulzRevived',
    totalScore: 28990,
    membershipType: 2,
    classType: 2,
    rank: 123,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'DietWokeZero',
    totalScore: 28870,
    membershipType: 4,
    classType: 2,
    rank: 124,
    profilePicturePath: '/img/profile/avatars/e2015_11.jpg',
  },
  {
    displayName: 'Oefvet2006',
    totalScore: 28839,
    membershipType: 4,
    classType: 1,
    rank: 125,
    profilePicturePath: '/img/profile/avatars/bungieday_09.jpg',
  },
  {
    displayName: 'Playerstephen45',
    totalScore: 28839,
    membershipType: 2,
    classType: 0,
    rank: 126,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Ix ShootTheJ xI',
    totalScore: 28833,
    membershipType: 1,
    classType: 1,
    rank: 127,
    profilePicturePath: '/img/profile/avatars/odst_skull_silver.png',
  },
  {
    displayName: 'Wednesday',
    totalScore: 28781,
    membershipType: 4,
    classType: 1,
    rank: 128,
    profilePicturePath: '/img/profile/avatars/bungieday_07.jpg',
  },
  {
    displayName: 'slicknic96',
    totalScore: 28612,
    membershipType: 1,
    classType: 2,
    rank: 129,
    profilePicturePath: '/img/profile/avatars/bungieday_06.jpg',
  },
  {
    displayName: 'stalop',
    totalScore: 28596,
    membershipType: 2,
    classType: 0,
    rank: 130,
    profilePicturePath: '/img/profile/avatars/soffishoutofwater.gif',
  },
  {
    displayName: 'Romannavi',
    totalScore: 28498,
    membershipType: 2,
    classType: 1,
    rank: 131,
    profilePicturePath: '/img/profile/avatars/cc21.jpg',
  },
  {
    displayName: 'Profanendobscene',
    totalScore: 28313,
    membershipType: 2,
    classType: 1,
    rank: 132,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'Shed I am',
    totalScore: 28218,
    membershipType: 1,
    classType: 2,
    rank: 133,
    profilePicturePath: '/img/profile/avatars/cc54.jpg',
  },
  {
    displayName: 'PandasPajamas',
    totalScore: 28214,
    membershipType: 2,
    classType: 1,
    rank: 134,
    profilePicturePath: '/img/profile/avatars/cc71.jpg',
  },
  {
    displayName: 'oxzzy',
    totalScore: 28123,
    membershipType: 2,
    classType: 0,
    rank: 135,
    profilePicturePath: '/img/profile/avatars/odst_skull_3_2.png',
  },
  {
    displayName: 'NekoBlackGem',
    totalScore: 28088,
    membershipType: 2,
    classType: 2,
    rank: 136,
    profilePicturePath: '/img/profile/avatars/th_BN2Avs15.jpg',
  },
  {
    displayName: 'Master Arikan ',
    totalScore: 28062,
    membershipType: 4,
    classType: 2,
    rank: 137,
    profilePicturePath: '/img/profile/avatars/odst_dutch.gif',
  },
  {
    displayName: 'CoolCheese',
    totalScore: 28002,
    membershipType: 4,
    classType: 0,
    rank: 138,
    profilePicturePath: '/img/profile/avatars/cc54.jpg',
  },
  {
    displayName: 'KareBearr JM',
    totalScore: 27953,
    membershipType: 1,
    classType: 1,
    rank: 139,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'Brekuza',
    totalScore: 27915,
    membershipType: 1,
    classType: 1,
    rank: 140,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'xx2GunzzSr',
    totalScore: 27804,
    membershipType: 2,
    classType: 2,
    rank: 141,
    profilePicturePath: '/img/profile/avatars/avatar9.jpg',
  },
  {
    displayName: 'splinter2078',
    totalScore: 27800,
    membershipType: 2,
    classType: 1,
    rank: 142,
    profilePicturePath: '/img/profile/avatars/cc20.jpg',
  },
  {
    displayName: 'Skorpion8997',
    totalScore: 27759,
    membershipType: 2,
    classType: 1,
    rank: 143,
    profilePicturePath: '/img/profile/avatars/bungiedayav2.jpg',
  },
  {
    displayName: 'SCORPION-AJ-92',
    totalScore: 27720,
    membershipType: 2,
    classType: 0,
    rank: 144,
    profilePicturePath: '/img/profile/avatars/e2015_15.jpg',
  },
  {
    displayName: 'Morgamella',
    totalScore: 27707,
    membershipType: 1,
    classType: 1,
    rank: 145,
    profilePicturePath: '/img/profile/avatars/cc24.jpg',
  },
  {
    displayName: 'DMHSutter',
    totalScore: 27691,
    membershipType: 1,
    classType: 1,
    rank: 146,
    profilePicturePath: '/img/profile/avatars/admin/DMHSutter_avatar.gif',
  },
  {
    displayName: 'GoetterStein',
    totalScore: 27648,
    membershipType: 2,
    classType: 2,
    rank: 147,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Willou_7',
    totalScore: 27549,
    membershipType: 2,
    classType: 1,
    rank: 148,
    profilePicturePath: '/img/profile/avatars/the_rip_saw_avtar.gif.gif',
  },
  {
    displayName: 'Flap',
    totalScore: 27510,
    membershipType: 4,
    classType: 1,
    rank: 149,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'TheScooby',
    totalScore: 27380,
    membershipType: 1,
    classType: 1,
    rank: 150,
    profilePicturePath: '/img/profile/avatars/cc72.jpg',
  },
  {
    displayName: 'LuckyLuxray',
    totalScore: 27371,
    membershipType: 1,
    classType: 2,
    rank: 151,
    profilePicturePath: '/img/profile/avatars/cc23.jpg',
  },
  {
    displayName: 'Killer Robots',
    totalScore: 27356,
    membershipType: 4,
    classType: 0,
    rank: 152,
    profilePicturePath:
      'https://halo.bungie.net/Stats/emblem.ashx?s=90&0=0&1=2&2=3&3=6&fi=13&bi=28&fl=0&m=1',
  },
  {
    displayName: 'MA5T3RCH13F82',
    totalScore: 27330,
    membershipType: 1,
    classType: 0,
    rank: 153,
    profilePicturePath: '/img/profile/avatars/bungieday_14.jpg',
  },
  {
    displayName: 'Kelxyel',
    totalScore: 27300,
    membershipType: 2,
    classType: 1,
    rank: 154,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'wubber567',
    totalScore: 27291,
    membershipType: 2,
    classType: 1,
    rank: 155,
    profilePicturePath: '/img/profile/avatars/cc64.jpg',
  },
  {
    displayName: 'gkl220',
    totalScore: 27243,
    membershipType: 1,
    classType: 0,
    rank: 156,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'I00k',
    totalScore: 27237,
    membershipType: 1,
    classType: 2,
    rank: 157,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'HorizonFracture',
    totalScore: 27221,
    membershipType: 2,
    classType: 1,
    rank: 158,
    profilePicturePath: '/img/profile/avatars/cc25.jpg',
  },
  {
    displayName: 'NTowers',
    totalScore: 27185,
    membershipType: 2,
    classType: 1,
    rank: 159,
    profilePicturePath: '/img/profile/avatars/cc74.jpg',
  },
  {
    displayName: 'Keratymull',
    totalScore: 27176,
    membershipType: 4,
    classType: 2,
    rank: 160,
    profilePicturePath: '/img/profile/avatars/cc50.jpg',
  },
  {
    displayName: 'Ferchi',
    totalScore: 27170,
    membershipType: 4,
    classType: 1,
    rank: 161,
    profilePicturePath: '/img/profile/avatars/cc43.jpg',
  },
  {
    displayName: 'Techmaturgic',
    totalScore: 27092,
    membershipType: 4,
    classType: 0,
    rank: 162,
    profilePicturePath: '/img/profile/avatars/cc14.jpg',
  },
  {
    displayName: 'Draco',
    totalScore: 27065,
    membershipType: 2,
    classType: 1,
    rank: 163,
    profilePicturePath: '/img/profile/avatars/bungieday_05.jpg',
  },
  {
    displayName: 'DP_Justin',
    totalScore: 27011,
    membershipType: 2,
    classType: 1,
    rank: 164,
    profilePicturePath: '/img/profile/avatars/s_tbagjx8.gif',
  },
  {
    displayName: 'airsickflea3401',
    totalScore: 27001,
    membershipType: 1,
    classType: 2,
    rank: 165,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_41.jpg',
  },
  {
    displayName: 'CPT_STEVO_o',
    totalScore: 26965,
    membershipType: 2,
    classType: 2,
    rank: 166,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Bluepurs',
    totalScore: 26962,
    membershipType: 1,
    classType: 1,
    rank: 167,
    profilePicturePath: '/img/profile/avatars/skull1w.gif',
  },
  {
    displayName: 'ScottOnXbox2001',
    totalScore: 26927,
    membershipType: 1,
    classType: 2,
    rank: 168,
    profilePicturePath: '/img/profile/avatars/bungiedayav4.jpg',
  },
  {
    displayName: 'BillyMcfloy',
    totalScore: 26822,
    membershipType: 4,
    classType: 0,
    rank: 169,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Repaired Leaks',
    totalScore: 26744,
    membershipType: 1,
    classType: 1,
    rank: 170,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_44.jpg',
  },
  {
    displayName: 'Gnarwhai',
    totalScore: 26728,
    membershipType: 4,
    classType: 2,
    rank: 171,
    profilePicturePath: '/img/profile/avatars/cc000010.jpg',
  },
  {
    displayName: 'Giant',
    totalScore: 26683,
    membershipType: 4,
    classType: 1,
    rank: 172,
    profilePicturePath: '/img/profile/avatars/cc32.jpg',
  },
  {
    displayName: 'flashcat85',
    totalScore: 26634,
    membershipType: 1,
    classType: 2,
    rank: 173,
    profilePicturePath: '/img/profile/avatars/avatar29.jpg',
  },
  {
    displayName: 'KingSeryoga21',
    totalScore: 26514,
    membershipType: 2,
    classType: 1,
    rank: 174,
    profilePicturePath: '/img/profile/avatars/cc66.jpg',
  },
  {
    displayName: 'Blukip',
    totalScore: 26458,
    membershipType: 1,
    classType: 1,
    rank: 175,
    profilePicturePath: '/img/profile/avatars/themaestro.jpg',
  },
  {
    displayName: 'Sergeant Gouge',
    totalScore: 26412,
    membershipType: 1,
    classType: 1,
    rank: 176,
    profilePicturePath: '/img/profile/avatars/bungiedayav3.jpg',
  },
  {
    displayName: 'Xylo_Void',
    totalScore: 26359,
    membershipType: 2,
    classType: 1,
    rank: 177,
    profilePicturePath: '/img/profile/avatars/Destiny16.jpg',
  },
  {
    displayName: 'DarthAbbadon',
    totalScore: 26346,
    membershipType: 4,
    classType: 2,
    rank: 178,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'fablek',
    totalScore: 26336,
    membershipType: 2,
    classType: 1,
    rank: 179,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'spentwolf',
    totalScore: 26316,
    membershipType: 2,
    classType: 0,
    rank: 180,
    profilePicturePath: '/img/profile/avatars/bungiedayav2.jpg',
  },
  {
    displayName: 'A large Potato',
    totalScore: 26302,
    membershipType: 4,
    classType: 0,
    rank: 181,
    profilePicturePath: '/img/profile/avatars/flamingninja.gif',
  },
  {
    displayName: 'DDT09',
    totalScore: 26254,
    membershipType: 2,
    classType: 0,
    rank: 182,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Huffy',
    totalScore: 26253,
    membershipType: 4,
    classType: 1,
    rank: 183,
    profilePicturePath: '/img/profile/avatars/14.jpg',
  },
  {
    displayName: 'D2Sweaty',
    totalScore: 26205,
    membershipType: 2,
    classType: 2,
    rank: 184,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'Essex',
    totalScore: 26186,
    membershipType: 4,
    classType: 1,
    rank: 185,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'LazyPandaCat',
    totalScore: 26104,
    membershipType: 2,
    classType: 0,
    rank: 186,
    profilePicturePath: '/img/profile/avatars/Destiny16.jpg',
  },
  {
    displayName: 'Reshetoff',
    totalScore: 26094,
    membershipType: 2,
    classType: 1,
    rank: 187,
    profilePicturePath: '/img/profile/avatars/bungiedayav1.jpg',
  },
  {
    displayName: 'MrButterslayer',
    totalScore: 26082,
    membershipType: 1,
    classType: 2,
    rank: 188,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_03.jpg',
  },
  {
    displayName: 'blueraven2234',
    totalScore: 26014,
    membershipType: 2,
    classType: 1,
    rank: 189,
    profilePicturePath: '/img/profile/avatars/trigunT7R_avatar.gif',
  },
  {
    displayName: 'command123',
    totalScore: 25952,
    membershipType: 2,
    classType: 1,
    rank: 190,
    profilePicturePath: '/img/profile/avatars/cc62.jpg',
  },
  {
    displayName: 'stealthpig03',
    totalScore: 25948,
    membershipType: 2,
    classType: 1,
    rank: 191,
    profilePicturePath: '/img/profile/avatars/bungiedayav4.jpg',
  },
  {
    displayName: 'ZRhino',
    totalScore: 25937,
    membershipType: 4,
    classType: 0,
    rank: 192,
    profilePicturePath: '/img/profile/avatars/th_BnetAvs37.jpg',
  },
  {
    displayName: 'BAU Fuzzy Wuzzy',
    totalScore: 25934,
    membershipType: 4,
    classType: 1,
    rank: 193,
    profilePicturePath: '/img/profile/avatars/CarnageZone.gif',
  },
  {
    displayName: 'Prodigy0112',
    totalScore: 25867,
    membershipType: 2,
    classType: 2,
    rank: 194,
    profilePicturePath: '/img/profile/avatars/cc000014.jpg',
  },
  {
    displayName: 'ALM1GHTY D0LLA',
    totalScore: 25828,
    membershipType: 1,
    classType: 1,
    rank: 195,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'MorPhonic',
    totalScore: 25819,
    membershipType: 4,
    classType: 0,
    rank: 196,
    profilePicturePath: '/img/profile/avatars/bungie_day_15_45.jpg',
  },
  {
    displayName: 'GrimReaper',
    totalScore: 25794,
    membershipType: 4,
    classType: 1,
    rank: 197,
    profilePicturePath: '/img/profile/avatars/e2015_13.jpg',
  },
  {
    displayName: 'Ninjahitman15',
    totalScore: 25791,
    membershipType: 2,
    classType: 2,
    rank: 198,
    profilePicturePath: '/img/profile/avatars/cc62.jpg',
  },
  {
    displayName: 'PaTriick1996',
    totalScore: 25625,
    membershipType: 2,
    classType: 0,
    rank: 199,
    profilePicturePath: '/img/profile/avatars/default_avatar.gif',
  },
  {
    displayName: 'DoobieBrothers94',
    totalScore: 25560,
    membershipType: 2,
    classType: 2,
    rank: 200,
    profilePicturePath: '/img/profile/avatars/cc64.jpg',
  },
];

export default week4;
